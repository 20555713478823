import { QUERY_KEYS } from "@/api/queryKeys.ts"
import { ZUser } from "@/api/types.ts"
import type { CONVERSATION_SCOPES } from "@/features/inbox/constants.ts"
import { useQuery } from "@tanstack/react-query"
import { gql } from "graphql-request"
import { z } from "zod"
import { canvasGqlClient } from "../api.ts"
import useValidateResponse from "../useValidateResponse.ts"

const ZWorkflowState = z.enum(["read", "unread", "archived"])

export const ZConversationMessage = z.object({
  _id: z.string(),
  id: z.string(),
  createdAt: z.string(),
  body: z.string(),
  author: ZUser,
})

export const ZConversationParticipant = z.object({
  _id: z.string(),
  id: z.string(),
  label: z.string(),
  user: ZUser,
  workflowState: ZWorkflowState,
})

export const ZConversation = z
  .object({
    _id: z.string(),
    id: z.number(),
    subject: z.string(),
    canReply: z.boolean(),
    isPrivate: z.boolean(),
    conversationMessagesConnection: z.object({
      nodes: z.array(ZConversationMessage),
    }),
    conversationMessagesCount: z.number(),
    conversationParticipantsConnection: z.object({
      nodes: z.array(ZConversationParticipant),
    }),
  })
  .strict()
export const ZConversationConnection = z
  .object({
    _id: z.string(),
    id: z.string(),
    label: z.string(),
    workflowState: ZWorkflowState,
    conversation: ZConversation,
  })
  .strict()

const ZConversations = z.object({
  conversations: z.array(ZConversationConnection),
})

export type ConversationConnection = z.infer<typeof ZConversationConnection>

const ZConversationsResponse = z.object({
  legacyNode: z.object({
    conversationsConnection: z.object({
      nodes: z.array(ZConversationConnection),
    }),
  }),
})

type ConversationsResponse = z.infer<typeof ZConversationsResponse>

export const CONVERSATIONS_QUERY = gql`
    query GetConversationsQuery(
        $userId: ID!
        $filter: [String!]
        $scope: String = ""
    ) {
        legacyNode(_id: $userId, type: User) {
            ... on User {
                _id
                id
                conversationsConnection(
                    scope: $scope # e.g. archived
                    filter: $filter # e.g. [user_1, course_1]
                ) {
                    nodes {
                        _id
                        id
                        label
                        workflowState
                        conversation {
                            _id
                            id
                            subject
                            isPrivate
                            canReply
                            conversationMessagesCount
                            conversationParticipantsConnection {
                                nodes {
                                    user {
                                        _id
                                        id
                                        name
                                    }
                                }
                            }
                            conversationMessagesConnection {
                                nodes {
                                    _id
                                    id
                                    body
                                    createdAt
                                    author {
                                        _id
                                        id
                                        name
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
`

export type GetConversationsProps = {
  userId: string
  filter: string[]
  scope: CONVERSATION_SCOPES
}

export const useGetConversations = (props: GetConversationsProps) => {
  const queryKey = QUERY_KEYS.CONVERSATION.byProperties(props)
  const queryResult = useQuery({
    queryKey,
    queryFn: async (): Promise<ConversationsResponse> =>
      (await canvasGqlClient()).request(CONVERSATIONS_QUERY, props),
    select: (data) => ({
      conversations: data.legacyNode.conversationsConnection.nodes,
    }),
  })

  useValidateResponse(queryKey.toString(), queryResult, ZConversations)
  return queryResult
}

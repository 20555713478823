// @ts-ignore
import { enhanceUserContent } from "@instructure/canvas-rce"
import { useEffect, useRef } from "react"
import { createMarkup } from "src/shared/utils/index"
import "./renderer.css"
import mathRenderer from "./mathRenderer"

type RichContentRendererProps = {
  content: string
  ariaHidden?: boolean
}
export const RichContentRenderer = (props: RichContentRendererProps) => {
  const { content, ariaHidden } = props
  const contentRef = useRef(null)

  useEffect(() => {
    content && contentRef?.current && enhanceUserContent(contentRef)
    mathRenderer.debouncedDocumentRendering()
  }, [content])

  return (
    <div ref={contentRef}>
      <div
        tabIndex={ariaHidden ? -1 : undefined}
        className="user_content hide-equation-image"
        // biome-ignore lint/security/noDangerouslySetInnerHtml: createMarkup function sanitize the input
        dangerouslySetInnerHTML={createMarkup(content)}
      />
    </div>
  )
}

import {
  type ButtonLevel,
  type ButtonVariant,
  generateButtonTheme,
} from "@/shared/themes/generateButtonTheme.ts"
import type { CLXTheme } from "@/themes/types.ts"

export const generateComponentTheme =
  (level: ButtonLevel, withBoxShadow: boolean, variant?: ButtonVariant) =>
  (_: unknown, currentTheme: CLXTheme) => {
    return generateButtonTheme({ currentTheme, level, withBoxShadow, variant })
  }

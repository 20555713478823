import type { PillTheme } from "@instructure/shared-types"
import { primitives } from "../primitives"
import { spacing } from "../spacing.ts"
import { typography } from "../typography.ts"

export const PILL_COLOR_OVERRIDE = {
  info: primitives.blue82,
  danger: primitives.red70,
  primary: primitives.grey125,
}

const PillThemeThemeOverrides: Partial<PillTheme> = {
  fontFamily: typography.fontFamily,
  primaryColor: PILL_COLOR_OVERRIDE.primary,
  dangerColor: PILL_COLOR_OVERRIDE.danger,
  infoColor: PILL_COLOR_OVERRIDE.info,
  padding: `${spacing.xSmall} ${spacing.small}`,
}

export default {
  Pill: PillThemeThemeOverrides,
}

// @ts-ignore
import CanvasRce from "@instructure/canvas-rce/es/rce/RCE"
import { FormField } from "@instructure/ui"
import classNames from "classnames"
import { type SyntheticEvent, useCallback, useEffect, useState } from "react"
import type { Editor } from "tinymce"
import {
  type CanvasConfig,
  type MapPropsToRCEType,
  type MapRcsPropsToRcsType,
  mapCLXRcePropsToCanvasRceProps,
} from "./rce5utils"
import "./input.css"
import { useGetRCEConfig } from "@/api/mutations/useGetRCEConfig"
import { refreshCanvasToken } from "@/api/util"
import { FetchingError } from "@/shared/loading/FetchingError"
import { GlobalLoader } from "@/shared/loading/GlobalLoader"
import { getUserId } from "@/token-storage"

const errorMessageType = "error"

type RCEInputProps = {
  autoFocus?: boolean
  textareaId: string
  defaultContent: string
  stem?: string
  height?: number | null
  actsAsInput?: boolean
  readOnly?: boolean
  placeholder?: string
  label: React.ReactNode
  messages?: {
    type: "hint" | "success" | "error" | "screenreader-only"
    text: string
  }[]
  onChange?: (editorContent: string) => void
  onFocus?: (event: SyntheticEvent, content: { editorContent: string }) => void
  onBlur?: (event: SyntheticEvent, content: { editorContent: string }) => void
  editorOptions?: {
    spellCheck: boolean
    wordCount: boolean
  }
  courseId: string
  token: string
}

export function RCEInput(props: RCEInputProps) {
  const {
    defaultContent = "",
    readOnly = false,
    onChange = () => {},
    onBlur = () => {},
    messages = [],
    textareaId,
    label,
    courseId,
    token,
  } = props

  const rceConfig = useGetRCEConfig({ token, courseId })

  const [editorContent, setEditorContent] = useState(defaultContent)

  useEffect(() => {
    onChange(editorContent)
  }, [editorContent, onChange])

  const invalid = () => {
    return (messages ?? []).some((message) => message.type === errorMessageType)
  }

  const handleContentChange = (content: string) => {
    setEditorContent(content)
  }

  const handleOnBlur = (e: SyntheticEvent) => {
    if (editorContent) {
      onBlur(e, { editorContent })
    }
  }

  const handleEditorInit = useCallback(
    (
      tinyEditorInstance: Editor & {
        rceWrapper: { iframe: { title: string } }
      },
    ) => {
      if (tinyEditorInstance?.selection) {
        tinyEditorInstance.selection.select(tinyEditorInstance.getBody(), true)
        tinyEditorInstance.selection.collapse(false)
      }
      const screenreaderText = tinyEditorInstance.rceWrapper.iframe.title
      tinyEditorInstance.dom.setAttrib(
        tinyEditorInstance.dom.select("body"),
        "aria-label",
        screenreaderText,
      )
    },
    [],
  )

  if (rceConfig.isLoading) {
    return <GlobalLoader title="loading..." />
  }

  if (rceConfig.isError) {
    return <FetchingError />
  }

  const rceProps: MapPropsToRCEType = {
    ...props,
    onBlur: handleOnBlur,
    onContentChange: handleContentChange,
    onInit: handleEditorInit,
  }

  const rcsProps: MapRcsPropsToRcsType = {
    canvasUserId: getUserId() as string,
    contextId: getUserId() as string,
    contextType: "user",
    resourceId: courseId,
    resourceType: "course",
    rcsHost: import.meta.env.PUBLIC_RCS_HOST,
    rcsJwt: token,
    rcsJwtRefreshFn: refreshCanvasToken(token),
  }

  return (
    <FormField messages={messages} label={label} id={textareaId}>
      <div
        className={classNames({
          "invalid-rce": invalid(),
          "readonly-rce": readOnly,
          "horizon-rce": true,
          asciimath2jax_ignore: true,
        })}
        data-automation="sdk-rce"
        data-testid="RichContentInput"
        aria-invalid={invalid() ? "true" : undefined}
      >
        <CanvasRce
          {...mapCLXRcePropsToCanvasRceProps(
            rceProps,
            rcsProps,
            rceConfig.data as CanvasConfig,
          )}
        />
      </div>
    </FormField>
  )
}

import { Text, View } from "@instructure/ui"

export const Message = () => {
  return (
    <>
      <View as="div" margin="0 0 small 0">
        <Text size="x-large">Let’s set your goal statement</Text>
      </View>
      <Text as="div">
        This info will be anonymized and used to help improve and evaluate your
        experience and future programs.
      </Text>
    </>
  )
}

import { type AddNote, insertNote } from "@/api/db/db"
import { QUERY_KEYS } from "@/api/queryKeys"
import { useMutation, useQueryClient } from "@tanstack/react-query"

export const useCreateNote = () => {
  const queryClient = useQueryClient()
  const mutationResult = useMutation({
    mutationFn: (note: AddNote) => insertNote(note),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: QUERY_KEYS.NOTE.all })
    },
  })

  return mutationResult
}

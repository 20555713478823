import { useAppContext } from "@/AppContext.tsx"
import { Icon } from "@/icons/Icon.tsx"
import { CLXIconButton } from "@/shared/components/IconButton/IconButton.tsx"
import { AiNavigationItem } from "@/shared/components/Navigation/AiNavigationItem.tsx"
import {
  AI,
  type NavigationItemProps,
} from "@/shared/components/Navigation/constants.ts"
import { borders } from "@/themes/horizon/src/borders.ts"
import { spacing } from "@/themes/horizon/src/spacing.ts"
import { ui } from "@/themes/horizon/src/ui.ts"
import { Text, View } from "@instructure/ui"
import type { ReactNode } from "react"
import { NavLink } from "react-router-dom"

export type NavItemProps = NavigationItemProps & {
  isIconButton?: boolean
  onClick?: () => void
}

export const RouteNavigationItem = ({
  route,
  title,
  icon,
  iconActive,
  isIconButton,
  onClick,
}: NavItemProps) => {
  const { desktopMode } = useAppContext()

  if (!route) {
    return null
  }

  return (
    <View
      height={desktopMode ? (isIconButton ? "44px" : "50px") : "55px"}
      width={desktopMode ? (isIconButton ? "44px" : "65px") : "280px"}
      margin={desktopMode && !isIconButton ? "x-small" : "none"}
    >
      <NavLink
        to={route}
        style={{
          textDecoration: "none",
        }}
        onClick={onClick}
      >
        {({ isActive }) => {
          return (
            <NavigationItemStyle isActive={isActive} isInline={!desktopMode}>
              {isIconButton ? (
                <CLXIconButton
                  screenReaderLabel={title ?? "Navigation item"}
                  level="primary"
                  variant="inverse"
                  withBoxShadow
                  clxIconName={isActive ? iconActive : icon}
                />
              ) : (
                <>
                  {icon && (
                    <Icon
                      name={isActive ? iconActive : icon}
                      width={30}
                      height={30}
                    />
                  )}
                  {title && (
                    <Text
                      color="primary"
                      size={desktopMode ? "x-small" : "medium"}
                      weight={isActive ? "bold" : "normal"}
                    >
                      {title}
                    </Text>
                  )}
                </>
              )}
            </NavigationItemStyle>
          )
        }}
      </NavLink>
    </View>
  )
}

export const NavigationItemStyle = ({
  children,
  isActive,
  isInline,
}: { children: ReactNode; isActive: boolean; isInline: boolean }) => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: isInline ? "row" : "column",
        justifyContent: isInline ? "start" : "center",
        gap: isInline ? spacing.mediumSmall : spacing.xxSmall,
        alignItems: "center",
        width: "100%",
        height: "100%",
        borderRadius: borders.radiusXLarge,
        backgroundColor:
          isInline && isActive ? ui.surfacePagePrimary : "transparent",
        color: isActive ? ui.surfaceInverseSecondary : ui.iconMedium,
        padding: isInline ? `${spacing.small} ${spacing.medium}` : 0,
      }}
    >
      {children}
    </div>
  )
}

export const NavigationItem = (props: NavItemProps) => {
  const { desktopMode } = useAppContext()

  if (props.customElement) {
    switch (props.icon) {
      case AI.icon:
        return <AiNavigationItem isIconButton={desktopMode} {...AI} />
      default:
        return null
    }
  }

  return <RouteNavigationItem {...props} />
}

import Dexie, { type EntityTable } from "dexie"

export enum NoteTag {
  Confusing = "Confusing",
  Interesting = "Interesting",
  Important = "Important",
}

export type Note = {
  id: number
  tags: NoteTag[]
  userId: string
  text: string
  createdAt: number
  updatedAt: number
  courseName: string
  courseId: string
  itemUrl: string
  institutionName: string
}

export type AddNote = Omit<Note, "id">
export type UpdateNote = Partial<Note>
export type NotedCourse = Pick<
  Note,
  "courseId" | "courseName" | "institutionName"
>

const db = new Dexie("NoteTable") as Dexie & {
  notes: EntityTable<Note, "id">
}

// Schema declaration:
db.version(1).stores({
  notes:
    "++id, tag, userId, text, createdAt, updatedAt, courseName, courseId, itemUrl, institutionName",
})

export { db }

export async function getNoteById(id: number) {
  return (await db.notes.where("id").equals(id).toArray())[0]
}

export async function getAllNotesByUser(userId: string) {
  return db.notes.where("userId").equals(userId).toArray()
}

export async function insertNote(note: AddNote): Promise<number> {
  return db.notes.add(note)
}

export async function updateNote(
  id: number,
  note: UpdateNote,
): Promise<number> {
  return db.notes.update(id, note)
}

export async function deleteNote(id: number): Promise<void> {
  return db.notes.delete(id)
}

export async function getNotedCourses(
  userId: string,
  searchText: string,
): Promise<NotedCourse[]> {
  return (
    await db.notes
      .filter(
        (note) =>
          note.userId === userId &&
          (note.courseName.includes(searchText) ||
            note.institutionName.includes(searchText)),
      )
      .toArray()
  )
    .map((note) => ({
      courseId: note.courseId,
      courseName: note.courseName,
      institutionName: note.institutionName,
    }))
    .reduce((acc, curr) => {
      if (!acc.find((a) => a.courseId === curr.courseId)) {
        acc.push(curr)
      }
      return acc
    }, [] as NotedCourse[])
}

export async function searchNotesByTextAndTag(
  userId: string,
  courseId: string,
  searchText: string,
  tags: Set<NoteTag>,
) {
  return db.notes
    .filter(
      (note) =>
        note.userId === userId &&
        note.courseId === courseId &&
        note.text.includes(searchText) &&
        (!tags.size ||
          (!!note.tags.length && note.tags.some((t) => tags.has(t)))),
    )
    .toArray()
}

export async function searchNotesByText(userId: string, searchText: string) {
  return db.notes.get({
    userId,
    text: searchText,
  })
}

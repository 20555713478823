import { useAppContext } from "@/AppContext.tsx"
import { useGetQuiz } from "@/api/queries/useGetQuiz.ts"
import { useGetSessionlessLaunchUrl } from "@/api/queries/useGetSessionlessLaunchUrl.ts"
import { ASSET_TYPE } from "@/api/types.ts"
import { LearningObjectWrapper } from "@/features/learning_content/learning_objects_skeleton/LearningObjectWrapper.tsx"
import { FetchingError } from "@/shared/loading/FetchingError.tsx"
import { GlobalLoader } from "@/shared/loading/GlobalLoader.tsx"
import { formatDate } from "@/shared/utils"
import { View } from "@instructure/ui"
import { useCallback, useEffect, useRef } from "react"
import { useParams } from "react-router-dom"

export const Quiz = () => {
  const quizFrameRef = useRef<HTMLIFrameElement>(null)
  const { courseId = "", assignmentId = "" } = useParams()
  const { theme } = useAppContext()
  const quizQuery = useGetQuiz({ courseId, assignmentId })
  const sessionlessLaunchUrlQuery = useGetSessionlessLaunchUrl({
    courseId,
    assignmentId,
  })
  const handleIframeCommunication = useCallback(
    (event: MessageEvent) => {
      if (!event.data?.type?.startsWith("quiz.")) {
        return
      }

      if (event.data.type === "quiz.waitingForTheme") {
        quizFrameRef.current?.contentWindow?.postMessage(
          { theme, type: "quiz.setTheme", origin: document.location.origin },
          "*",
        )
      }
    },
    [theme],
  )

  useEffect(() => {
    window.addEventListener("message", handleIframeCommunication)

    return () => {
      window.removeEventListener("message", handleIframeCommunication)
    }
  }, [handleIframeCommunication])

  if (quizQuery.isLoading || sessionlessLaunchUrlQuery.isLoading) {
    return <GlobalLoader title="Loading..." />
  }

  if (
    quizQuery.isError ||
    !quizQuery.data ||
    sessionlessLaunchUrlQuery.isError ||
    !sessionlessLaunchUrlQuery.data
  ) {
    return <FetchingError />
  }

  if (!quizQuery.data.is_quiz_lti_assignment) {
    console.error("This is not a new quiz")
    return <FetchingError />
  }

  return (
    <LearningObjectWrapper
      assetId={quizQuery.data.id}
      assetType={ASSET_TYPE.ASSIGNMENT}
      estimatedTime="20 Mins"
      courseId={courseId}
      dueAt={
        quizQuery.data.due_at ? formatDate(quizQuery.data.due_at) : undefined
      }
    >
      <View as="div" height="100%" overflowY="hidden">
        <iframe
          title={quizQuery.data.name}
          src={sessionlessLaunchUrlQuery.data.url}
          ref={quizFrameRef}
          style={{
            border: "none",
            width: "100%",
            height: "100%",
          }}
        />
      </View>
    </LearningObjectWrapper>
  )
}

import { useGetModuleItem } from "@/api/queries/useGetModules.ts"
import { ASSET_TYPE } from "@/api/types.ts"
import { LearningObjectWrapper } from "@/features/learning_content/learning_objects_skeleton/LearningObjectWrapper.tsx"
import { FetchingError } from "@/shared/loading/FetchingError.tsx"
import { GlobalLoader } from "@/shared/loading/GlobalLoader.tsx"
import { Button, View } from "@instructure/ui"
import { useParams } from "react-router-dom"
import { EmbeddedExternalTool } from "./EmbeddedExternalTool"

export const ExternalItem = () => {
  const { courseId = "", moduleId = "", itemId = "" } = useParams()
  const itemQuery = useGetModuleItem({
    courseId,
    moduleId,
    itemId,
  })

  if (itemQuery.isLoading) {
    return <GlobalLoader title="Loading..." />
  }

  if (
    itemQuery.isError ||
    !itemQuery.data ||
    !["ExternalUrl", "ExternalTool"].includes(itemQuery.data.type)
  ) {
    return <FetchingError />
  }

  return (
    <LearningObjectWrapper
      assetType={ASSET_TYPE.MODULE_ITEM}
      assetId={itemQuery.data.id}
      estimatedTime="20 Mins"
      courseId={courseId}
    >
      {itemQuery.data.new_tab ? (
        <View as="div" margin="medium">
          <Button
            href={itemQuery.data.external_url}
            target="_blank"
            display="block"
          >
            {`Open ${itemQuery.data.type === "ExternalUrl" ? "link" : "tool"} in new tab`}
          </Button>
        </View>
      ) : (
        <EmbeddedExternalTool
          title={itemQuery.data.title}
          url={itemQuery.data.external_url}
        />
      )}
    </LearningObjectWrapper>
  )
}

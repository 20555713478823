import { getNotedCourses } from "@/api/db/db"
import { QUERY_KEYS } from "@/api/queryKeys"
import { useQuery } from "@tanstack/react-query"
import { useState } from "react"

export const useGetNotedCourses = (userId?: string) => {
  const [searchText, setSearchText] = useState<string>("")
  const queryKey = QUERY_KEYS.NOTE.bySearchCourse(userId ?? "", searchText)
  return {
    setSearchText,
    noteSearchQuery: useQuery({
      enabled: !!userId,
      queryKey,
      queryFn: async () => {
        return getNotedCourses(userId ?? "", searchText)
      },
    }),
  }
}

import { Icon } from "@/icons/Icon.tsx"
import { CLXIconButton } from "@/shared/components/IconButton/IconButton.tsx"
import {
  type NavItemProps,
  NavigationItemStyle,
} from "@/shared/components/Navigation/NavigationItem.tsx"
import { LearningAssist } from "@/shared/learning_assist/LearningAssist.tsx"
import { Flex, Text, View } from "@instructure/ui"
import { useState } from "react"

export const AiNavigationItem = ({
  isIconButton,
  icon,
  iconActive,
  title,
  context,
}: NavItemProps & { context?: string }) => {
  const [isShowingAssistModal, setShowingAssistModal] = useState(false)

  return (
    <>
      {isIconButton ? (
        <Flex.Item height="44px" width="44px">
          <CLXIconButton
            size="medium"
            level="primary"
            variant="ai"
            shape="circle"
            screenReaderLabel="Learning Assist"
            clxIconName={icon}
            onClick={() => setShowingAssistModal(true)}
          />
        </Flex.Item>
      ) : (
        <View
          as="div"
          width="100%"
          height="100%"
          onClick={() => {
            setShowingAssistModal(true)
          }}
        >
          <NavigationItemStyle isActive={isShowingAssistModal} isInline={true}>
            <>
              {icon && (
                <Icon
                  name={isShowingAssistModal ? iconActive : icon}
                  width={30}
                  height={30}
                />
              )}
              {title && (
                <Text
                  color="primary"
                  size={"medium"}
                  weight={isShowingAssistModal ? "bold" : "normal"}
                >
                  {title}
                </Text>
              )}
            </>
          </NavigationItemStyle>
        </View>
      )}
      <LearningAssist
        isOpen={isShowingAssistModal}
        dismiss={() => {
          setShowingAssistModal(false)
        }}
        context={context}
      />
    </>
  )
}

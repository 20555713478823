import {
  Flex,
  IconArrowUpLine,
  IconButton,
  ScreenReaderContent,
  TextArea,
} from "@instructure/ui"
import { useState } from "react"

export const ChatInput = ({
  onSend,
  disabled,
}: { onSend: (promptText: string) => void; disabled: boolean }) => {
  const [promptText, setPromptText] = useState("")
  return (
    <Flex as="div" gap="small">
      <Flex.Item shouldGrow={true} shouldShrink={true}>
        <TextArea
          label={<ScreenReaderContent>Ask a question</ScreenReaderContent>}
          placeholder="Send a message..."
          value={promptText}
          onChange={(e) => setPromptText(e.target.value)}
          disabled={disabled}
          height="3rem"
          maxHeight="12rem"
          autoGrow={true}
        />
      </Flex.Item>

      <Flex.Item>
        <IconButton
          shape="circle"
          withBorder={false}
          size="large"
          renderIcon={IconArrowUpLine}
          screenReaderLabel="Ask"
          onClick={() => {
            onSend(promptText)
            setPromptText("")
          }}
          interaction={
            promptText.length > 0 && !disabled ? "enabled" : "disabled"
          }
        />
      </Flex.Item>
    </Flex>
  )
}

import { useCreateSubmissionComment } from "@/api/mutations/useCreateSubmissionComment"
import { useGetSubmissionComments } from "@/api/queries/useGetSubmissionComments"
import type { ZAttachmentType } from "@/api/types"
import { CLXButton } from "@/shared/components/Button/Button"
import { FetchingError } from "@/shared/loading/FetchingError"
import { GlobalLoader } from "@/shared/loading/GlobalLoader"
import { Flex, Popover, Text, TextArea, View } from "@instructure/ui"
import EmojiPicker from "emoji-picker-react"
import { useEffect, useState } from "react"
import { FileView } from "./FileView"
import { UploadFile, type UploadedFile } from "./UploadFile"

export type SubmissionCommentsProps = {
  submissionId: string
  attempt: number
}
export const SubmissionComments = ({
  submissionId,
  attempt,
}: SubmissionCommentsProps) => {
  const commentsQuery = useGetSubmissionComments(submissionId, attempt)
  const [files, setFiles] = useState<UploadedFile[]>([])
  const [uploadErrors, setUploadErrors] = useState<Error[]>([])
  const [text, setText] = useState<string>("")
  const [showEmojiSelector, setShowEmojiSelector] = useState<boolean>(false)
  const commentsMutation = useCreateSubmissionComment(submissionId, attempt)

  useEffect(() => {
    if (commentsMutation.isSuccess) {
      setFiles([])
      setText("")
    }
  }, [commentsMutation.isSuccess])

  if (commentsQuery.isLoading) {
    return <GlobalLoader title="Loading..." />
  }
  if (commentsQuery.isError || !commentsQuery.data) {
    return <FetchingError />
  }

  const handleClick = () => {
    commentsQuery.fetchNextPage()
  }

  const submitButtonLabel = () => {
    if (commentsMutation.isPending) {
      return "Sending..."
    }
    return "Add comment"
  }
  const entryValid = (files.length > 0 || text) && uploadErrors.length === 0

  return (
    <View>
      <View padding="medium">
        <Flex as="div" direction="column">
          <Text size="large">Comments</Text>
          <View as="div" padding="medium 0">
            <TextArea
              label={"Add comment"}
              value={text}
              onChange={(event) => setText(event.target.value)}
            />
            <Popover
              renderTrigger={<View>{"\u{1F600}"}</View>}
              isShowingContent={showEmojiSelector}
              onShowContent={() => {
                setShowEmojiSelector(true)
              }}
              onHideContent={() => {
                setShowEmojiSelector(false)
              }}
              on="click"
              screenReaderLabel="Emoji selector"
              shouldContainFocus
              shouldReturnFocus
              shouldCloseOnDocumentClick
              offsetY="16px"
            >
              <EmojiPicker
                onEmojiClick={({ emoji }: { emoji: string }) =>
                  setText((state) => state + emoji)
                }
              />
            </Popover>
            <UploadFile
              uploadedFiles={files}
              setUploadedFiles={setFiles}
              errors={uploadErrors}
              setErrors={setUploadErrors}
              isComment
              allowMultiple
              allowRepeats
              allowDeletion
            />
            <CLXButton
              level="primary"
              onClick={() => {
                commentsMutation.mutate({
                  text,
                  fileIds: files.map((f) => f.id.toString()),
                })
              }}
              interaction={
                entryValid && !commentsMutation.isPending
                  ? "enabled"
                  : "disabled"
              }
            >
              {submitButtonLabel()}
            </CLXButton>
          </View>
          {commentsQuery.data.comments.map((c) => {
            const { _id, attachments, htmlComment } = c
            return (
              <View
                as="div"
                key={_id}
                padding="medium"
                borderWidth="0 0 small 0"
                borderColor="black"
              >
                <Flex direction="column">
                  <Text>{htmlComment}</Text>
                  {(
                    (attachments ?? []).filter((e) => e) as ZAttachmentType[]
                  ).map(({ id, ...rest }) => {
                    return (
                      <View key={id}>
                        <FileView {...rest} />
                      </View>
                    )
                  })}
                </Flex>
              </View>
            )
          })}
        </Flex>
      </View>
      {commentsQuery.hasNextPage && (
        <Flex direction="column" alignItems="center">
          <CLXButton level="primary" onClick={handleClick}>
            Load 20 more
          </CLXButton>
        </Flex>
      )}
    </View>
  )
}

import { useAppContext } from "@/AppContext"
import {
  useAnswerPrompt,
  useGetBedrockKBResponse,
} from "@/api/queries/useGetAIResponse.ts"
import { useGetUser } from "@/api/queries/useGetUser.ts"
import { AI_GRADIENT } from "@/constants.ts"
import { CLXPill } from "@/shared/components/Pill/Pill.tsx"
import { AssistQuizInteraction } from "@/shared/learning_assist/AssistQuizInteraction.tsx"
import { ChatEntry } from "@/shared/learning_assist/ChatEntry.tsx"
import { ChatInput } from "@/shared/learning_assist/ChatInput.tsx"
import { Header } from "@/shared/learning_assist/Header.tsx"
import {
  Actor,
  type Chat,
  type Prompt,
} from "@/shared/learning_assist/types.ts"
import { transformBedrockKbResponseToChat } from "@/shared/learning_assist/utils.ts"
import { getUserId } from "@/token-storage.ts"
import { Flex, Modal, Spinner, View } from "@instructure/ui"
import { useCallback, useEffect, useRef, useState } from "react"
import { useParams } from "react-router-dom"
import AssistFlashCardsInteraction from "./AssistFlashCardsInteraction"

export const LearningAssist = ({
  isOpen,
  dismiss,
  context,
}: {
  isOpen: boolean
  dismiss: () => void
  context?: string
}) => {
  const { courseId } = useParams() as { courseId: string }
  const scrollingRef = useRef<HTMLElement | null>(null)
  const [sessionId, setSessionId] = useState<string | null>(null)
  const { desktopMode } = useAppContext()
  const [chats, setChats] = useState<Chat[]>([])
  const [selectedPrompt, setSelectedPrompt] = useState<Prompt | null>(null)
  const addChat = useCallback((chat: Chat) => {
    setChats((chats) => [...chats, chat])
  }, [])
  const lastChat = chats[chats.length - 1]
  const showLargePrompts = context && chats.length === 0
  const showSmallPrompts = context && chats.length > 0
  const showPromptInteraction = selectedPrompt !== null
  const useGetUserQuery = useGetUser(getUserId())
  const userName = useGetUserQuery.data?.name?.split(" ")[0] // TODO: use firstName when available on User type (CLX-124)
  const lastChatIsUser =
    !!lastChat && lastChat.actor === Actor.User && !!lastChat.message
  const useGetBedrockKBResponseQuery = useGetBedrockKBResponse(
    {
      courseId,
      promptId: lastChat?.id,
      prompt: lastChat?.message ?? "",
      sessionId,
      userName,
    },
    lastChatIsUser && !!userName && !lastChat.agentPrompt,
  )
  const useAnswerPromptQuery = useAnswerPrompt(
    {
      promptId: lastChat?.id,
      prompt: lastChat?.agentPrompt ?? "",
      context: context ?? "",
      userName,
    },
    lastChatIsUser && !!userName && !!lastChat.agentPrompt && !!context,
  )

  const assistPrompts: Prompt[] = [
    {
      longMessage: "Quiz me on this material",
      shortMessage: "Quiz me",
      renderInteraction: <AssistQuizInteraction context={context ?? ""} />,
    },
    {
      longMessage: "Summarize this material",
      shortMessage: "Summarize",
      agentPrompt:
        "Give me a 1-2 paragraph summary of the content; don't use any information besides the provided content. Return the response as HTML paragraphs.",
    },
    {
      longMessage: "Give me key takeaways",
      shortMessage: "Key takeaways",
      agentPrompt:
        "Give some key takeaways from this content; don't use any information besides the provided content. Return the response as an HTML unordered list.",
    },
    {
      longMessage: "Tell me more about this topic",
      shortMessage: "More detail",
      agentPrompt:
        "In 1-2 paragraphs, tell me more about this content. Return the response as HTML paragraphs.",
    },
    {
      longMessage: "Generate some study flashcards",
      shortMessage: "Flashcards",
      renderInteraction: (
        <AssistFlashCardsInteraction context={context ?? ""} />
      ),
    },
  ]

  const handlePromptInteraction = (prompt: Prompt) => {
    if (prompt.renderInteraction) {
      setSelectedPrompt(prompt)
    } else if (prompt.agentPrompt) {
      addChat({
        id: chats.length,
        actor: Actor.User,
        message: prompt.longMessage,
        agentPrompt: prompt.agentPrompt,
      })
    }
  }

  useEffect(() => {
    if (useGetBedrockKBResponseQuery.data?.sessionId) {
      setSessionId(useGetBedrockKBResponseQuery.data.sessionId)
    }
  }, [useGetBedrockKBResponseQuery.data?.sessionId])

  // biome-ignore lint/correctness/useExhaustiveDependencies: don't run on changes to chats
  useEffect(() => {
    if (useGetBedrockKBResponseQuery.data?.output?.text) {
      addChat(
        transformBedrockKbResponseToChat(
          chats.length,
          useGetBedrockKBResponseQuery.data,
        ),
      )
    }
  }, [useGetBedrockKBResponseQuery.data?.output?.text])

  // biome-ignore lint/correctness/useExhaustiveDependencies: don't run on changes to chats
  useEffect(() => {
    if (useAnswerPromptQuery.data?.answerPrompt) {
      addChat({
        id: chats.length,
        actor: Actor.Assistant,
        htmlMessage: useAnswerPromptQuery.data.answerPrompt,
      })
    }
  }, [useAnswerPromptQuery.data?.answerPrompt])

  // biome-ignore lint/correctness/useExhaustiveDependencies: scroll whenever chats change
  useEffect(() => {
    if (scrollingRef.current) {
      scrollingRef.current.scrollTo({
        top: scrollingRef.current.scrollHeight,
        behavior: "smooth",
      })
    }
  }, [chats])

  useEffect(() => {
    if (userName && !context && chats.length === 0) {
      addChat({
        id: 0,
        actor: Actor.Assistant,
        message: `Hi, ${userName}. How can I help you?`,
      })
    }
  }, [userName, addChat, chats.length, context])

  return (
    <Modal
      open={isOpen}
      onClose={dismiss}
      size={desktopMode ? "large" : "fullscreen"}
      label="AI Learning Assist"
    >
      <Modal.Body padding="0">
        <div
          style={{
            width: "100%",
            height: "100%",
            background: AI_GRADIENT,
          }}
        >
          <Flex as="div" direction="column" height="100%">
            <Flex.Item>
              <Header
                showBackButton={showPromptInteraction}
                onBack={() => setSelectedPrompt(null)}
                onClose={dismiss}
              />
            </Flex.Item>

            {!showPromptInteraction ? (
              <>
                <Flex.Item
                  shouldGrow={true}
                  shouldShrink={true}
                  padding="medium"
                  overflowY="auto"
                  elementRef={(el) => {
                    if (el instanceof HTMLElement) {
                      scrollingRef.current = el
                    }
                  }}
                >
                  <Flex
                    as="div"
                    direction="column"
                    height="100%"
                    justifyItems={showLargePrompts ? "center" : "start"}
                  >
                    <Flex.Item>
                      {showLargePrompts
                        ? assistPrompts.map((prompt, index) => (
                            <View
                              // biome-ignore lint/suspicious/noArrayIndexKey: assistPrompts array is static
                              key={index}
                              as="div"
                              onClick={() => {
                                handlePromptInteraction(prompt)
                              }}
                            >
                              <ChatEntry courseId={courseId} prompt={prompt} />
                            </View>
                          ))
                        : chats.map((chat, index) => (
                            <ChatEntry
                              key={chat.id}
                              courseId={courseId}
                              chat={chat}
                              showFeedbackControls={
                                chat.actor === Actor.Assistant &&
                                index === chats.length - 1 &&
                                index > 0
                              }
                            />
                          ))}

                      {(useGetBedrockKBResponseQuery.isLoading ||
                        useAnswerPromptQuery.isLoading) && (
                        <div style={{ margin: "1.5rem 0" }}>
                          <Spinner renderTitle="Loading" size="x-small" />
                        </div>
                      )}
                    </Flex.Item>
                  </Flex>
                </Flex.Item>

                {showSmallPrompts && (
                  <Flex.Item overflowX="auto">
                    <Flex as="div" margin="xx-small 0">
                      {assistPrompts.map((prompt, index) => (
                        <Flex.Item
                          // biome-ignore lint/suspicious/noArrayIndexKey: assistPrompts array is static
                          key={index}
                        >
                          <CLXPill
                            margin={`0 ${index === assistPrompts.length - 1 ? "medium" : "xx-small"} 0 ${index === 0 ? "medium" : "xx-small"}`}
                            onClick={() => handlePromptInteraction(prompt)}
                          >
                            {prompt.shortMessage}
                          </CLXPill>
                        </Flex.Item>
                      ))}
                    </Flex>
                  </Flex.Item>
                )}

                <Flex.Item padding="medium">
                  <ChatInput
                    onSend={(promptText) => {
                      addChat({
                        id: chats.length,
                        actor: Actor.User,
                        message: promptText,
                      })
                    }}
                    disabled={
                      useGetBedrockKBResponseQuery.isLoading ||
                      useAnswerPromptQuery.isLoading
                    }
                  />
                </Flex.Item>
              </>
            ) : (
              <Flex.Item
                shouldGrow={true}
                shouldShrink={true}
                padding="medium"
                overflowY="auto"
              >
                {selectedPrompt?.renderInteraction}
              </Flex.Item>
            )}
          </Flex>
        </div>
      </Modal.Body>
    </Modal>
  )
}

import type { ModuleItem, ModuleType } from "@/api/types.ts"
import { LearningObjectMyProgress } from "@/features/learning_content/learning_objects_skeleton/LearningObjectMyProgress.tsx"
import { CLXIconButton } from "@/shared/components/IconButton/IconButton.tsx"
import { CLXTruncateText } from "@/shared/components/TruncateText/TruncateText.tsx"
import { PAGEROUTE, generateRoute } from "@/shared/router"
import { spacing } from "@/themes/horizon/src/spacing.ts"
import { ui } from "@/themes/horizon/src/ui.ts"
import { Flex, Overlay } from "@instructure/ui"
import { useState } from "react"
import { useNavigate } from "react-router-dom"

type MobileLearningObjectHeaderProps = {
  courseId: string
  moduleItem: ModuleItem
  module: ModuleType
}

export const MobileLearningObjectHeader = ({
  courseId,
  moduleItem,
  module,
}: MobileLearningObjectHeaderProps) => {
  const navigate = useNavigate()
  const [isMyProgressOpen, setIsMyProgressOpen] = useState(false)
  return (
    <div
      style={{
        display: "flex",
        padding: `${spacing.small} ${spacing.medium}`,
        justifyContent: "space-between",
        width: "100%",
      }}
    >
      <CLXIconButton
        screenReaderLabel={"Back to my progress"}
        level="secondary"
        variant="beige"
        clxIconName="arrow_left_alt"
        onClick={() => {
          const route = generateRoute(PAGEROUTE.COURSE_PROGRESS, {
            courseId,
          })
          navigate(route)
        }}
      />
      <Flex as="div" direction="column" gap="small" alignItems="center">
        <CLXTruncateText
          size="small"
          text={module.name ?? ""}
          textAlign="center"
        />
        <CLXTruncateText
          size="large"
          color="secondary"
          weight="bold"
          text={moduleItem.title}
          maxLines={3}
          textAlign="center"
        />
      </Flex>
      <CLXIconButton
        screenReaderLabel="My Progress"
        level="secondary"
        variant="beige"
        clxIconName="list_alt"
        size="small"
        onClick={() => setIsMyProgressOpen(true)}
      />
      <Overlay
        open={isMyProgressOpen}
        transition="fade"
        label="My progress overlay"
        shouldReturnFocus
        shouldContainFocus
      >
        <div
          style={{
            position: "absolute",
            top: 0,
            background: ui.surfacePageSecondary,
            height: "100%",
            width: "100%",
          }}
        >
          <LearningObjectMyProgress
            courseId={courseId}
            module={module}
            itemId={moduleItem.id}
            onCloseClicked={() => setIsMyProgressOpen(false)}
          />
        </div>
      </Overlay>
    </div>
  )
}

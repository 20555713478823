import { CLXProgressBar } from "@/shared/components/ProgressBar/ProgressBar"
import { PAGEROUTE, generateRoute } from "@/shared/router"
import { getCourseProgressionValues } from "@/shared/utils"
import { Heading, View } from "@instructure/ui"
import { useNavigate } from "react-router-dom"
import { HomeCard } from "./HomeCard"
import type { HomeProps } from "./types"

export const MobileHome = ({ courses }: HomeProps) => {
  const navigate = useNavigate()

  return courses.map((course) => {
    const { completed, total, completionPercentage } =
      getCourseProgressionValues(
        course.course.usersConnection?.nodes[0].courseProgression,
      )
    const isCourseStarted = completionPercentage > 0

    const handleNavigate = () => {
      const myProgressRoute = generateRoute(PAGEROUTE.COURSE_PROGRESS, {
        courseId: course.course._id,
      })
      navigate(myProgressRoute)
    }

    return (
      <View key={course.course._id} as="div" margin="0 medium">
        <View onClick={handleNavigate} as="div" cursor="pointer">
          <Heading level="h1" margin="medium 0">
            {course.course.name}
          </Heading>
          <View as="div" margin="medium 0 large">
            <CLXProgressBar
              valueNow={completed}
              valueMax={total}
              completionPercentage={completionPercentage}
              shouldRenderValue
            />
          </View>
        </View>
        <Heading as="h3" margin="0 0 small">
          Next Up
        </Heading>
        <HomeCard course={course} isCourseStarted={isCourseStarted} />
      </View>
    )
  })
}

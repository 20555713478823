import { QUERY_KEYS } from "@/api/queryKeys.ts"
import { APIROUTE, generateRoute } from "@/shared/router"
import { useQuery } from "@tanstack/react-query"
import { z } from "zod"
import { canvasRestClient } from "../api.ts"
import useValidateResponse from "../useValidateResponse.ts"

const ZQuizResponse = z.object({
  id: z.number(),
  name: z.string(),
  due_at: z.string().nullable(),
  points_possible: z.number().nullable(),
  url: z.string(),
  is_quiz_lti_assignment: z.boolean(),
})

type QuizResponse = z.infer<typeof ZQuizResponse>

export type QuizProperties = {
  courseId: string
  assignmentId: string
}

export const useGetQuiz = (props: QuizProperties) => {
  const queryKey = QUERY_KEYS.QUIZ.assignment(props)
  const queryResult = useQuery({
    queryKey,
    queryFn: async (): Promise<QuizResponse> => {
      const apiRoute = generateRoute(APIROUTE.ASSIGNMENT, {
        courseId: props.courseId,
        assignmentId: props.assignmentId,
      })

      return canvasRestClient(apiRoute)
    },
  })

  useValidateResponse(queryKey.toString(), queryResult, ZQuizResponse)
  return queryResult
}

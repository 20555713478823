import { Link, Text, View, canvas } from "@instructure/ui"
import type { Dispatch, SetStateAction } from "react"
import { STEPS } from "./types"

type GoalsProp = {
  currentStep: STEPS
  setCurrentStep: Dispatch<SetStateAction<STEPS>>
  learningIntention: string | null
  careerOutcome: string | null
  lifeOutcome: string | null
}

export const Goals = ({
  currentStep,
  setCurrentStep,
  learningIntention,
  careerOutcome,
  lifeOutcome,
}: GoalsProp) => {
  const isLearningIntentionStep = currentStep === STEPS.LEARNING_INTENTION
  const isCareerOutcomeStep = currentStep === STEPS.CAREER_OUTCOME
  const isLifeOutcomeStep = currentStep === STEPS.LIFE_OUTCOME
  return (
    <View
      as="div"
      padding="medium"
      background="secondary"
      margin="0 0 medium 0"
    >
      <Text>Through this program, I will </Text>
      <Link
        themeOverride={{
          color: canvas.colors.primitives.grey125,
          focusOutlineWidth: "0",
          hoverColor: canvas.colors.primitives.grey125,
          textDecorationWithinText: isLearningIntentionStep
            ? "none"
            : "underline",
        }}
        onClick={() => setCurrentStep(STEPS.LEARNING_INTENTION)}
      >
        <Text
          weight={isLearningIntentionStep ? "bold" : "normal"}
        >{`${learningIntention ?? "[learning intention]"}`}</Text>
      </Link>
      <Text> to </Text>
      <Link
        themeOverride={{
          color: canvas.colors.primitives.grey125,
          focusOutlineWidth: "0",
          hoverColor: canvas.colors.primitives.grey125,
          textDecorationWithinText: isCareerOutcomeStep ? "none" : "underline",
        }}
        onClick={() => setCurrentStep(STEPS.CAREER_OUTCOME)}
      >
        <Text
          weight={isCareerOutcomeStep ? "bold" : "normal"}
        >{`${careerOutcome ?? "[the career outcome]"}`}</Text>
      </Link>
      <Text> so that I can </Text>
      <Link
        themeOverride={{
          color: canvas.colors.primitives.grey125,
          focusOutlineWidth: "0",
          hoverColor: canvas.colors.primitives.grey125,
          textDecorationWithinText: isLifeOutcomeStep ? "none" : "underline",
        }}
        onClick={() => setCurrentStep(STEPS.LIFE_OUTCOME)}
      >
        <Text
          weight={isLifeOutcomeStep ? "bold" : "normal"}
        >{`${lifeOutcome ?? "[life outcome]"}`}</Text>
      </Link>
    </View>
  )
}

import { APIROUTE, generateRoute } from "@/shared/router"
import { useMutation, useQueryClient } from "@tanstack/react-query"
import { canvasRestClient } from "../api"
import { QUERY_KEYS } from "../queryKeys"

export type MarkDone = {
  courseId: string
  moduleId: string
  itemId: string
}

export const useMarkDone = ({ courseId, moduleId, itemId }: MarkDone) => {
  const queryClient = useQueryClient()
  const mutationResult = useMutation({
    mutationFn: (done: boolean) =>
      canvasRestClient(
        generateRoute(APIROUTE.PAGE_MARK_DONE, { courseId, moduleId, itemId }),
        { method: done ? "PUT" : "DELETE" },
      ),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: QUERY_KEYS.MODULES.all,
      })
    },
  })

  return mutationResult
}

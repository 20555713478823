import { useGetCourseName } from "@/api/queries/useGetCourses.ts"
import { useGetPageContent } from "@/api/queries/useGetPage.ts"
import { ASSET_TYPE } from "@/api/types.ts"
import { LearningObjectWrapper } from "@/features/learning_content/learning_objects_skeleton/LearningObjectWrapper.tsx"
import { FetchingError } from "@/shared/loading/FetchingError.tsx"
import { GlobalLoader } from "@/shared/loading/GlobalLoader.tsx"
import { createMarkup } from "@/shared/utils/index.ts"
import { sanitize } from "dompurify"
import { useParams } from "react-router-dom"

export const Page = () => {
  const { courseId = "", pageId = "" } = useParams()

  const pageContentQuery = useGetPageContent({
    courseId,
    pageId,
  })

  const courseNameQuery = useGetCourseName(courseId)

  if (courseNameQuery.isLoading || pageContentQuery.isLoading) {
    return <GlobalLoader title="Loading..." />
  }

  if (
    courseNameQuery.isError ||
    !courseNameQuery.data ||
    pageContentQuery.isError ||
    !pageContentQuery.data
  ) {
    return <FetchingError />
  }

  const markup = createMarkup(pageContentQuery.data.body)

  return (
    <LearningObjectWrapper
      assetId={pageContentQuery.data.page_id}
      estimatedTime="20 Mins"
      courseId={courseId}
      assetType={ASSET_TYPE.PAGE}
      learningAssistContext={sanitize(pageContentQuery.data.body)}
    >
      <div
        // biome-ignore lint/security/noDangerouslySetInnerHtml: <explanation>
        dangerouslySetInnerHTML={markup}
      />
    </LearningObjectWrapper>
  )
}

import ConversationList from "@/features/inbox/ConversationList.tsx"
import { InboxHeader } from "@/features/inbox/InboxHeader.tsx"
import { SelectedConversation } from "@/features/inbox/SelectedConversation.tsx"
import {
  INBOX_HEADER_HEIGHT,
  MESSAGE_HEADER_HEIGHT,
  SCOPE_SELECTOR_HEIGHT,
} from "@/features/inbox/constants.ts"
import type { ViewProps } from "@/features/inbox/types.ts"
import { Text, View } from "@instructure/ui"
import { useAppContext } from "../../AppContext.tsx"

export const MobileView = ({
  conversations,
  setFilter,
  filterValue,
  selectedConversation,
  setSelectedConversation,
}: ViewProps) => {
  const { mobileContentHeight } = useAppContext()

  return (
    <View as="div" height="100%" overflowY="hidden">
      {!selectedConversation && (
        <InboxHeader filterValue={filterValue} setFilter={setFilter} />
      )}
      {conversations.length < 1 ? (
        <View as="div" width="100%" textAlign="center">
          <Text>No messages found</Text>
        </View>
      ) : (
        <View
          as="div"
          className="flex-align-item-start flex-container"
          height="100%"
        >
          <View as="div" className="flex-item-grow">
            {selectedConversation ? (
              <SelectedConversation
                messageBodyHeight={
                  mobileContentHeight -
                  SCOPE_SELECTOR_HEIGHT -
                  MESSAGE_HEADER_HEIGHT
                }
                selectedConversation={selectedConversation}
                onBack={() => setSelectedConversation(null)}
              />
            ) : (
              <View
                as="div"
                className={`${selectedConversation ? "hidden" : ""}`}
                height={
                  mobileContentHeight -
                  INBOX_HEADER_HEIGHT -
                  SCOPE_SELECTOR_HEIGHT
                }
                overflowY="auto"
              >
                <ConversationList
                  conversations={conversations}
                  setSelectedConversation={setSelectedConversation}
                />
              </View>
            )}
          </View>
        </View>
      )}
    </View>
  )
}

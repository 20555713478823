import type { ModuleItem, ModuleItemType } from "@/api/types.ts"
import { MODULE_ITEM_TYPE } from "@/shared/components/ModuleItem/types.ts"
import { PAGEROUTE, SearchParams, generateRoute } from "@/shared/router"
import { typography } from "@/themes/horizon/src/typography"
import { ui } from "@/themes/horizon/src/ui"
import type { FormMessage } from "../types"

type GetFormMessages = {
  description?: string
  descriptionFontSize?: string
  errorText?: string
  errorTextFontSize?: string
  marginLeft?: string
}

export const getFormMessages = ({
  description,
  errorText,
  marginLeft,
  descriptionFontSize,
  errorTextFontSize,
}: GetFormMessages) => {
  const messages: FormMessage = []
  if (description) {
    messages.push({
      text: (
        <span
          style={{
            marginLeft: errorText ? 0 : marginLeft,
            fontSize: descriptionFontSize ?? typography.fontSizeMedium,
            color: ui.textDatapoint,
          }}
        >
          {description}
        </span>
      ),
      type: "hint",
    })
  }
  if (errorText) {
    messages.push({
      text: (
        <span
          style={{ fontSize: errorTextFontSize ?? typography.fontSizeSmall }}
        >
          {errorText}
        </span>
      ),
      type: "newError",
    })
  }
  return messages
}

export const getLearningObjectLink = (
  type: ModuleItemType,
  quizLti: boolean,
  courseId: string,
  item: ModuleItem,
  moduleId: string,
) => {
  switch (type) {
    case MODULE_ITEM_TYPE.ASSIGNMENT:
      return generateRoute(quizLti ? PAGEROUTE.QUIZ : PAGEROUTE.ASSIGNMENT, {
        courseId,
        assignmentId: String(item.content_id),
      })
    case MODULE_ITEM_TYPE.PAGE:
      return generateRoute(
        PAGEROUTE.PAGE,
        {
          courseId,
          pageId: item.page_url ?? "",
        },
        [{ [SearchParams.MODULE_ITEM_ID]: item.id }],
      )
    case MODULE_ITEM_TYPE.EXTERNAL_URL:
    case MODULE_ITEM_TYPE.EXTERNAL_TOOL:
      return generateRoute(PAGEROUTE.EXTERNALITEM, {
        courseId,
        moduleId,
        itemId: String(item.id),
      })
    case MODULE_ITEM_TYPE.FILE:
      return generateRoute(
        PAGEROUTE.FILECONTENT,
        {
          courseId,
          fileId: String(item.content_id),
        },
        [{ [SearchParams.MODULE_ITEM_ID]: item.id }],
      )
    default:
      return ""
  }
}

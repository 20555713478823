import supportedIcons from "@/icons/icons.json"
import { type IconProperties, getIconFileName } from "@/shared/utils"
import { SVGIcon } from "@instructure/ui"
import React, { type ComponentProps, useEffect } from "react"
import { renderToString } from "react-dom/server"

interface IconProps extends ComponentProps<typeof SVGIcon> {
  name?: string
  children?: string
  customColor?: string
  width?: number | string
  height?: number | string
  iconOptions?: Partial<IconProperties>
}

function hasValidIconProps(
  iconName: string,
  icon: IconProperties | undefined,
): boolean {
  if (!icon) {
    return false
  }

  if (icon?.localName === iconName) {
    return true
  }

  return !icon?.localName && icon?.remoteName === iconName
}

export const Icon: React.FC<IconProps> = ({
  name,
  children,
  customColor,
  iconOptions,
  width,
  height,
  ...props
}) => {
  const [iconSrc, setIconSrc] = React.useState<string | null>(null)
  const iconName = name || children || ""
  const icon = (supportedIcons.find(
    (icon: IconProperties) =>
      icon.localName === iconName || icon.remoteName === iconName,
  ) || {}) as IconProperties

  if (!hasValidIconProps(iconName, icon)) {
    throw new Error(
      `Icon "${iconName}" not found, check its availability in the icons.json file.`,
    )
  }

  const fileName = getIconFileName({
    ...icon,
    ...iconOptions,
  })
  const dimensions = {
    width: width || (iconOptions?.size ?? 24),
    height: height || (iconOptions?.size ?? 24),
  }

  useEffect(() => {
    import(`@/icons/${fileName}?react`).then((icon) => {
      const iconString: string = renderToString(icon.default())
      setIconSrc(iconString)
    })
  }, [fileName])

  if (!iconSrc) {
    return null
  }

  return (
    <span style={{ color: customColor ?? "inherit" }}>
      <SVGIcon src={iconSrc} {...dimensions} {...props} />
    </span>
  )
}

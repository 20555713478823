import type {
  TabsPanelTheme,
  TabsTabTheme,
  TabsTheme,
} from "@instructure/shared-types"
import { ui } from "../ui"

const TabsThemeOverrides: Partial<TabsTheme> = {
  defaultBackground: "transparent",
}

const TabsTabThemeOverrides: Partial<TabsTabTheme> = {
  defaultSelectedBorderColor: ui.surfaceInverseSecondary,
  secondarySelectedBackground: "transparent",
}

const TabsPanelThemeOverrides: Partial<TabsPanelTheme> = {
  borderWidth: 0,
  background: "transparent",
  borderColor: "transparent",
}

export default {
  Tabs: TabsThemeOverrides,
  TabsTab: TabsTabThemeOverrides,
  "Tabs.Tab": TabsTabThemeOverrides,
  TabsPanel: TabsPanelThemeOverrides,
  "Tabs.Panel": TabsPanelThemeOverrides,
}

import { useGetCourseName } from "@/api/queries/useGetCourses.ts"
import { useGetUser } from "@/api/queries/useGetUser.ts"
import { SearchHeader } from "@/shared/header/SearchHeader"
import { FetchingError } from "@/shared/loading/FetchingError.tsx"
import { GlobalLoader } from "@/shared/loading/GlobalLoader.tsx"
import { PAGEROUTE, generateRoute } from "@/shared/router"
import { getUserId } from "@/token-storage.ts"
import { Button, Flex, IconArrowEndSolid, Text, View } from "@instructure/ui"
import { useState } from "react"
import { useTranslation } from "react-i18next"
import { useNavigate, useParams } from "react-router-dom"
import { Goals } from "./Goals.tsx"
import { Message } from "./Message.tsx"
import { Step } from "./Step.tsx"
import {
  CAREER_OUTCOMES,
  LEARNING_INTENTIONS,
  LIFE_OUTCOMES,
  STEPS,
} from "./types.ts"

export const PreCourseSurvey = () => {
  const { courseId = "" } = useParams()
  const { t } = useTranslation()
  const userId = getUserId()
  const userQuery = useGetUser(userId)
  const navigate = useNavigate()
  const courseNameQuery = useGetCourseName(courseId)

  const [currentStep, setCurrentStep] = useState(STEPS.START)
  const [learningIntention, setLearningIntention] = useState<
    (typeof LEARNING_INTENTIONS)[number] | null
  >(null)
  const [careerOutcome, setCareerOutcome] = useState<
    (typeof CAREER_OUTCOMES)[number] | null
  >(null)
  const [lifeOutcome, setLifeOutcome] = useState<
    (typeof LIFE_OUTCOMES)[number] | null
  >(null)

  const goToNextStep = () => {
    if (currentStep === STEPS.PREVIEW) {
      return
    }
    setCurrentStep((state) => state + 1)
  }

  if (userQuery.isLoading || courseNameQuery.isLoading) {
    return <GlobalLoader title="Loading..." renderInContent={true} />
  }

  if (
    userQuery.isError ||
    !userQuery.data ||
    courseNameQuery.isError ||
    !courseNameQuery.data
  ) {
    return <FetchingError />
  }

  const coursePage = generateRoute(PAGEROUTE.COURSE, {
    courseId,
  })

  if (currentStep === STEPS.START) {
    return (
      <View as="div">
        <SearchHeader />

        <View as="div" margin="medium">
          <Text size="large">{`${t("home.hi")}, ${userQuery.data.name}`}</Text>
        </View>
        <View as="div" background="secondary" padding="medium">
          <View as="div" margin="x-small 0 medium 0">
            <Text>You’re about to start a new chapter in X [days].</Text>
          </View>
          <View as="div" margin="0 0 x-small 0">
            <Text weight="bold" size="small">
              [{userQuery.data?.name}]
            </Text>
          </View>
          <View as="div">
            <View as="div" margin="0 0 large 0">
              <Text size="x-large">{courseNameQuery.data?.courseName}</Text>
            </View>
          </View>
          <View
            as="div"
            padding="large"
            background="primary"
            onClick={goToNextStep}
          >
            <View as="div" margin="0 0 large 0">
              <Flex justifyItems="space-between" alignItems="center">
                <Text size="large" weight="light">
                  Set your goal statement
                </Text>
                <IconArrowEndSolid />
              </Flex>
            </View>
            <Text size="medium">
              What exactly are you trying to achieve from taking this program.
            </Text>
          </View>
          <View
            as="div"
            padding="large"
            background="primary"
            margin="medium 0 0 0"
            onClick={() => navigate(coursePage)}
          >
            <View as="div">
              <Flex justifyItems="space-between" alignItems="center">
                <Text size="large" weight="light">
                  Review the modules
                </Text>
                <IconArrowEndSolid />
              </Flex>
            </View>
          </View>
        </View>
      </View>
    )
  }

  const renderGoals = () => {
    return (
      <Goals
        currentStep={currentStep}
        setCurrentStep={setCurrentStep}
        learningIntention={learningIntention}
        careerOutcome={careerOutcome}
        lifeOutcome={lifeOutcome}
      />
    )
  }

  if (currentStep === STEPS.LEARNING_INTENTION) {
    return (
      <Step
        inputName={"learning-intention"}
        choicesTitle={"Select a learning intention"}
        state={learningIntention}
        setter={setLearningIntention}
        choices={LEARNING_INTENTIONS}
        currentStep={currentStep}
        setCurrentStep={setCurrentStep}
        renderGoals={renderGoals}
      />
    )
  }
  if (currentStep === STEPS.CAREER_OUTCOME) {
    return (
      <Step
        inputName={"career-outcome"}
        choicesTitle={"Select a career outcome"}
        state={careerOutcome}
        setter={setCareerOutcome}
        choices={CAREER_OUTCOMES}
        currentStep={currentStep}
        setCurrentStep={setCurrentStep}
        renderGoals={renderGoals}
      />
    )
  }
  if (currentStep === STEPS.LIFE_OUTCOME) {
    return (
      <Step
        inputName={"life-outcome"}
        choicesTitle={"Select a life outcome"}
        state={lifeOutcome}
        setter={setLifeOutcome}
        choices={LIFE_OUTCOMES}
        currentStep={currentStep}
        setCurrentStep={setCurrentStep}
        renderGoals={renderGoals}
      />
    )
  }
  if (currentStep === STEPS.PREVIEW) {
    return (
      <View as="div" background="secondary">
        <View as="div" padding="large 0" margin="0 0 0 large">
          <Text transform="uppercase">Pre-course check in</Text>
        </View>
        <View as="div" background="primary" padding="large">
          <Message />
          {renderGoals()}
          <Text>Looks good? Save it.</Text>
          <Button
            margin="small 0"
            display="block"
            onClick={() => navigate(coursePage)}
          >
            Set goal statement
          </Button>
        </View>
      </View>
    )
  }
}

import { useEffect } from "react"
import type { z } from "zod"

type Issue = {
  expected: unknown
  received: unknown
}

const issuesEntries: [string, Issue][] = []

const useValidateResponse = <T>(
  label: string,
  result: { data?: T },
  schema: z.Schema<T>,
) => {
  const inDevMode = import.meta.env.MODE === "development"
  useEffect(() => {
    if (inDevMode && result.data) {
      const parseResult = schema.safeParse(result.data)
      if (!parseResult.success) {
        for (const issue of parseResult.error.issues) {
          if (issue.code === "invalid_type") {
            issuesEntries.push([
              `${label}${issue.path.length > 0 ? `::${issue.path.join(".")}` : ""}`,
              {
                expected: issue.expected,
                received: issue.received,
              },
            ])
          } else {
            issuesEntries.push([
              `${label}: ${issue.message}`,
              {
                expected: undefined,
                received: undefined,
              },
            ])
          }
        }
      }
    }
  }, [label, result.data, schema.safeParse])

  // useEffect / do console.table on issues every 2 seconds, then clear issues[]
  useEffect(() => {
    if (inDevMode) {
      const interval = setInterval(() => {
        if (issuesEntries.length) {
          console.table(Object.fromEntries(issuesEntries), [
            "expected",
            "received",
          ])
          issuesEntries.length = 0
        }
      }, 2000)
      return () => clearInterval(interval)
    }
  }, [])
}

export default useValidateResponse

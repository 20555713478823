import { useAppContext } from "@/AppContext"
import { Icon } from "@/icons/Icon"
import type { LinkTheme } from "@instructure/shared-types"
import { Flex, Link, View } from "@instructure/ui"
import { generateLinkComponentTheme } from "./theme"

export type CLXLinkProps = Omit<
  React.ComponentProps<typeof Link>,
  "themeOverride" | "color" | "renderIcon"
> & {
  color: "link" | "link-inverse" | "blue" | "beige"
  clxIconName?: string
}

type LinkOverrideType = {
  Link: {
    iconSize: string
  }
}

export const CLXLink = ({ children, color, ...props }: CLXLinkProps) => {
  const { theme } = useAppContext()
  return (
    <Link
      {...props}
      themeOverride={
        generateLinkComponentTheme(color) as () => Partial<LinkTheme>
      }
      renderIcon={
        props.clxIconName && (
          <View
            as="div"
            display="inline-block"
            height={
              (theme.componentOverrides as LinkOverrideType).Link.iconSize
            }
            width={
              (theme.componentOverrides as LinkOverrideType).Link
                .iconSize as string
            }
          >
            <Flex>
              <Icon width={"100%"} height={"100%"} name={props.clxIconName} />
            </Flex>
          </View>
        )
      }
    >
      {children}
    </Link>
  )
}

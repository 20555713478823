import { type MarkDone, useMarkDone } from "@/api/mutations/useMarkDone"
import { useGetModuleItem } from "@/api/queries/useGetModules"
import { Icon } from "@/icons/Icon"
import { CLXButton } from "@/shared/components/Button/Button"
import { FetchingError } from "@/shared/loading/FetchingError"
import { GlobalLoader } from "@/shared/loading/GlobalLoader"
import { spacing } from "@/themes/horizon/src/spacing"
import { Spinner, View } from "@instructure/ui"
import "src/FlexStyles.css"

export const MarkDoneButton = ({ courseId, moduleId, itemId }: MarkDone) => {
  const markDoneMutation = useMarkDone({ courseId, moduleId, itemId })

  const moduleItemQuery = useGetModuleItem({
    courseId,
    moduleId,
    itemId,
  })
  if (moduleItemQuery.isLoading) {
    return <GlobalLoader title="Loading..." />
  }

  if (moduleItemQuery.error && !moduleItemQuery.data) {
    return <FetchingError />
  }

  const markDoneType =
    moduleItemQuery.data?.completion_requirement?.type === "must_mark_done"
  const done = moduleItemQuery.data?.completion_requirement?.completed

  const buttonText = (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        gap: spacing.xSmall,
      }}
    >
      {markDoneMutation.isPending || moduleItemQuery.isRefetching ? (
        <Spinner size="x-small" renderTitle="Loading mark as done" />
      ) : done ? (
        <>
          <Icon name="select_check_box" />
          Done
        </>
      ) : (
        <>
          <Icon name="check_box_outline_blank" />
          Mark as Done
        </>
      )}
    </div>
  )

  return (
    markDoneType && (
      <View padding="small 0" as="div">
        <CLXButton
          level="secondary"
          interaction={
            markDoneMutation.isPending || moduleItemQuery.isRefetching
              ? "readonly"
              : "enabled"
          }
          onClick={() => {
            markDoneMutation.mutate(!done)
          }}
        >
          {buttonText}
        </CLXButton>
      </View>
    )
  )
}

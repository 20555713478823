import type { BaseTheme, ViewTheme } from "@instructure/shared-types"
import type { View } from "@instructure/ui"
import type { ComponentProps } from "react"
import type { horizonTheme } from "../../../themes/horizon"

export const CARD_VARIANTS = ["card", "page", "ai"] as const
export type CardVariant = (typeof CARD_VARIANTS)[number]

type ViewProps = ComponentProps<typeof View>

export const generateComponentTheme =
  (externalThemeOverride: ViewProps["themeOverride"], variant: CardVariant) =>
  (componentTheme: ViewTheme, globalTheme: BaseTheme) => {
    const currentTheme = globalTheme as unknown as typeof horizonTheme

    let override: Partial<ViewTheme> = {
      backgroundPrimary: currentTheme.ui.surfaceCardPrimary,
      backgroundSecondary: currentTheme.ui.surfaceCardSecondary,
      shadowAbove: currentTheme.shadows.above,
    }

    if (variant === "page") {
      override = {
        ...override,
        backgroundPrimary: currentTheme.ui.surfacePagePrimary,
        backgroundSecondary: currentTheme.ui.surfacePageSecondary,
      }
    }

    if (variant === "ai") {
      override = {
        ...override,
        backgroundPrimary: currentTheme.gradients.blue,
        backgroundSecondary: currentTheme.gradients.blue,
        color: currentTheme.ui.textSurfaceColored,
        shadowAbove: currentTheme.shadows.depth3,
      }
    }

    return {
      ...override,
      ...(typeof externalThemeOverride === "function"
        ? externalThemeOverride(componentTheme, globalTheme)
        : externalThemeOverride),
    }
  }

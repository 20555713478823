import { QUERY_KEYS } from "@/api/queryKeys.ts"
import { useQuery } from "@tanstack/react-query"
import { convertToHtml } from "mammoth"
import { z } from "zod"
import useValidateResponse from "../useValidateResponse.ts"

const ZDocContentResponse = z.object({
  messages: z.array(
    z.object({
      type: z.string(),
      message: z.string(),
    }),
  ),
  value: z.string(),
})

type DocContentResponse = z.infer<typeof ZDocContentResponse>

export const useGetDoc = (url: string) => {
  const queryKey = QUERY_KEYS.DOC.byUrl(url)
  const queryResult = useQuery({
    queryKey,
    queryFn: async (): Promise<DocContentResponse> => {
      const file = await fetch(url)
      return convertToHtml({ arrayBuffer: await file.arrayBuffer() })
    },
  })

  useValidateResponse(queryKey.toString(), queryResult, ZDocContentResponse)
  return queryResult
}

import { formatNumberToPercentage } from "@/shared/utils"
import { borders } from "@/themes/horizon/src/borders"
import { spacing } from "@/themes/horizon/src/spacing"
import { typography } from "@/themes/horizon/src/typography"
import { ui } from "@/themes/horizon/src/ui"
import { ProgressBar as InstUIProgressBar } from "@instructure/ui"
import "./styles.css"

type Props = {
  readonly valueNow: number
  readonly valueMax: number
  readonly completionPercentage: number
  readonly shouldRenderValue?: boolean
  readonly renderValueInside?: boolean
}

export function CLXProgressBar({
  valueMax,
  valueNow,
  completionPercentage,
  shouldRenderValue,
  renderValueInside,
}: Props) {
  return (
    <InstUIProgressBar
      themeOverride={{
        meterColorBrand: ui.surfaceInstitution,
        borderRadius: borders.radiusLarge,
      }}
      meterColor="brand"
      screenReaderLabel={`${formatNumberToPercentage(
        completionPercentage,
      )} Complete`}
      margin="small 0"
      valueNow={valueNow || 0}
      valueMax={valueMax || 100}
      renderValueInside={renderValueInside}
      renderValue={() => (
        <ProgressBarValue
          shouldRenderValue={shouldRenderValue}
          renderValueInside={renderValueInside}
          completionPercentage={completionPercentage}
        />
      )}
    />
  )
}

type ProgressBarValueProps = {
  readonly shouldRenderValue?: boolean
  readonly renderValueInside?: boolean
  readonly completionPercentage: number
}

function ProgressBarValue({
  shouldRenderValue,
  renderValueInside,
  completionPercentage,
}: ProgressBarValueProps) {
  if (!shouldRenderValue) {
    return null
  }

  if (renderValueInside) {
    return <InsideValue completionPercentage={completionPercentage} />
  }

  return <OutsideValue completionPercentage={completionPercentage} />
}

type ValueProps = {
  readonly completionPercentage: number
}

function InsideValue({ completionPercentage }: ValueProps) {
  return (
    <span
      style={{
        display: "flex",
        justifyContent: "end",
        alignItems: "center",
        height: "100%",
        paddingRight: spacing.small,
        fontWeight: "bold",
        color: ui.surfaceCardSecondary,
      }}
    >
      {formatNumberToPercentage(completionPercentage, 0)}
    </span>
  )
}

function OutsideValue({ completionPercentage }: ValueProps) {
  return (
    <span
      style={{
        color: ui.surfaceInstitution,
        fontWeight: typography.fontWeightSemiBold,
        paddingLeft: spacing.small,
      }}
    >
      {formatNumberToPercentage(completionPercentage, 0)}
    </span>
  )
}

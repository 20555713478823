import { canvasGqlClient } from "@/api/api.ts"
import { QUERY_KEYS } from "@/api/queryKeys.ts"
import useValidateResponse from "@/api/useValidateResponse.ts"
import { useMutation, useQueryClient } from "@tanstack/react-query"
import { gql } from "graphql-request"
import { z } from "zod"

const ZDeleteConversationResponse = z
  .object({
    deleteConversations: z.object({
      conversationIds: z.string(),
      errors: z
        .array(
          z
            .object({
              message: z.string(),
            })
            .strict(),
        )
        .nullable(),
    }),
  })
  .strict()

type DeleteConversationResponse = z.infer<typeof ZDeleteConversationResponse>

export const DELETE_CONVERSATIONS = gql`
    mutation DeleteConversations($ids: [ID!]!) {
        deleteConversations(input: {ids: $ids}) {
            conversationIds
            errors {
                message
            }
        }
    }
`

export const useDeleteConversation = () => {
  const queryClient = useQueryClient()
  const mutationResult = useMutation({
    mutationFn: async ({
      ids,
    }: { ids: string[] }): Promise<DeleteConversationResponse> =>
      (await canvasGqlClient()).request(DELETE_CONVERSATIONS, {
        ids,
      }),
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: QUERY_KEYS.CONVERSATION.all,
      })
    },
  })

  useValidateResponse(
    "useDeleteConversation",
    mutationResult,
    ZDeleteConversationResponse,
  )
  return mutationResult
}

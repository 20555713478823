import { QUERY_KEYS } from "@/api/queryKeys.ts"
import { type UseQueryResult, useQuery } from "@tanstack/react-query"
import { gql } from "graphql-request"
import { z } from "zod"
import { canvasGqlClient } from "../api.ts"
import { ZSubmissions, type ZSubmissionsType } from "../types.ts"
import useValidateResponse from "../useValidateResponse.ts"

const ZSubmissionHistory = z
  .object({
    node: z.object({
      submissionHistoriesConnection: z.object({
        nodes: ZSubmissions,
      }),
    }),
  })
  .strict()

type ZSubmissionResponse = z.infer<typeof ZSubmissionHistory>

const query = gql`query getSubmissions($submissionId: ID!) {
  node(id: $submissionId) {
    ... on Submission {
      submissionHistoriesConnection {
        nodes {
          submittedAt
          body
          grade
          score
          submissionType
          attempt
          attachment {
						...SubmissionFile
          }
          attachments {
            ...SubmissionFile
          }
        }
      }
    }
  }
}

fragment SubmissionFile on File {
  displayName
  id
  mimeClass
  url
  contentType
}
`

export const useGetSubmission = (
  submissionId: string,
): UseQueryResult<ZSubmissionsType> => {
  const queryKey = QUERY_KEYS.SUBMISSION.byId(submissionId)
  const queryResult = useQuery({
    queryKey,
    queryFn: async (): Promise<ZSubmissionResponse> =>
      (await canvasGqlClient()).request(query, { submissionId }),
    select: (data) => {
      return data.node.submissionHistoriesConnection.nodes
    },
  })

  useValidateResponse(queryKey.toString(), queryResult, ZSubmissions)
  return queryResult
}

import { useCreateSubmission } from "@/api/mutations/useCreateSubmission.ts"
import { useJWT } from "@/api/mutations/useJWT"
import { AssignmentSubmissionControls } from "@/features/learning_content/AssignmentSubmissionControls.tsx"
import { FetchingError } from "@/shared/loading/FetchingError"
import { GlobalLoader } from "@/shared/loading/GlobalLoader"
import { View } from "@instructure/ui"
import { useState } from "react"
import { useParams } from "react-router-dom"
import { RCEInput } from "src/shared/components/RCE/RichContentInput/RCEInput.tsx"

export const TextEntrySubmissionForm = () => {
  const { assignmentId = "", courseId = "" } = useParams()
  const [textEntry, setTextEntry] = useState("")
  const createSubmissionMutation = useCreateSubmission()

  const jwt = useJWT()

  if (jwt.isLoading) {
    return <GlobalLoader title="Loading..." />
  }

  if (jwt.isError) {
    return <FetchingError />
  }

  return (
    <View as="div" margin="small 0">
      <RCEInput
        label="Text entry submission"
        defaultContent={textEntry}
        onChange={(editorContent) => {
          setTextEntry(editorContent)
        }}
        textareaId={"Submission"}
        courseId={courseId}
        token={jwt.data.token}
      />
      <AssignmentSubmissionControls
        entryValid={textEntry.length > 0}
        onSubmit={() =>
          createSubmissionMutation.mutate({
            assignmentLid: assignmentId,
            submissionType: "online_text_entry",
            body: textEntry,
          })
        }
        isPending={createSubmissionMutation.isPending}
        isSuccess={createSubmissionMutation.isSuccess}
        errorMessage={createSubmissionMutation.error?.message}
      />
    </View>
  )
}

import { useLogout } from "@/api/mutations/useLogout.ts"
import { clearCodeVerifier } from "@/features/login/pkce-verifier-storage.ts"
import { useOnMount } from "@/shared/hooks/useOnMount.ts"
import { GlobalLoader } from "@/shared/loading/GlobalLoader.tsx"
import { PAGEROUTE } from "@/shared/router"
import { Heading, Text, View } from "@instructure/ui"
import { Link } from "react-router-dom"
import { clearTokenData } from "../../token-storage.ts"

export const Logout = () => {
  const logoutMutation = useLogout({
    onSettled: () => {
      clearCodeVerifier()
      clearTokenData()
    },
  })

  useOnMount(() => {
    logoutMutation.mutate()
  })

  if (logoutMutation.isSuccess) {
    return (
      <View as="div" padding="medium">
        <Heading level="h1" margin="small 0">
          Logged out
        </Heading>
        <Link to={PAGEROUTE.HOME}>Login again</Link>
      </View>
    )
  }

  if (logoutMutation.isError) {
    return <Text>An error occurred logging out.</Text>
  }

  return <GlobalLoader title="Logging out..." />
}

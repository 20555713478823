export enum STEPS {
  START = 0,
  LEARNING_INTENTION = 1,
  CAREER_OUTCOME = 2,
  LIFE_OUTCOME = 3,
  PREVIEW = 4,
}

export const LEARNING_INTENTIONS = [
  "learn new skills and knowledge",
  "improve my current skillset",
  "gain the knowledge and confidence",
  "become an expert in this area",
]

export const CAREER_OUTCOMES = [
  "find a new job",
  "be promoted to a new role",
  "explore a new career path",
  "improve in my current role",
]

export const LIFE_OUTCOMES = [
  "increase my salary",
  "improve quality of life",
  "set a healthy work-life balance",
  "support my family",
  "maintain my lifestyle",
]

import type { FormFieldMessageTheme } from "@instructure/shared-types"
import { primitives } from "../primitives"

const FormFieldThemeOverrides: Partial<FormFieldMessageTheme> = {
  colorError: primitives.red70,
  colorHint: primitives.grey125,
}

export default {
  FormField: FormFieldThemeOverrides,
}

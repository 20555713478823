import { useAppContext } from "@/AppContext.tsx"
import type { ModuleItem } from "@/api/types.ts"
import { Icon } from "@/icons/Icon.tsx"
import { CLXButton } from "@/shared/components/Button/Button.tsx"
import { CLXIconButton } from "@/shared/components/IconButton/IconButton.tsx"
import { getLearningObjectLink } from "@/shared/components/utils.tsx"
import { spacing } from "@/themes/horizon/src/spacing.ts"
import { Flex } from "@instructure/ui"
import type { PropsWithChildren } from "react"
import { useNavigate } from "react-router-dom"
import type { LearningObjectProps } from "../LearningObjectWrapper.tsx"
import { DesktopLearningContentHeader } from "./DesktopLearningContentHeader.tsx"
import { DesktopLearningObjectContent } from "./DesktopLearningObjectContent.tsx"
import { DesktopLearningObjectNavigation } from "./DesktopLearningObjectNavigation.tsx"

type NavigationButtonProps = {
  clxIconName: string
  text: string
  isIconAfter?: boolean
  moduleItem: ModuleItem
  courseId: string
}

const NavigationButton = ({
  clxIconName,
  text,
  isIconAfter = false,
  moduleItem,
  courseId,
}: NavigationButtonProps) => {
  const { isLargeDesktopView } = useAppContext()
  const navigate = useNavigate()
  const redirectUrl = getLearningObjectLink(
    moduleItem.type,
    moduleItem.quiz_lti,
    courseId,
    moduleItem,
    moduleItem.module_id.toString(),
  )
  return (
    <div
      style={{ position: "sticky", bottom: spacing.large, alignSelf: "end" }}
    >
      {isLargeDesktopView ? (
        <CLXButton
          level="secondary"
          withBoxShadow={true}
          renderIcon={isIconAfter ? null : <Icon name={clxIconName} />}
          renderIconAfter={isIconAfter ? <Icon name={clxIconName} /> : null}
          size="small"
          onClick={() => {
            navigate(redirectUrl)
          }}
        >
          {text}
        </CLXButton>
      ) : (
        <CLXIconButton
          screenReaderLabel={text}
          level="secondary"
          withBoxShadow={true}
          clxIconName={clxIconName}
          size="small"
          onClick={() => {
            navigate(redirectUrl)
          }}
        />
      )}
    </div>
  )
}

export const DesktopLearningObject = (
  props: PropsWithChildren<LearningObjectProps>,
) => {
  const { viewportHeight } = useAppContext()

  return (
    <Flex as="div">
      <DesktopLearningObjectNavigation
        courseId={props.courseId}
        learningAssistContext={props.learningAssistContext ?? ""}
      />
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "start",
          padding: `0 ${spacing.large}`,
          minHeight: viewportHeight,
          flexGrow: 2,
        }}
      >
        {props.previousItem ? (
          <NavigationButton
            clxIconName="chevron_left"
            text="Back"
            courseId={props.courseId}
            moduleItem={props.previousItem}
          />
        ) : (
          <div />
        )}
        <div
          style={{
            display: "flex",
            padding: `0 ${spacing.medium}`,
            flexDirection: "column",
            gap: spacing.large,
          }}
        >
          <DesktopLearningContentHeader {...props} />
          <DesktopLearningObjectContent {...props} />
        </div>
        {props.nextItem ? (
          <NavigationButton
            clxIconName="chevron_right"
            text="Next"
            isIconAfter
            courseId={props.courseId}
            moduleItem={props.nextItem}
          />
        ) : (
          <div />
        )}
      </div>
    </Flex>
  )
}

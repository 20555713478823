import {
  type ZMimeClassesType,
  type ZMimeTypesType,
  mimeTypeToClassMap,
} from "@/api/types"
import { Img } from "@instructure/ui"
import { AudioContent } from "./AudioContent"
import { DocContent } from "./DocContent"
import { PDFContent } from "./PDFContent"
import { VideoContent } from "./VideoContent"

type FileViewProps = {
  mimeClass: ZMimeClassesType
  contentType: ZMimeTypesType
  url: string
  displayName: string
}

const contentTypeSpecificComponents: Partial<
  Record<
    ZMimeClassesType,
    (props: {
      url: string
      contentType: ZMimeTypesType
      displayName: string
    }) => JSX.Element
  >
> = {
  audio: AudioContent,
  doc: DocContent,
  pdf: PDFContent,
  video: VideoContent,
  image: ({
    url,
    displayName,
  }: {
    url: string
    displayName: string
  }) => <Img src={url} alt={displayName} />,
}

export const FileView = ({
  mimeClass,
  contentType,
  url,
  displayName,
}: FileViewProps) => {
  const fileType = mimeTypeToClassMap[contentType] ?? mimeClass

  const SpecificComponent =
    contentTypeSpecificComponents[fileType] ??
    contentTypeSpecificComponents[mimeClass]

  if (!SpecificComponent) {
    // TODO: What should we do with unsupported file types? Download them?
    return <div>Unsupported file type</div>
  }

  return (
    <SpecificComponent
      url={url}
      contentType={contentType}
      displayName={displayName}
    />
  )
}

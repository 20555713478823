// Sentry initialization should be done as early as possible in the boot process
import "./instrument.ts"

import { RefreshCredentialsModal } from "@/features/login/RefreshCredentialsModal.tsx"
import ReactDOM from "react-dom/client"
import { RouterProvider } from "react-router-dom"
import { AppContextProvider } from "./AppContext.tsx"
import { WrappedQueryClientProvider } from "./QueryClientProvider.tsx"
import "./config/i18n"
import { ThemeProvider } from "@/ThemeProvider.tsx"
import Masquerade from "@/shared/components/Masquerade/Masquerade.tsx"
import { ToastProvider } from "@/shared/components/Toast/ToastContext.tsx"
import router from "./router.tsx"

const rootEl = document.getElementById("root")

if (rootEl) {
  const root = ReactDOM.createRoot(rootEl)

  root.render(
    <AppContextProvider>
      <ThemeProvider>
        <WrappedQueryClientProvider>
          <Masquerade>
            <ToastProvider>
              <RouterProvider router={router} />
              <RefreshCredentialsModal />
            </ToastProvider>
          </Masquerade>
        </WrappedQueryClientProvider>
      </ThemeProvider>
    </AppContextProvider>,
  )
}

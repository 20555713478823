import { MODULE_STATE } from "@/api/types.ts"
import { Icon } from "@/icons/Icon.tsx"
import { CLXPill } from "@/shared/components/Pill/Pill.tsx"
import { ui } from "@/themes/horizon/src/ui.ts"

export interface ModuleContainerStateLabelProps {
  state: MODULE_STATE | null
}

const STATE_TO_LABEL: Record<MODULE_STATE, string> = {
  [MODULE_STATE.COMPLETED]: "Completed",
  [MODULE_STATE.STARTED]: "In progress",
  [MODULE_STATE.UNLOCKED]: "Not started",
  [MODULE_STATE.LOCKED]: "Locked",
}

export const ModuleContainerStateLabel = ({
  state,
}: ModuleContainerStateLabelProps) => {
  if (!state) {
    return null
  }
  const stateLabel = STATE_TO_LABEL[state]

  return (
    <CLXPill
      pillSize={
        state === MODULE_STATE.LOCKED || state === MODULE_STATE.UNLOCKED
          ? "x-small"
          : "small"
      }
      color="info"
      fill={state === MODULE_STATE.COMPLETED ? "solid" : "outline"}
      isBorderVisible={state === MODULE_STATE.STARTED}
      renderIcon={
        state === MODULE_STATE.LOCKED ? (
          <Icon
            name="lock"
            width={18}
            height={18}
            customColor={ui.surfaceInstitution}
          />
        ) : null
      }
    >
      {stateLabel}
    </CLXPill>
  )
}

import type { Breakpoints } from "@instructure/shared-types/types/BaseTheme"
import { canvas } from "@instructure/ui"
import { useState } from "react"
import { useEvent, useIsomorphicLayoutEffect } from "react-use"

export type ResponsiveBreakpointMatches = Record<keyof Breakpoints, boolean>

interface ResponsiveOptions {
  breakPointOverrides?: Partial<Breakpoints>
}
const getDefaultMatches = (): ResponsiveBreakpointMatches =>
  Object.fromEntries(
    Object.keys(canvas.breakpoints).map((key) => [key, false]),
  ) as ResponsiveBreakpointMatches

export const emToPxNumber = (em: string): number =>
  Number.parseFloat(em) *
  Number.parseFloat(
    getComputedStyle(document.documentElement).fontSize || "16px",
  )

export const useResponsive = (
  props?: ResponsiveOptions,
): ResponsiveBreakpointMatches => {
  const { breakPointOverrides } = props ?? {}
  const [matches, setMatches] = useState<ResponsiveBreakpointMatches>(
    getDefaultMatches(),
  )
  const handleSize = () => {
    const changedMatches = Object.fromEntries(
      Object.keys(canvas.breakpoints).map((key) => [
        key,
        emToPxNumber(
          breakPointOverrides?.[key as keyof Breakpoints] ??
            canvas.breakpoints[key as keyof Breakpoints],
        ) >= window.innerWidth,
      ]),
    ) as ResponsiveBreakpointMatches
    if (Object.values(changedMatches).every((match) => !match)) {
      changedMatches.desktop = true
      changedMatches.xLarge = true
    }
    if (JSON.stringify(changedMatches) !== JSON.stringify(matches)) {
      setMatches(changedMatches)
    }
  }
  useEvent("resize", handleSize)
  // Set size at the first client-side load
  useIsomorphicLayoutEffect(() => {
    handleSize()
  }, [])
  return matches
}

import type { ModuleItem } from "@/api/types.ts"
import { CLXIconButton } from "@/shared/components/IconButton/IconButton.tsx"
import { AiNavigationItem } from "@/shared/components/Navigation/AiNavigationItem.tsx"
import { AI } from "@/shared/components/Navigation/constants.ts"
import { NoteTaking } from "@/shared/components/Notebook/NoteTaking.tsx"
import { getLearningObjectLink } from "@/shared/components/utils.tsx"
import { SearchParams } from "@/shared/router"
import { spacing } from "@/themes/horizon/src/spacing.ts"
import { Flex } from "@instructure/ui"
import { useCallback } from "react"
import { useNavigate, useSearchParams } from "react-router-dom"

type MobileLearningObjectControlsProps = {
  courseId: string
  learningAssistContext?: string
  nextItem: ModuleItem | null
  prevItem: ModuleItem | null
}

export const MobileLearningObjectControls = ({
  courseId,
  learningAssistContext,
  nextItem,
  prevItem,
}: MobileLearningObjectControlsProps) => {
  const [searchParams, setSearchParams] = useSearchParams()
  const navigate = useNavigate()

  const getRedirectUrl = useCallback(
    (moduleItem: ModuleItem) => {
      return getLearningObjectLink(
        moduleItem.type,
        moduleItem.quiz_lti,
        courseId,
        moduleItem,
        moduleItem.module_id.toString(),
      )
    },
    [courseId],
  )

  const addNoteParam = () => {
    const newSearchParams = new URLSearchParams(searchParams)
    newSearchParams.set(SearchParams.ADD_NOTE, "true")
    setSearchParams(newSearchParams)
  }
  return (
    <>
      <div
        style={{
          margin: `${spacing.mediumSmall} ${spacing.medium}`,
        }}
      >
        <Flex as="div" justifyItems="space-between">
          {prevItem ? (
            <CLXIconButton
              screenReaderLabel={"Previous"}
              level="primary"
              variant="inverse"
              withBoxShadow={true}
              clxIconName="chevron_left"
              size="small"
              onClick={() => {
                navigate(getRedirectUrl(prevItem))
              }}
            />
          ) : (
            <div />
          )}
          <Flex as="div" justifyItems="center" gap="small">
            <CLXIconButton
              screenReaderLabel="Listen to this"
              level="primary"
              variant="inverse"
              withBoxShadow={true}
              clxIconName="volume_up"
              size="small"
            />
            <AiNavigationItem
              isIconButton={true}
              context={learningAssistContext}
              {...AI}
            />
            <CLXIconButton
              screenReaderLabel="Take note"
              level="primary"
              variant="inverse"
              withBoxShadow={true}
              clxIconName="menu_book_notebook"
              size="small"
              onClick={addNoteParam}
            />
          </Flex>
          {nextItem ? (
            <CLXIconButton
              screenReaderLabel={"Next"}
              level="primary"
              variant="inverse"
              withBoxShadow={true}
              clxIconName="chevron_right"
              size="small"
              onClick={() => {
                navigate(getRedirectUrl(nextItem))
              }}
            />
          ) : (
            <div />
          )}
        </Flex>
      </div>
      {courseId && (
        <NoteTaking courseId={courseId} institutionName="Dummy institution" />
      )}
    </>
  )
}

import { useGetCourses } from "@/api/queries/useGetCourses.ts"
import { CLXProgressBar } from "@/shared/components/ProgressBar/ProgressBar"
import { SearchHeader } from "@/shared/header/SearchHeader.tsx"
import { FetchingError } from "@/shared/loading/FetchingError.tsx"
import { GlobalLoader } from "@/shared/loading/GlobalLoader.tsx"
import { PAGEROUTE, generateRoute } from "@/shared/router"
import {
  formatNumberToPercentage,
  getCourseProgressionValues,
} from "@/shared/utils"
import { getUserId } from "@/token-storage.ts"
import { Flex, Text, View } from "@instructure/ui"
import { useEffect } from "react"
import { Link, useNavigate } from "react-router-dom"

export const Programs = () => {
  const coursesQuery = useGetCourses(getUserId())
  const navigate = useNavigate()

  /* remove this effect after beta */
  useEffect(() => {
    if (coursesQuery.isLoading || coursesQuery.isError || !coursesQuery.data) {
      return
    }
    const firstCourse = coursesQuery.data[0]
    if (firstCourse) {
      const route = generateRoute(PAGEROUTE.COURSE, {
        courseId: firstCourse.course._id,
      })
      navigate(route)
    }
  }, [coursesQuery, navigate])

  if (coursesQuery.isLoading) {
    return <GlobalLoader title="Loading..." renderInContent={true} />
  }

  if (coursesQuery.isError || !coursesQuery.data) {
    return <FetchingError />
  }

  /* remove loader and hidden view after beta,
	   for beta the learner will have only one course,
	   we should load it for them automatically
	*/
  return (
    <>
      <GlobalLoader title="Loading..." renderInContent={true} />
      <View className="hidden">
        <SearchHeader />
        <View as="div" margin="medium">
          {coursesQuery.data.map((course) => {
            const { completed, total, completionPercentage } =
              getCourseProgressionValues(
                course.course.usersConnection.nodes[0].courseProgression,
              )
            return (
              <Link
                key={course.course._id}
                to={generateRoute(PAGEROUTE.COURSE, {
                  courseId: course.course._id,
                })}
                style={{
                  textDecoration: "none",
                }}
              >
                <View
                  as="div"
                  background="secondary"
                  margin="medium 0"
                  padding="medium"
                >
                  <Text size="small">{course.course.account.name}</Text>
                  <View as="div" margin="small 0">
                    <Text size="large">{course.course.name}</Text>
                  </View>
                  <CLXProgressBar
                    valueNow={completed}
                    valueMax={total}
                    completionPercentage={completionPercentage}
                  />
                  <Flex
                    as="div"
                    justifyItems="space-between"
                    margin="xx-small 0"
                  >
                    <Flex.Item>
                      <Text size="small">
                        {formatNumberToPercentage(
                          course.course.usersConnection.nodes[0]
                            .courseProgression?.requirements
                            .completionPercentage ?? 0,
                        )}
                      </Text>
                    </Flex.Item>
                    <Flex.Item>
                      <Text size="small">
                        {getProgressText(
                          course.course.usersConnection.nodes[0]
                            .courseProgression?.requirements
                            .completionPercentage ?? 0,
                        )}
                      </Text>
                    </Flex.Item>
                  </Flex>
                </View>
              </Link>
            )
          })}
        </View>
      </View>
    </>
  )
}

function getProgressText(progress: number) {
  switch (progress) {
    case 0:
      return "Not Started"
    case 100:
      return "Completed"
    default:
      return "On Track"
  }
}

import { LearningObjectLockedContent } from "@/features/learning_content/learning_objects_skeleton/LearningObjectLockedContent.tsx"
import { useNavigationContext } from "@/shared/components/Navigation/NavigationContext.tsx"
import { NoteTaking } from "@/shared/components/Notebook/NoteTaking.tsx"
import { spacing } from "@/themes/horizon/src/spacing.ts"
import { ui } from "@/themes/horizon/src/ui.ts"
import { Flex, View } from "@instructure/ui"
import {
  type PropsWithChildren,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react"
import { MarkDoneButton } from "../../MarkDoneButton.tsx"
import { LearningObjectMyProgress } from "../LearningObjectMyProgress.tsx"
import type { LearningObjectProps } from "../LearningObjectWrapper.tsx"
import {
  DESKTOP_LEARNING_OBJECT_CONTENT_WIDTH,
  DESKTOP_LEARNING_OBJECT_CONTROLS_HEIGHT,
} from "../constants.ts"
import { DesktopLearningObjectControls } from "./DesktopLearningObjectControls.tsx"

export const DesktopLearningObjectContent = ({
  children,
  courseId,
  moduleItem,
  module,
  hasMoreRelevantSequence,
}: PropsWithChildren<LearningObjectProps>) => {
  const elementRef = useRef<HTMLDivElement>(null)
  const [controlContainerTopMargin, setControlContainerTopMargin] = useState(0)
  const [isMyProgressOpen, setIsMyProgressOpen] = useState(false)
  const { desktopScrollRef } = useNavigationContext()

  const calculateHeights = useCallback(() => {
    if (!elementRef.current) return

    const rect = elementRef.current.getBoundingClientRect()
    const viewportHeight =
      window.innerHeight || document.documentElement.clientHeight

    const visible = Math.max(
      0,
      Math.min(rect.bottom, viewportHeight) - Math.max(rect.top, 0),
    )

    const scrolledOut = Math.max(0, -rect.top)

    setControlContainerTopMargin(
      scrolledOut + (visible - DESKTOP_LEARNING_OBJECT_CONTROLS_HEIGHT) / 2,
    )
  }, [])

  useEffect(() => {
    if (!desktopScrollRef?.current || !elementRef.current) {
      return
    }
    // used to recalculate the heights after the LO content loads and change the height
    const observer = new ResizeObserver(() => {
      calculateHeights()
    })

    observer.observe(elementRef.current)

    desktopScrollRef.current.addEventListener("scroll", calculateHeights)
    desktopScrollRef.current.addEventListener("resize", calculateHeights)

    return () => {
      if (!desktopScrollRef?.current || !elementRef.current) {
        return
      }
      observer.disconnect()
      desktopScrollRef.current.removeEventListener("scroll", calculateHeights)
      desktopScrollRef.current.removeEventListener("resize", calculateHeights)
    }
  }, [calculateHeights, desktopScrollRef])

  const lockExplanation = moduleItem.content_details?.lock_explanation
  const isLocked = lockExplanation != null

  return (
    <div
      ref={elementRef}
      style={{
        display: "flex",
        flexShrink: 2,
        marginBottom: spacing.large,
      }}
    >
      <View
        as="div"
        background={isLocked ? "secondary" : "primary"}
        borderRadius={isMyProgressOpen ? "large none none large" : "large"}
        shadow="above"
        width="100%"
        themeOverride={{ backgroundSecondary: ui.surfacePageTertiary }}
      >
        <Flex
          as="div"
          gap="medium"
          padding={isMyProgressOpen ? "none medium none large" : "none large"}
          alignItems="stretch"
          justifyItems="space-between"
          width="100%"
        >
          <div
            style={{
              maxWidth: DESKTOP_LEARNING_OBJECT_CONTENT_WIDTH,
              padding: `${spacing.large} 0`,
            }}
          >
            {isLocked ? (
              <LearningObjectLockedContent lockExplanation={lockExplanation} />
            ) : (
              children
            )}
            {module.id && moduleItem.id && (
              <MarkDoneButton
                courseId={courseId}
                moduleId={module.id.toString()}
                itemId={moduleItem.id.toString()}
              />
            )}
          </div>
          <div
            style={{
              marginTop: controlContainerTopMargin,
            }}
          >
            <DesktopLearningObjectControls
              onMyProgressClicked={() => setIsMyProgressOpen(!isMyProgressOpen)}
              onListenClicked={() => console.log("Implement this feature!")}
              hasMoreRelevantSequence={hasMoreRelevantSequence}
            />
          </div>
        </Flex>
      </View>
      {isMyProgressOpen && (
        <View
          as="div"
          background="primary"
          borderRadius="none large large none"
          shadow="above"
          padding="none medium none none"
        >
          <LearningObjectMyProgress
            courseId={courseId}
            module={module}
            itemId={moduleItem.id}
            onCloseClicked={() => setIsMyProgressOpen(false)}
          />
        </View>
      )}
      {courseId && (
        <NoteTaking courseId={courseId} institutionName="Dummy institution" />
      )}
    </div>
  )
}

import { spacing } from "../spacing"

export default {
  Billboard: {
    iconColor: "inherit",
    paddingMedium: spacing.xLarge,
    largeMargin: spacing.mediumSmall,
    mediumMargin: spacing.xSmall,
  },
}

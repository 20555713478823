import { useGetCourseName } from "@/api/queries/useGetCourses.ts"
import {
  actions,
  useNavigationContextDispatch,
} from "@/shared/components/Navigation/NavigationContext.tsx"
import { FetchingError } from "@/shared/loading/FetchingError.tsx"
import { GlobalLoader } from "@/shared/loading/GlobalLoader.tsx"
import { typography } from "@/themes/horizon/src/typography.ts"
import { Text } from "@instructure/ui"
import { type PropsWithChildren, useEffect } from "react"
import { LEARN_PAGE_NAVIGATION_ITEMS } from "../constants.ts"
import type { LearnLayoutProps } from "./LearnLayoutContainer.tsx"

export const LearnDesktopLayout = ({
  courseId,
  children,
}: PropsWithChildren<LearnLayoutProps>) => {
  const dispatch = useNavigationContextDispatch()
  const { data, isLoading, isError } = useGetCourseName(courseId)

  useEffect(() => {
    dispatch({
      type: actions.SET_SECONDARY_NAVIGATION,
      payload: {
        secondaryNavigationItems: LEARN_PAGE_NAVIGATION_ITEMS.map((item) => ({
          ...item,
          routeParams: { courseId },
        })),
        secondaryNavigationHeader: (
          <Text>
            <span style={{ fontWeight: typography.fontWeightSemiBold }}>
              {data?.courseName}
            </span>
          </Text>
        ),
      },
    })
    return () => {
      dispatch({
        type: actions.SET_SECONDARY_NAVIGATION,
        payload: {
          secondaryNavigationItems: [],
          secondaryNavigationHeader: "",
        },
      })
    }
  }, [courseId, data, dispatch])

  if (isLoading) {
    return <GlobalLoader title="Loading..." renderInContent={true} />
  }

  if (isError || !data) {
    return <FetchingError />
  }

  return children
}

import { useAppContext } from "@/AppContext.tsx"
import { useGetCourseProgress } from "@/api/queries/useGetCourses.ts"
import { useGetModules } from "@/api/queries/useGetModules.ts"
import { MODULE_CONTAINER_MAX_WIDTH } from "@/shared/components/ModuleContainer/ModuleContainer.tsx"
import { CLXProgressBar } from "@/shared/components/ProgressBar/ProgressBar"
import { FetchingError } from "@/shared/loading/FetchingError.tsx"
import { GlobalLoader } from "@/shared/loading/GlobalLoader.tsx"
import { getCourseProgressionValues } from "@/shared/utils"
import { getUserId } from "@/token-storage.ts"
import { Button, Flex, Text, View } from "@instructure/ui"
import { useCallback, useEffect, useMemo, useState } from "react"
import { useParams } from "react-router-dom"
import { Module } from "./Module.tsx"

const COURSE_PROGRESS_BAR_WIDTH = 350

export const MyProgress = () => {
  const { courseId = "" } = useParams()
  const { desktopMode } = useAppContext()

  const {
    data: modules,
    isLoading,
    isError,
  } = useGetModules({
    courseId,
    userId: getUserId(),
  })

  const [collapseStateByModuleId, setCollapseStateByModuleId] = useState<
    Map<number, boolean>
  >(new Map())

  const isEveryModuleExpanded = useMemo(
    () =>
      Array.from(collapseStateByModuleId.values()).every(
        (isCollapsed) => !isCollapsed,
      ),
    [collapseStateByModuleId],
  )

  const moduleNameById = useMemo(() => {
    if (!modules) {
      return new Map<number, string>()
    }
    return modules.reduce((acc, module) => {
      acc.set(module.id, module.name || "")
      return acc
    }, new Map<number, string>())
  }, [modules])

  const {
    data: courseProgressionData,
    isLoading: isCourseProgressLoading,
    isError: isCourseProgressError,
  } = useGetCourseProgress(courseId, getUserId() ?? "")

  const setCollapseStateForAll = useCallback(
    (newState: boolean) => {
      if (!modules) return
      setCollapseStateByModuleId(
        modules.reduce((acc, module) => {
          acc.set(module.id, newState)
          return acc
        }, new Map()),
      )
    },
    [modules],
  )

  useEffect(() => {
    setCollapseStateForAll(true)
  }, [setCollapseStateForAll])

  if (isLoading || isCourseProgressLoading) {
    return <GlobalLoader title="Loading..." renderInContent={true} />
  }

  if (isError || isCourseProgressError || !modules || !courseProgressionData) {
    return <FetchingError />
  }

  const { completed, total, completionPercentage } = getCourseProgressionValues(
    courseProgressionData?.courseProgression ?? null,
  )

  return (
    <View
      as="div"
      maxWidth={MODULE_CONTAINER_MAX_WIDTH}
      margin="auto"
      padding={desktopMode ? "none medium medium" : "none"}
    >
      <Flex direction="column" gap="small" alignItems="center">
        {desktopMode && (
          <>
            <Flex.Item align="start" margin="mediumSmall none none none">
              <Text size="xx-large" color="secondary" weight="bold">
                My Progress
              </Text>
            </Flex.Item>
            <Flex.Item as="div" align="start" width={COURSE_PROGRESS_BAR_WIDTH}>
              <CLXProgressBar
                valueNow={completed}
                valueMax={total}
                completionPercentage={completionPercentage}
                shouldRenderValue
                renderValueInside
              />
            </Flex.Item>
          </>
        )}
        <div style={{ alignSelf: "flex-start", width: "100%" }}>
          <Button
            color="primary-inverse"
            display={desktopMode ? "inline-block" : "block"}
            onClick={() => setCollapseStateForAll(isEveryModuleExpanded)}
          >
            {isEveryModuleExpanded ? "Collapse All" : "Expand All"}
          </Button>
        </div>
        {modules.map((module) => (
          <Module
            key={module.id}
            module={module}
            moduleNameById={moduleNameById}
            isCollapsed={collapseStateByModuleId.get(module.id) ?? false}
            setIsCollapsed={(newState: boolean) => {
              setCollapseStateByModuleId((prevState) => {
                const newStateMap = new Map(prevState)
                newStateMap.set(module.id, newState)
                return newStateMap
              })
            }}
          />
        ))}
      </Flex>
    </View>
  )
}

export const forms = {
  inputHeightXSmall: "2rem", // 32px
  inputHeightSmall: "2.25rem", // 36px
  inputHeightSmallMedium: "2.5rem", // 40px
  inputHeightMedium: "2.75rem", // 44px
  inputHeightLarge: "3rem", // 48px
  inputHeightXLarge: "3.25rem", // 52px
}

// copied default canvas theme values (not addressed in CLX design system)

import type { Note, NoteTag } from "@/api/db/db"
import { CLXButton } from "@/shared/components/Button/Button.tsx"
import {
  Flex,
  FormField,
  FormFieldLabel,
  Text,
  TextArea,
  View,
} from "@instructure/ui"
import type { UseMutateFunction } from "@tanstack/react-query"
import { useEffect, useState } from "react"
import type { PartialBy } from "src/shared/utils/index.ts"
import { TagBlock } from "./TagBlock"

type Mutation<T> = UseMutateFunction<number, Error, T, unknown>

type EditableNote = PartialBy<Note, "createdAt" | "id" | "updatedAt">

export const NoteEditor = <T,>({
  note,
  mutation,
}: {
  note: EditableNote
  mutation: Mutation<T>
}) => {
  const [noteText, setNoteText] = useState(note?.text ?? "")
  const [tags, setTags] = useState<Set<NoteTag>>(new Set(note.tags))
  const [error, setError] = useState("")
  useEffect(() => {
    if (noteText) {
      setError("")
    }
  }, [noteText])
  return (
    <View
      as="div"
      background="success"
      borderRadius="large large 0 0"
      padding="medium"
      themeOverride={{
        backgroundSuccess: "#626262",
        borderRadiusLarge: "2rem",
      }}
    >
      <TagBlock
        currentTags={tags}
        setTags={setTags}
        title="Tag"
        isMultiSelect={false}
      />
      <FormField label="" id="note">
        <FormFieldLabel
          themeOverride={{
            color: "white",
            fontWeight: 400,
            lineHeight: 2,
          }}
        >
          Add a note (optional)
        </FormFieldLabel>
        <TextArea
          value={noteText}
          label=""
          onChange={(event) => {
            setNoteText(event.target.value)
          }}
          height="10em"
        />
      </FormField>
      <Flex justifyItems="space-between" gap="small">
        <Flex.Item shouldGrow margin="small 0 0 0">
          <CLXButton
            level="primary"
            variant="inverse"
            onClick={() => {
              if (!noteText) {
                setError("Note is empty")
              } else {
                mutation({
                  ...note,
                  text: noteText,
                  tags: Array.from(tags),
                  updatedAt: Date.now(),
                  createdAt: note.createdAt ?? Date.now(),
                } as unknown as T)
              }
            }}
          >
            Save
          </CLXButton>
        </Flex.Item>
      </Flex>
      {error && <Text color="danger">{error}</Text>}
    </View>
  )
}

import { QUERY_KEYS } from "@/api/queryKeys.ts"
import { APIROUTE, generateRoute } from "@/shared/router"
import { useQuery } from "@tanstack/react-query"
import { z } from "zod"
import { canvasRestClient } from "../api.ts"
import useValidateResponse from "../useValidateResponse.ts"

const ZPlannableType = z.enum([
  "discussion_topic",
  "announcement",
  "quiz",
  "assignment",
  "wiki_page",
  "planner_note",
  "calendar_event",
  "assessment_request",
  "sub_assignment",
])

const ZNotificationResponse = z.object({
  new_activity: z.boolean(),
  plannable: z.object({
    id: z.number(),
    title: z.string(),
    description: z.string().nullable().optional(),
    html_url: z.string().nullable().optional(),
    context_name: z.string().nullable().optional(),
    context_image: z.string().nullable().optional(),
    context_type: z.string().nullable().optional(),
    workflow_state: z.string().optional(),
  }),
  plannable_date: z.string(),
  plannable_id: z.number(),
  plannable_type: ZPlannableType,
  planner_override: z
    .object({
      marked_complete: z.boolean(),
    })
    .nullable(),
  submissions: z.union([
    z.boolean().refine((val) => val === false),
    z.object({
      excused: z.boolean(),
      graded: z.boolean(),
      has_feedback: z.boolean(),
      late: z.boolean(),
      missing: z.boolean(),
      needs_grading: z.boolean(),
      posted_at: z.string().nullable(),
      redo_request: z.boolean(),
      submitted: z.boolean(),
    }),
  ]),
  context_image: z.string().optional(),
  context_name: z.string().optional(),
  context_type: z.string().optional(),
  course_id: z.number().optional(),
  html_url: z.string().optional(),
})

type NotificationResponse = z.infer<typeof ZNotificationResponse>

const ZNotificationsResponse = z.array(ZNotificationResponse)

export type NotificationsResponse = z.infer<typeof ZNotificationsResponse>

const ZNormalizedNotificationResponse = z.object({
  readNotifications: ZNotificationsResponse,
  unReadNotifications: ZNotificationsResponse,
})

export const useGetNotification = (id?: string) => {
  const userId = id || ""
  const queryKey = QUERY_KEYS.NOTIFICATION.byUser(userId)
  const queryResult = useQuery({
    queryKey,
    queryFn: async (): Promise<NotificationsResponse> =>
      canvasRestClient(
        generateRoute(
          APIROUTE.NOTIFICATION,
          {
            userId,
          },
          [{ per_page: 100 }],
        ),
      ),
    enabled: !!userId,
    select: (data) => {
      return data.reduce(
        (acc, notification) => {
          if (!isComplete(notification)) {
            acc.unReadNotifications.push(notification)
          } else {
            acc.readNotifications.push(notification)
          }
          return acc
        },
        {
          unReadNotifications: [] as NotificationsResponse,
          readNotifications: [] as NotificationsResponse,
        },
      )
    },
  })

  useValidateResponse(
    queryKey.toString(),
    queryResult,
    ZNormalizedNotificationResponse,
  )
  return queryResult
}

function isComplete(notification: NotificationResponse) {
  const { planner_override, submissions } = notification
  let complete = false
  if (planner_override) {
    complete = planner_override.marked_complete
  } else if (submissions) {
    complete =
      typeof submissions !== "boolean" &&
      submissions.submitted &&
      !submissions.redo_request
  }
  return complete
}

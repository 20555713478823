import { FONT_WEIGHT_TYPE_TO_VALUE } from "@/themes/horizon/src/typography.ts"
import { Text, Tooltip } from "@instructure/ui"
import type React from "react"
import {
  type ComponentProps,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react"

type TooltipProps = React.ComponentProps<typeof Tooltip>
export type TruncatedTextProps = Omit<
  ComponentProps<typeof Text>,
  "themeOverride" | "weight"
> & {
  text: string
  maxLines?: number
  textAlign?: "start" | "end" | "center" | "justify"
  shouldShowTooltip?: boolean
  tooltipColor?: TooltipProps["color"]
  tooltipPlacement?: TooltipProps["placement"]
  weight?: "normal" | "bold" | "semiBold"
}

const TruncateText = ({
  text,
  maxLines,
  textAlign,
  onTruncate,
  weight,
}: {
  text: string
  maxLines?: number
  textAlign?: "start" | "end" | "center" | "justify"
  weight: "normal" | "bold" | "semiBold"
  onTruncate: (hasOverflow: boolean) => void
}) => {
  const textRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    const checkOverflow = () => {
      if (text && textRef.current && onTruncate) {
        const hasOverflow =
          maxLines && maxLines > 1
            ? textRef.current.scrollHeight > textRef.current.clientHeight
            : textRef.current.scrollWidth > textRef.current.clientWidth
        onTruncate(hasOverflow)
      }
    }

    checkOverflow()
    window.addEventListener("resize", checkOverflow)

    return () => {
      window.removeEventListener("resize", checkOverflow)
    }
  }, [onTruncate, text, maxLines])

  return (
    <div
      ref={textRef}
      style={{
        overflow: "hidden",
        textOverflow: maxLines ? "clip" : "ellipsis",
        whiteSpace: maxLines ? "normal" : "nowrap",
        display: maxLines ? "-webkit-box" : "block",
        WebkitBoxOrient: maxLines ? "vertical" : undefined,
        WebkitLineClamp: maxLines || undefined,
        textAlign,
        fontWeight: FONT_WEIGHT_TYPE_TO_VALUE[weight],
      }}
    >
      {text}
    </div>
  )
}

export const CLXTruncateText = ({
  text,
  maxLines = 1,
  shouldShowTooltip = true,
  tooltipColor = "primary-inverse",
  tooltipPlacement = "bottom center",
  textAlign = "start",
  weight = "normal",
  ...props
}: TruncatedTextProps) => {
  const [isTruncated, setIsTruncated] = useState<boolean>(false)

  const updateIsTruncatedIfChanged = useCallback(
    (truncated: boolean) => {
      if (isTruncated !== truncated) {
        setIsTruncated(truncated)
      }
    },
    [isTruncated],
  )

  const renderText = () => {
    return (
      <Text {...props}>
        <TruncateText
          textAlign={textAlign}
          text={text}
          onTruncate={updateIsTruncatedIfChanged}
          maxLines={maxLines}
          weight={weight}
        />
      </Text>
    )
  }
  return (
    <>
      {shouldShowTooltip && isTruncated ? (
        <Tooltip
          renderTip={text}
          color={tooltipColor}
          placement={tooltipPlacement}
          constrain="parent"
        >
          {renderText()}
        </Tooltip>
      ) : (
        renderText()
      )}
    </>
  )
}

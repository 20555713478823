import { Link } from "@instructure/ui"
import type { ComponentProps } from "react"

export const OptionalLink = (linkProps: ComponentProps<typeof Link>) => {
  if (!linkProps.href) {
    return linkProps.children
  }

  return <Link {...linkProps}>{linkProps.children}</Link>
}

import type { TextInputTheme } from "@instructure/shared-types"
import { borders } from "../borders"
import { forms } from "../forms"
import { spacing } from "../spacing"
import { typography } from "../typography"
import { ui } from "../ui"

const TextInputThemeOverrides: Partial<TextInputTheme> = {
  fontWeight: typography.fontWeightNormal,
  color: ui.textBody,
  background: ui.surfaceCardPrimary,

  borderRadius: borders.radiusSmallMedium,
  borderWidth: borders.widthSmallMedium,
  borderStyle: borders.style,
  borderColor: ui.containerStroke,

  padding: spacing.small,

  mediumHeight: forms.inputHeightMedium,
  smallHeight: forms.inputHeightSmall,
  largeHeight: forms.inputHeightMedium,

  focusOutlineColor: ui.surfaceAttention,

  errorBorderColor: ui.surfaceError,
  errorOutlineColor: ui.textError,
  placeholderColor: ui.textPlaceholder,

  smallFontSize: typography.fontSizeMedium,
  mediumFontSize: typography.fontSizeMedium,
  largeFontSize: typography.fontSizeMedium,
}

export default {
  TextInput: TextInputThemeOverrides,
}

import {
  generatePillComponentTheme,
  generatePillTextTheme,
} from "@/shared/components/Pill/theme.ts"
import type { PillTheme, TextTheme } from "@instructure/shared-types"
import { Pill, Text, Tooltip } from "@instructure/ui"
import type React from "react"

type PillProps = React.ComponentProps<typeof Pill>
export type CLXPillThemeProps = {
  fill?: "solid" | "outline"
  pillSize?: "normal" | "small" | "x-small"
  color?: "info" | "danger" | "primary"
  isBorderVisible?: boolean
}

export type CLXPillProps = Omit<PillProps, "children" | "themeOverride"> &
  CLXPillThemeProps & {
    children: string
    isUpperCase?: boolean
    renderTip?: string
  }

export const CLXPill = ({
  children,
  pillSize = "normal",
  fill = "outline",
  color = "primary",
  isUpperCase = false,
  isBorderVisible = true,
  renderTip,
  statusLabel,
  ...props
}: CLXPillProps) => {
  const renderText = () => {
    return (
      <Text
        size="x-small"
        themeOverride={
          generatePillTextTheme(isUpperCase) as () => Partial<TextTheme>
        }
      >
        {isUpperCase ? children.toUpperCase() : children}
      </Text>
    )
  }
  return (
    <Pill
      statusLabel={isUpperCase ? statusLabel?.toUpperCase() : statusLabel}
      {...props}
      color={color}
      themeOverride={
        generatePillComponentTheme({
          isBorderVisible,
          color,
          fill,
          pillSize,
        }) as () => Partial<PillTheme>
      }
    >
      {renderTip ? (
        <Tooltip renderTip={renderTip} color="primary-inverse">
          {renderText()}
        </Tooltip>
      ) : (
        renderText()
      )}
    </Pill>
  )
}

import { typography } from "../typography.ts"
import { ui } from "../ui.ts"

export default {
  Text: {
    fontFamily: typography.fontFamily,

    fontSizeXSmall: typography.fontSizeXSmall,
    fontSizeSmall: typography.fontSizeSmall,
    fontSizeMedium: typography.fontSizeMedium,

    fontWeightNormal: typography.fontWeightNormal,
    fontWeightBold: typography.fontWeightBold,

    primaryInverseColor: ui.textSurfaceColored,
    primaryColor: ui.textBody,
    secondaryColor: ui.surfaceInstitution,

    paragraphMargin: "0",
  },
}

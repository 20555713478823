import { SendMessageModal } from "@/features/inbox/SendMessageModal.tsx"
import { IconButton, IconComposeLine } from "@instructure/ui"
import { useState } from "react"

export const CreateConversationButton = () => {
  const [isCreateConversationModalOpen, setIsCreateConversationModalOpen] =
    useState<boolean>(false)
  return (
    <>
      <IconButton
        screenReaderLabel="Compose"
        renderIcon={IconComposeLine}
        withBackground
        shape="circle"
        withBorder={false}
        margin="x-small"
        onClick={() => setIsCreateConversationModalOpen(true)}
      />
      <SendMessageModal
        closeModal={() => setIsCreateConversationModalOpen(false)}
        isOpen={isCreateConversationModalOpen}
      />
    </>
  )
}

import { generateComponentTheme } from "@/shared/components/Button/theme.ts"
import type { BaseButtonProps } from "@/shared/themes/generateButtonTheme.ts"
import type { BaseButtonTheme } from "@instructure/shared-types"
import { Button, Flex } from "@instructure/ui"
import type React from "react"

export interface ButtonProps extends BaseButtonProps {
  children: React.ReactNode | string
  renderIconAfter?: React.ReactNode
  withBoxShadow?: boolean
}

export const CLXButton = ({
  level,
  variant,
  size = "medium",
  withBoxShadow = false,
  ...props
}: ButtonProps) => (
  <Button
    {...props}
    size={size}
    color={level}
    themeOverride={
      generateComponentTheme(
        level,
        variant,
        withBoxShadow,
      ) as () => Partial<BaseButtonTheme>
    }
  >
    <Flex gap="xx-small" margin="none none none xx-small">
      {props.children}
      {props.renderIconAfter}
    </Flex>
  </Button>
)

import {
  MODULE_STATE,
  type ModuleRequirementType,
  type ModuleType,
} from "@/api/types.ts"
import { formatDateForNotes } from "@/shared/utils/index.ts"

export const getLockExplanation = (
  module: ModuleType,
  moduleNameById: Map<number, string>,
) => {
  if (module.state === MODULE_STATE.LOCKED) {
    if (module.unlock_at && new Date(module.unlock_at) > new Date()) {
      return `Locked until ${formatDateForNotes(module.unlock_at)}`
    }
    if (module.prerequisite_module_ids.length) {
      return `Locked until ${module.prerequisite_module_ids.map((moduleId) => moduleNameById.get(moduleId)).join(", ")} ${module.prerequisite_module_ids.length > 1 ? "are" : "is"} completed.`
    }
    return "All items in this module is individually locked."
  }
}

export const getRequirementText = (
  moduleRequirementType: ModuleRequirementType,
  isAllOfThemRequired: boolean,
) => {
  if (!moduleRequirementType) {
    return null
  }
  if (moduleRequirementType === "all" && isAllOfThemRequired) {
    return "Complete all of the items."
  }
  if (moduleRequirementType === "all" && !isAllOfThemRequired) {
    return "Complete all of the required items."
  }
  if (moduleRequirementType === "one") {
    return "Choose and complete one of the required items."
  }
  return null
}

import { QUERY_KEYS } from "@/api/queryKeys.ts"
import { ZModuleItemContent } from "@/api/types.ts"
import { useQuery } from "@tanstack/react-query"
import { gql } from "graphql-request"
import { z } from "zod"
import { canvasGqlClient } from "../api.ts"
import useValidateResponse from "../useValidateResponse.ts"

export const ZCourseProgression = z
  .object({
    requirements: z.object({
      completed: z.number(),
      completionPercentage: z.number(),
      total: z.number(),
    }),
    incompleteModulesConnection: z.object({
      nodes: z.array(
        z.object({
          module: z.object({
            name: z.string(),
            moduleItems: z
              .array(
                z.object({
                  next: z
                    .object({
                      content: ZModuleItemContent,
                    })
                    .nullable(),
                }),
              )
              .optional(),
          }),
          incompleteItemsConnection: z.object({
            nodes: z.array(
              z.object({
                _id: z.string(),
                nextItemsConnection: z.object({
                  nodes: z.array(
                    z.object({
                      _id: z.string(),
                      content: ZModuleItemContent,
                    }),
                  ),
                }),
              }),
            ),
          }),
        }),
      ),
    }),
  })
  .nullable()

export type CourseProgression = z.infer<typeof ZCourseProgression>

const ZUserCourse = z.object({
  course: z.object({
    _id: z.string(),
    name: z.string(),
    imageUrl: z.string().nullable(),
    account: z.object({
      name: z.string(),
    }),
    modulesConnection: z.object({
      nodes: z.array(
        z.object({
          _id: z.string(),
          name: z.string(),
          id: z.string(),
          moduleItems: z.array(
            z.object({
              content: ZModuleItemContent,
              _id: z.string(),
            }),
          ),
        }),
      ),
    }),
    usersConnection: z.object({
      nodes: z.array(
        z.object({
          courseProgression: ZCourseProgression,
        }),
      ),
    }),
  }),
})

export type UserCourse = z.infer<typeof ZUserCourse>

export const ZUserCourses = z.array(ZUserCourse)

const ZUserCoursesResponse = z
  .object({
    legacyNode: z.object({
      enrollments: ZUserCourses,
    }),
  })
  .strict()

export type UserCoursesResponse = z.infer<typeof ZUserCoursesResponse>

const ZCourseNameResponse = z
  .object({
    course: z.object({
      name: z.string(),
    }),
  })
  .strict()

type CourseNameResponse = z.infer<typeof ZCourseNameResponse>

const ZCourseOverviewResponse = z
  .object({
    legacyNode: z.object({
      _id: z.string(),
      syllabusBody: z.string().nullable(),
    }),
  })
  .strict()

type CourseOverviewResponse = z.infer<typeof ZCourseOverviewResponse>

const contentGql = `
		_id,
		content {
			... on Page {
				__typename
				_id
				id
				name: title
			}
			... on ModuleExternalTool {
				__typename
				_id
				createdAt
				updatedAt
				name: url
			}
			... on File {
				__typename
				_id
				id
				name: displayName
			}
			... on ExternalUrl {
				__typename
				_id
				createdAt
				name: title
			}
			... on ExternalTool {
				__typename
				_id
				createdAt
				name: description
			}
			... on Assignment {
				__typename
				_id
				id
				name
				dueAt
			}
		}`

const query = gql`
	query GetUserCourses($id: ID!) {
		legacyNode(_id: $id, type: User) {
			... on User {
				enrollments(currentOnly: true) {
					course {
						_id
						name
						imageUrl
						account {
              name
            }
						modulesConnection(first: 1) {
							nodes {
								_id
								name
								moduleItems {
									${contentGql}
								}
							}
						}
						usersConnection(filter: {userIds: [$id]}) {
							nodes {
								courseProgression {
									requirements {
										completed
										completionPercentage
										total
									}
									incompleteModulesConnection {
										nodes {
											module {
												name
											}
											incompleteItemsConnection(first: 1) {
												nodes {
													_id
													nextItemsConnection(first: 1) {
														nodes {
															_id
															${contentGql}
														}
													}
												}
											}
										}
									}
								}
							}
						}
					}
				}
			}
		}
	}
`
export const useGetCourses = (userId?: string) => {
  const queryKey = QUERY_KEYS.COURSE.byUser(userId || "")
  const queryResult = useQuery({
    queryKey,
    queryFn: async (): Promise<UserCoursesResponse> =>
      (await canvasGqlClient()).request(query, { id: userId }),
    enabled: !!userId,
    select: (data) =>
      data.legacyNode.enrollments.filter(
        (enrollment, index, self) =>
          index ===
          self.findIndex((other) => other.course._id === enrollment.course._id),
      ),
  })

  useValidateResponse(queryKey.toString(), queryResult, ZUserCourses)
  return queryResult
}

export const useGetCourseName = (courseId: string) => {
  const queryKey = QUERY_KEYS.COURSE.name(courseId)
  const queryResult = useQuery({
    queryKey,
    queryFn: async (): Promise<CourseNameResponse> => {
      const query = gql`query GetCourseName($courseId: ID!) {
				course(id: $courseId) {
					name
				}
			}`
      return (await canvasGqlClient()).request(query, { courseId })
    },
    select: (data) => ({
      courseName: data.course.name,
    }),
  })

  useValidateResponse(
    queryKey.toString(),
    queryResult,
    z.object({ courseName: z.string() }),
  )
  return queryResult
}

export const useGetCourseProgress = (courseId: string, userId: string) => {
  const queryKey = QUERY_KEYS.COURSE.progress(courseId, userId)
  const queryResult = useQuery({
    queryKey,
    queryFn: async (): Promise<UserCoursesResponse> => {
      const query = gql`query GetCourseProgress($courseId: ID!, $userId: ID!) {
				legacyNode(_id: $userId, type: User) {
					... on User {
						enrollments(courseId: $courseId) {
							course {
								usersConnection(filter: {userIds: [$userId]}) {
									nodes {
										courseProgression {
											requirements {
												completed
												completionPercentage
												total
											}
										}
									}
								}
							}
						}
					}
				}
			}`
      return (await canvasGqlClient()).request(query, { courseId, userId })
    },
    select: (data) => ({
      courseProgression:
        data.legacyNode.enrollments[0].course.usersConnection.nodes[0]
          .courseProgression,
    }),
  })

  useValidateResponse(
    queryKey.toString(),
    queryResult,
    z.object({ courseProgression: ZCourseProgression }),
  )
  return queryResult
}

export const useGetCourseOverview = (courseId: string) => {
  const queryKey = QUERY_KEYS.COURSE.overview(courseId)
  const queryResult = useQuery({
    queryKey,
    queryFn: async (): Promise<CourseOverviewResponse> => {
      const query = gql`
				query GetCourseOverview($courseId: ID!) {
					legacyNode(_id: $courseId, type: Course) {
						... on Course {
							_id
							syllabusBody
						}
					}
				}`
      return (await canvasGqlClient()).request(query, { courseId })
    },
    select: (data) => ({
      courseOverview: data.legacyNode.syllabusBody,
    }),
    staleTime: 50000,
  })

  useValidateResponse(
    queryKey.toString(),
    queryResult,
    z.object({ courseOverview: z.string().nullable() }),
  )
  return queryResult
}

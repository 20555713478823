import { shadows } from "@/themes/horizon/src/shadows.ts"
import type { CLXTheme, ThemeObjectValue } from "@/themes/types.ts"

export type ButtonSize = "small" | "medium"

export type ButtonLevel = "primary" | "secondary"

export type ButtonVariant = "default" | "inverse" | "ai" | "beige"

export interface BaseButtonProps {
  level: ButtonLevel
  variant?: ButtonVariant
  size?: ButtonSize
  interaction?: "enabled" | "disabled" | "readonly"
  // biome-ignore lint/suspicious/noExplicitAny: allows to pass any prop to the component
  [key: string]: any
}

export const generateButtonTheme = ({
  currentTheme,
  level,
  withBoxShadow,
  variant,
}: {
  currentTheme: CLXTheme
  level: ButtonLevel
  withBoxShadow?: boolean
  variant?: ButtonVariant
}) => {
  const { ui, gradients } = currentTheme
  const baseComponentTheme = {
    ...(currentTheme.componentOverrides.Button as ThemeObjectValue),
  }

  gradients.blue = "linear-gradient(90deg, #1E90FF 0%, #0076FF 100%)"

  if (withBoxShadow) {
    baseComponentTheme.primaryActiveBoxShadow = shadows.above
    baseComponentTheme.secondaryActiveBoxShadow = shadows.above
    baseComponentTheme.secondaryGhostActiveBoxShadow = shadows.above
    baseComponentTheme.primaryGhostActiveBoxShadow = shadows.above
    baseComponentTheme.primaryInverseActiveBoxShadow = shadows.above
    baseComponentTheme.dangerActiveBoxShadow = shadows.above
    baseComponentTheme.dangerGhostActiveBoxShadow = shadows.above
    baseComponentTheme.successActiveBoxShadow = shadows.above
    baseComponentTheme.successGhostActiveBoxShadow = shadows.above
    baseComponentTheme.primaryInverseGhostActiveBoxShadow = shadows.above
  }

  if (level === "primary") {
    switch (variant) {
      case "inverse":
        return {
          ...baseComponentTheme,
          primaryColor: ui.textTitle,
          primaryBorderColor: "transparent",
          primaryBackground: ui.surfaceCardPrimary,
          primaryHoverBackground: ui.surfaceCardPrimary,
          primaryActiveBackground: ui.surfaceCardPrimary,
        }
      case "ai":
        return {
          ...baseComponentTheme,
          primaryColor: ui.textSurfaceColored,
          primaryBorderColor: "transparent",
          primaryBackground: gradients.blue,
          primaryHoverBackground: gradients.blue,
          primaryActiveBackground: gradients.blue,
        }
    }
  }

  if (level === "secondary") {
    switch (variant) {
      case "default":
        return {
          ...baseComponentTheme,
          secondaryBackground: ui.surfaceInstitution,
        }
      case "beige":
        return {
          ...baseComponentTheme,
          secondaryColor: ui.textTitle,
          secondaryBorderColor: "transparent",
          secondaryBackground: ui.surfacePagePrimary,
          secondaryHoverBackground: ui.surfacePageSecondary,
          secondaryActiveBackground: ui.surfacePageSecondary,
        }
    }
  }

  return baseComponentTheme
}

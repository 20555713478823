import { PAGEROUTE } from "@/shared/router"

export const DESKTOP_NAVIGATION_WIDTH = 89
export const SECONDARY_NAVIGATION_WIDTH = 127
export const DESKTOP_NAVIGATION_HEADER_MAX_WIDTH = 848

export interface NavigationItemProps {
  icon?: string
  iconActive?: string
  title?: string
  route?: PAGEROUTE
  routeParams?: Record<string, string>
  customElement?: boolean
  subNavigationItems?: NavigationItemProps[]
}

export const HOME: NavigationItemProps = {
  title: "Home",
  route: PAGEROUTE.HOME,
  icon: "home",
  iconActive: "home_filled",
}

export const LEARN: NavigationItemProps = {
  title: "Learn",
  route: PAGEROUTE.LEARN,
  icon: "book_2",
  iconActive: "book_2_filled",
}

const SKILLSPACE: NavigationItemProps = {
  title: "Skillspace",
  route: PAGEROUTE.SKILLSPACE,
  icon: "hub",
  iconActive: "hub_filled",
}

const INBOX: NavigationItemProps = {
  title: "Inbox",
  route: PAGEROUTE.INBOX,
  icon: "mail",
  iconActive: "mail_filled",
}

export const AI: NavigationItemProps = {
  title: "AI Assist",
  icon: "AI",
  iconActive: "AI_filled",
  customElement: true,
}

const ACCOUNT: NavigationItemProps = {
  title: "Account",
  route: PAGEROUTE.ACCOUNT,
  icon: "account_circle",
  iconActive: "account_circle_filled",
}

const NOTEBOOK: NavigationItemProps = {
  title: "Notebook",
  route: PAGEROUTE.NOTEBOOK,
  icon: "menu_book_notebook",
  iconActive: "menu_book_notebook_filled",
}
export const ALERTS: NavigationItemProps = {
  title: "Alerts",
  route: PAGEROUTE.ALERTS,
  icon: "notifications_unread",
  iconActive: "notifications_unread_filled",
}

export const LEARN_MYPROGRESS: NavigationItemProps = {
  title: "My Progress",
  route: PAGEROUTE.COURSE_PROGRESS,
}

export const LEARN_OVERVIEW: NavigationItemProps = {
  title: "Overview",
  route: PAGEROUTE.COURSE_OVERVIEW,
}

export const LEARN_GRADES: NavigationItemProps = {
  title: "Grades",
  route: PAGEROUTE.COURSE_GRADES,
}

export const LEARN_QUICKLINKS: NavigationItemProps = {
  title: "Quick Links",
  route: PAGEROUTE.COURSE_QUICKLINKS,
}

export const LEARN_PAGE_NAVIGATION_ITEMS: NavigationItemProps[] = [
  LEARN_MYPROGRESS,
  LEARN_OVERVIEW,
  LEARN_GRADES,
  LEARN_QUICKLINKS,
]

export const DESKTOP_NAVIGATION_ITEMS_TOP: NavigationItemProps[] = [
  HOME,
  LEARN,
  SKILLSPACE,
  ACCOUNT,
]

export const DESKTOP_NAVIGATION_ITEMS_BOTTOM: NavigationItemProps[] = [AI]

export const DESKTOP_HEADER_NAVIGATION_ITEMS: NavigationItemProps[] = [
  NOTEBOOK,
  ALERTS,
  INBOX,
]

export const MOBILE_NAVIGATION_ITEMS: NavigationItemProps[] = [
  HOME,
  LEARN,
  AI,
  SKILLSPACE,
  NOTEBOOK,
  ACCOUNT,
  ALERTS,
  INBOX,
]

import { useGetModuleItems } from "@/api/queries/useGetModules"
import type { MODULE_STATE, ModuleType } from "@/api/types"
import { ModuleContainer } from "@/shared/components/ModuleContainer/ModuleContainer.tsx"
import { ModuleItem } from "@/shared/components/ModuleItem/ModuleItem.tsx"
import {
  type MODULE_ITEM_TYPE,
  SUPPORTED_MODULE_ITEM_TYPES,
} from "@/shared/components/ModuleItem/types.ts"
import { getLearningObjectLink } from "@/shared/components/utils.tsx"
import { FetchingError } from "@/shared/loading/FetchingError.tsx"
import { GlobalLoader } from "@/shared/loading/GlobalLoader.tsx"
import { getLockExplanation } from "@/shared/utils/module.ts"
import { getUserId } from "@/token-storage.ts"
import { useMemo } from "react"
import { useParams } from "react-router-dom"

type ModuleProperties = {
  module: ModuleType
  isCollapsed: boolean
  setIsCollapsed: (isCollapsed: boolean) => void
  moduleNameById: Map<number, string>
}

export const Module = ({
  module,
  isCollapsed,
  setIsCollapsed,
  moduleNameById,
}: ModuleProperties) => {
  const { courseId = "" } = useParams()
  const itemsQuery = useGetModuleItems({
    courseId,
    moduleId: module.id.toString(),
    userId: getUserId(),
    isEnabled: !module.items?.length,
  })

  if (itemsQuery.isLoading) {
    return <GlobalLoader title="Loading..." renderInContent={true} />
  }

  if (itemsQuery.isError) {
    return <FetchingError />
  }
  const moduleItems = module.items ?? itemsQuery.data ?? []

  const itemCount = useMemo(() => {
    const items = moduleItems.filter((item) =>
      SUPPORTED_MODULE_ITEM_TYPES.includes(item.type),
    )
    return items.length
  }, [moduleItems])

  const isAllOfThemRequired = moduleItems.every(
    (i) => !!i.completion_requirement,
  )

  return (
    <ModuleContainer
      moduleName={module.name}
      moduleState={module.state as MODULE_STATE}
      moduleRequirementType={module.requirement_type}
      isAllOfThemRequired={isAllOfThemRequired}
      isCollapsed={isCollapsed}
      setIsCollapsed={setIsCollapsed}
      itemCount={itemCount}
      lockExplanation={getLockExplanation(module, moduleNameById)}
      duration={itemCount * 20} // TODO: get duration from API
      content={moduleItems.map((item) => (
        <ModuleItem
          key={item.id}
          name={item.title}
          position={item.position}
          type={item.type as MODULE_ITEM_TYPE}
          redirectUrl={getLearningObjectLink(
            item.type,
            item.quiz_lti,
            courseId,
            item,
            module.id.toString(),
          )}
          isSelected={false}
          duration={20} // TODO: get duration from API
          dueDate={item.content_details?.due_at}
          isCompleted={item.completion_requirement?.completed}
          isRequired={!!item.completion_requirement}
          isLocked={item.content_details?.locked_for_user}
          lockExplanation={item.content_details?.lock_explanation}
          points={item.content_details?.points_possible}
        />
      ))}
    />
  )
}

import { View } from "@instructure/ui"
import type { ComponentProps } from "react"
import { type CardVariant, generateComponentTheme } from "./theme"

type ViewProps = ComponentProps<typeof View>

export interface CardProps extends Omit<ViewProps, "background"> {
  variant?: CardVariant
}

const VARIANT_PROPS: Record<CardVariant, Partial<ViewProps>> = {
  card: {
    background: "primary",
    shadow: "above",
    padding: "large",
    borderRadius: "medium",
  },
  page: {
    background: "primary",
    padding: "large",
    borderRadius: "large",
  },
  ai: {
    background: "primary",
    shadow: "above",
    padding: "medium",
    borderRadius: "medium",
  },
}

export const Card = ({ variant = "card", ...props }: CardProps) => {
  return (
    <View
      as="div"
      {...VARIANT_PROPS[variant]}
      {...props}
      themeOverride={generateComponentTheme(props.themeOverride, variant)}
    />
  )
}

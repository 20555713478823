import { DESKTOP_LEARNING_OBJECT_CONTROLS_HEIGHT } from "@/features/learning_content/learning_objects_skeleton/constants.ts"
import { CLXIconButton } from "@/shared/components/IconButton/IconButton.tsx"
import { SearchParams } from "@/shared/router"
import { Flex } from "@instructure/ui"
import { useSearchParams } from "react-router-dom"

type DesktopLearningObjectControlsProps = {
  onMyProgressClicked: () => void
  onListenClicked: () => void
  hasMoreRelevantSequence: boolean
}

export const DesktopLearningObjectControls = ({
  onMyProgressClicked,
  onListenClicked,
  hasMoreRelevantSequence,
}: DesktopLearningObjectControlsProps) => {
  const [searchParams, setSearchParams] = useSearchParams()

  const addNoteParam = () => {
    const newSearchParams = new URLSearchParams(searchParams)
    newSearchParams.set(SearchParams.ADD_NOTE, "true")
    setSearchParams(newSearchParams)
  }

  return (
    <Flex
      gap="small"
      direction="column"
      justifyItems="center"
      height={DESKTOP_LEARNING_OBJECT_CONTROLS_HEIGHT}
    >
      <CLXIconButton
        disabled={hasMoreRelevantSequence}
        screenReaderLabel="My Progress"
        level="secondary"
        clxIconName="list_alt_check"
        size="small"
        onClick={onMyProgressClicked}
      />
      <CLXIconButton
        screenReaderLabel="Listen to this"
        level="secondary"
        clxIconName="volume_up"
        size="small"
        onClick={onListenClicked}
      />
      <CLXIconButton
        disabled={hasMoreRelevantSequence}
        screenReaderLabel="Take note"
        level="secondary"
        clxIconName="menu_book_notebook"
        size="small"
        onClick={addNoteParam}
      />
    </Flex>
  )
}

import type { NavigationItemProps } from "@/shared/components/Navigation/constants.ts"
import {
  type Dispatch,
  type ReactNode,
  type RefObject,
  createContext,
  useContext,
  useReducer,
} from "react"

type NavigationContext = {
  secondaryNavigationItems: NavigationItemProps[]
  secondaryNavigationHeader: ReactNode | null
  isDesktopNavigationHidden: boolean
  isMobileNavigationHidden: boolean
  desktopScrollRef: RefObject<HTMLDivElement> | null
}

const initialContext: NavigationContext = {
  secondaryNavigationItems: [],
  secondaryNavigationHeader: null,
  isDesktopNavigationHidden: false,
  isMobileNavigationHidden: false,
  desktopScrollRef: null,
}

export enum actions {
  SET_SECONDARY_NAVIGATION = "SET_SECONDARY_NAVIGATION",
  IS_LEARNING_OBJECT_LOADED = "IS_LEARNING_OBJECT_LOADED",
  SET_DESKTOP_SCROLL_REF = "SET_DESKTOP_SCROLL_REF",
}

type SetSecondaryNavigation = {
  type: actions.SET_SECONDARY_NAVIGATION
  payload: {
    secondaryNavigationItems: NavigationItemProps[]
    secondaryNavigationHeader?: ReactNode
  }
}

type SetIsLearningObjectLoaded = {
  type: actions.IS_LEARNING_OBJECT_LOADED
  payload: boolean
}

type SetDesktopScrollRef = {
  type: actions.SET_DESKTOP_SCROLL_REF
  payload: RefObject<HTMLDivElement> | null
}

// Union of all actions
type NavigationContextAction =
  | SetSecondaryNavigation
  | SetIsLearningObjectLoaded
  | SetDesktopScrollRef

const reducer = (
  state: NavigationContext,
  action: NavigationContextAction,
): NavigationContext => {
  switch (action.type) {
    case actions.SET_SECONDARY_NAVIGATION:
      return {
        ...state,
        secondaryNavigationHeader: action.payload.secondaryNavigationHeader,
        secondaryNavigationItems: action.payload.secondaryNavigationItems,
      }
    case actions.IS_LEARNING_OBJECT_LOADED:
      return {
        ...state,
        isDesktopNavigationHidden: action.payload,
        isMobileNavigationHidden: action.payload,
      }

    case actions.SET_DESKTOP_SCROLL_REF:
      return {
        ...state,
        desktopScrollRef: action.payload,
      }
    default:
      return state
  }
}

const NavigationContext = createContext<NavigationContext>(initialContext)
const NavigationContextDispatch = createContext<
  Dispatch<NavigationContextAction>
>(() => {
  throw new Error(
    "NavigationContextDispatch must be used within an NavigationContextProvider",
  )
})

export const NavigationContextProvider = ({
  children,
}: { children: ReactNode }) => {
  const [navigationContext, dispatch] = useReducer(reducer, initialContext)
  return (
    <NavigationContext.Provider value={navigationContext}>
      <NavigationContextDispatch.Provider value={dispatch}>
        {children}
      </NavigationContextDispatch.Provider>
    </NavigationContext.Provider>
  )
}

export const useNavigationContext = () => {
  return useContext(NavigationContext)
}

export const useNavigationContextDispatch = () => {
  return useContext(NavigationContextDispatch)
}

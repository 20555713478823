import { getNoteById } from "@/api/db/db"
import { QUERY_KEYS } from "@/api/queryKeys"
import { useQuery } from "@tanstack/react-query"

export const useGetNotebyId = (id?: number) => {
  const queryKey = QUERY_KEYS.NOTE.all
  return useQuery({
    enabled: !!id,
    queryKey,
    queryFn: async () => {
      return getNoteById(id as number)
    },
  })
}

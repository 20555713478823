import { getUserId } from "@/token-storage"
import { useQuery } from "@tanstack/react-query"
import { canvasRestClient } from "../api"
import { QUERY_KEYS } from "../queryKeys"

export const useJWT = () => {
  const queryKey = QUERY_KEYS.RCS.all
  const queryResult = useQuery({
    queryKey,
    queryFn: () =>
      canvasRestClient("api/v1/jwts", {
        method: "POST",
        body: JSON.stringify({
          context_type: "User",
          context_id: getUserId() as string,
          workflows: ["rich_content"],
        }),
      }),
  })

  return queryResult
}

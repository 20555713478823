import { useAI } from "@/api/mutations/useAI.ts"
import { useOnMount } from "@/shared/hooks/useOnMount.ts"
import { FetchingError } from "@/shared/loading/FetchingError.tsx"
import { GlobalLoader } from "@/shared/loading/GlobalLoader.tsx"
import { Button, Flex, Heading, Text, View } from "@instructure/ui"
import { type ChangeEvent, useState } from "react"
import { z } from "zod"
import "./AssistQuizInteraction.css"

type Props = {
  context: string
}

const ZQuizData = z
  .object({
    question: z.string(),
    options: z.array(z.string()),
    result: z.number(),
  })
  .strict()

type QuizData = z.infer<typeof ZQuizData>

export const AssistQuizInteraction = ({ context }: Props) => {
  const { mutate, isPending, isError } = useAI(true)
  const [data, setData] = useState<QuizData | null>(null)

  const getQuiz = () => {
    mutate(
      `if there was a previous question, it should be different, here is the context: ${context}, from this context create 1 quiz, give 4 possible options, give back in json format like: {question: '', options: [option1 as string, option2 as string, ...], result: index of the correct answer}`,
      {
        onSuccess: (data) => {
          try {
            const result = JSON.parse(data.message.content)

            setData(result)
          } catch (error) {
            console.error(error)
          }
        },
        onError: (error) => {
          console.error(error)
        },
      },
    )
  }

  useOnMount(() => {
    getQuiz()
  })

  if (isPending) {
    return (
      <Flex as="div" justifyItems="center">
        <GlobalLoader title="Loading..." renderInContent={true} />
      </Flex>
    )
  }

  if (isError) {
    return (
      <View as="div">
        <FetchingError />
      </View>
    )
  }

  return data ? (
    <Flex direction="column" as="div" height="100%">
      <Flex.Item margin="0 0 medium 0" shouldGrow>
        <Heading color="primary-inverse">{data?.question}</Heading>
      </Flex.Item>
      <Flex.Item as="div" overflowX="hidden" overflowY="hidden" padding="small">
        <RadioGroup
          options={data?.options?.map((option, index) => ({
            value: index,
            label: option,
          }))}
          result={data?.result}
          name="ai-quiz"
          className="quiz-radio-input"
          callback={getQuiz}
        />
      </Flex.Item>
    </Flex>
  ) : null
}

type RadioGroupProps = {
  options: { value: number; label: string }[]
  name: string
  className: string
  callback: () => void
  result: number
}

const RadioGroup = ({
  options,
  name,
  className,
  callback,
  result,
}: RadioGroupProps) => {
  const [selectedOption, setSelectedOption] = useState(0)

  const [isAnswerValid, setIsAnswerValid] = useState<boolean>(false)
  const [isAnswerIncorrect, setIsAnswerIncorrect] = useState<
    number | undefined
  >()

  const handleCheckAnswer = () => {
    if (selectedOption === result) {
      setIsAnswerValid(true)
    } else {
      setIsAnswerIncorrect(selectedOption)
    }
  }

  const getBackground = (index: number) => {
    if (selectedOption === index && isAnswerValid) {
      return "green"
    }
    if (selectedOption === index && isAnswerIncorrect !== undefined) {
      return "red"
    }
    return "white"
  }

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    setSelectedOption(+e.target.value)
    setIsAnswerValid(false)
    setIsAnswerIncorrect(undefined)
  }

  return (
    <div className={className}>
      {options.map((option, index) => (
        <label
          key={option.value}
          style={{ background: `${getBackground(index)}` }}
        >
          <input
            type="radio"
            name={name}
            value={option.value}
            checked={selectedOption === option.value}
            onChange={handleChange}
          />
          {option.label}
        </label>
      ))}
      <Flex as="div" gap="small" margin="medium 0 0 0">
        <Button onClick={callback} display="block">
          <Text>Re-Generate Quiz</Text>
        </Button>

        <Button onClick={handleCheckAnswer} display="block">
          <Text>Check Answer</Text>
        </Button>
      </Flex>
    </div>
  )
}

import { useAppContext } from "@/AppContext.tsx"
import { InstUISettingsProvider, canvas } from "@instructure/ui"

export const ThemeProvider = ({ children }: { children: React.ReactNode }) => {
  const { theme: activeTheme } = useAppContext()

  return (
    <InstUISettingsProvider theme={{ ...canvas, ...activeTheme }}>
      {children}
    </InstUISettingsProvider>
  )
}

import { Flex, View } from "@instructure/ui"
import { CLXLink } from "../../shared/components/Link/Link"

export const EmbeddedExternalTool = ({
  title,
  url,
}: { title: string; url?: string }) => (
  <Flex direction="column" height="100%">
    <View textAlign="center" margin="0 0 medium 0">
      <CLXLink
        href={url}
        color="blue"
        target="_blank"
        clxIconName="link"
        iconPlacement="end"
      >
        Open in a New Tab
      </CLXLink>
    </View>
    <Flex.Item shouldGrow>
      {url ? (
        <iframe
          title={title}
          src={url}
          style={{
            border: "none",
            width: "100%",
            height: "100%",
          }}
          loading="lazy"
          referrerPolicy="no-referrer"
        />
      ) : (
        "Missing URL"
      )}
    </Flex.Item>
  </Flex>
)

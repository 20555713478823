import { Flex } from "@instructure/ui"
import type { ZMimeTypesType } from "../../api/types"

export const AudioContent = ({
  url,
  contentType,
}: { url: string; contentType: ZMimeTypesType }) => {
  return (
    <Flex as="div" margin="medium auto" justifyItems="center">
      {/* biome-ignore lint/a11y/useMediaCaption: */}
      <audio controls>
        <source src={url} type={contentType} />
      </audio>
    </Flex>
  )
}

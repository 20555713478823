import { MOBILE_MENU_HEIGHT } from "@/constants.ts"
import { DesktopNavigationWrapper } from "@/shared/components/Navigation/DesktopNavigationWrapper.tsx"
import { MobileNavigationWrapper } from "@/shared/components/Navigation/MobileNavigationWrapper.tsx"
import { NavigationContextProvider } from "@/shared/components/Navigation/NavigationContext.tsx"
import { emToPxNumber } from "@/shared/hooks/useResponsive.ts"
import { PAGEROUTE } from "@/shared/router"
import { breakpoints } from "@/themes/horizon/src/breakpoints.ts"
import { ui } from "@/themes/horizon/src/ui.ts"
import { useLayoutEffect, useRef } from "react"
import { type Location, useLocation } from "react-router-dom"
import {
  actions,
  useAppContext,
  useAppContextDispatch,
} from "../../../AppContext.tsx"

export const MainNavigation = () => {
  const dispatch = useAppContextDispatch()
  const location = useLocation()

  const { desktopMode } = useAppContext()

  const ref = useRef<HTMLDivElement>(null)

  const updateView = () => {
    const clientHeight = ref?.current?.clientHeight ?? 0
    const clientWidth = ref?.current?.clientWidth ?? 0
    dispatch({
      type: actions.SET_DESKTOP_MODE,
      payload: (clientWidth ?? 0) >= emToPxNumber(breakpoints.mobile),
    })
    dispatch({
      type: actions.SET_APPLICATION_DIMENSIONS,
      payload: {
        viewportHeight: clientHeight,
        mobileContentHeight: clientHeight - MOBILE_MENU_HEIGHT,
        viewportWidth: clientWidth,
      },
    })
  }
  // biome-ignore lint/correctness/useExhaustiveDependencies: run once on mount
  useLayoutEffect(() => {
    updateView()
    window.addEventListener("resize", updateView)
    return () => {
      window.removeEventListener("resize", updateView)
    }
  }, [])

  const backgroundColor = getBackgroundColor(desktopMode, location)

  return (
    <div ref={ref} style={{ height: "100%", backgroundColor }}>
      <NavigationContextProvider>
        {desktopMode ? (
          <DesktopNavigationWrapper />
        ) : (
          <MobileNavigationWrapper />
        )}
      </NavigationContextProvider>
    </div>
  )
}

function getBackgroundColor(isDesktopMode: boolean, location: Location) {
  if (!isDesktopMode) {
    return ui.surfacePagePrimary
  }

  const isHomePage = location.pathname === PAGEROUTE.HOME

  if (!isHomePage) {
    return ui.surfacePagePrimary
  }
  return ui.surfacePageSecondary
}

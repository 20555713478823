import { useGetDoc } from "@/api/queries/useGetDoc"
import { FetchingError } from "@/shared/loading/FetchingError"
import { GlobalLoader } from "@/shared/loading/GlobalLoader"
import { Link, View } from "@instructure/ui"
import "./docstyles.css"
import { createMarkup } from "@/shared/utils"

export const DocContent = ({ url }: { url: string }) => {
  const docQuery = useGetDoc(url)
  if (docQuery.isLoading) {
    return <GlobalLoader title="Loading..." />
  }

  if (docQuery.isError || !docQuery.data) {
    return <FetchingError />
  }

  const cleanHTML = createMarkup(docQuery.data.value)
  return (
    <View as="div" padding="medium">
      <Link href={url}>Download original here</Link>
      <div
        className="doc-styles"
        // biome-ignore lint/security/noDangerouslySetInnerHtml: <explanation>
        dangerouslySetInnerHTML={cleanHTML}
      />
    </View>
  )
}

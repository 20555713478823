import { LearningObjectLockedContent } from "@/features/learning_content/learning_objects_skeleton/LearningObjectLockedContent.tsx"
import { ui } from "@/themes/horizon/src/ui.ts"
import { Flex, Text, View } from "@instructure/ui"
import type { PropsWithChildren } from "react"
import { MarkDoneButton } from "../../MarkDoneButton.tsx"
import type { LearningObjectProps } from "../LearningObjectWrapper.tsx"
import { MobileLearningObjectControls } from "./MobileLearningObjectControls.tsx"
import { MobileLearningObjectHeader } from "./MobileLearningObjectHeader.tsx"

export const MobileLearningObject = ({
  courseId,
  estimatedTime,
  dueAt,
  children,
  moduleItem,
  learningAssistContext,
  nextItem,
  previousItem,
  module,
}: PropsWithChildren<LearningObjectProps>) => {
  const lockExplanation = moduleItem.content_details?.lock_explanation
  const isLocked = lockExplanation != null
  return (
    <Flex direction="column" height="100%">
      <div
        style={{
          flexGrow: 2,
          overflowX: "hidden",
        }}
      >
        <Flex as="div" direction="column" alignItems="center" height="100%">
          <div
            style={{
              position: "sticky",
              top: 0,
              width: "100%",
              background: ui.surfacePagePrimary,
            }}
          >
            <MobileLearningObjectHeader
              moduleItem={moduleItem}
              module={module}
              courseId={courseId}
            />
          </div>
          <Flex
            as="div"
            justifyItems="space-between"
            width="100%"
            padding="small medium medium"
          >
            <Text size="small">{`${estimatedTime}`}</Text>
            <Text size="small">{`Due ${dueAt ?? "XX/XX"}`}</Text>
          </Flex>
          <div style={{ width: "100%", flexGrow: 2 }}>
            <View
              as="div"
              background={isLocked ? "secondary" : "primary"}
              borderRadius="large large none none"
              shadow="above"
              width="100%"
              height="100%"
              padding="medium"
              themeOverride={{ backgroundSecondary: ui.surfacePageTertiary }}
            >
              {isLocked ? (
                <LearningObjectLockedContent
                  lockExplanation={lockExplanation}
                />
              ) : (
                children
              )}
              {module.id && moduleItem.id && (
                <MarkDoneButton
                  courseId={courseId}
                  moduleId={module.id.toString()}
                  itemId={moduleItem.id.toString()}
                />
              )}
            </View>
          </div>
        </Flex>
      </div>
      <div
        style={{
          position: "sticky",
          bottom: 0,
          background: ui.surfacePagePrimary,
        }}
      >
        <MobileLearningObjectControls
          courseId={courseId}
          learningAssistContext={learningAssistContext}
          nextItem={nextItem}
          prevItem={previousItem}
        />
      </div>
    </Flex>
  )
}

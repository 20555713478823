import {
  useGetCourseName,
  useGetCourseProgress,
} from "@/api/queries/useGetCourses.ts"
import { CLXProgressBar } from "@/shared/components/ProgressBar/ProgressBar"
import { FetchingError } from "@/shared/loading/FetchingError"
import { GlobalLoader } from "@/shared/loading/GlobalLoader"
import { getCourseProgressionValues } from "@/shared/utils"
import { spacing } from "@/themes/horizon/src/spacing"
import { getUserId } from "@/token-storage.ts"
import { Text, View } from "@instructure/ui"
import type { PropsWithChildren } from "react"
import { SecondaryNavigationItem } from "../SecondaryNavigationItem.tsx"
import { LEARN_PAGE_NAVIGATION_ITEMS } from "../constants.ts"
import type { LearnLayoutProps } from "./LearnLayoutContainer.tsx"

export const LearnMobileLayout = ({
  courseId,
  children,
}: PropsWithChildren<LearnLayoutProps>) => {
  const {
    data: courseName,
    isLoading: isCourseNameLoading,
    isError: isCourseNameError,
  } = useGetCourseName(courseId)
  const {
    data: courseProgressionData,
    isLoading: isCourseProgressLoading,
    isError: isCourseProgressError,
  } = useGetCourseProgress(courseId, getUserId() ?? "")

  if (isCourseNameLoading || isCourseProgressLoading) {
    return <GlobalLoader title="Loading..." renderInContent={true} />
  }

  if (isCourseNameError || isCourseProgressError) {
    return <FetchingError />
  }

  const { completed, total, completionPercentage } = getCourseProgressionValues(
    courseProgressionData?.courseProgression ?? null,
  )

  return (
    <>
      <View as="div" margin="large medium medium">
        <Text size="large">{courseName?.courseName}</Text>
        <CLXProgressBar
          valueNow={completed}
          valueMax={total}
          completionPercentage={completionPercentage}
          shouldRenderValue
        />
      </View>
      <div
        style={{
          display: "flex",
          gap: spacing.mediumSmall,
          padding: spacing.mediumSmall,
        }}
      >
        {LEARN_PAGE_NAVIGATION_ITEMS.map((item) => (
          <SecondaryNavigationItem
            key={item.title}
            {...item}
            routeParams={{ courseId: courseId }}
          />
        ))}
      </div>
      <div
        style={{
          padding: spacing.medium,
        }}
      >
        {children}
      </div>
    </>
  )
}

import i18next from "i18next"
import LanguageDetector from "i18next-browser-languagedetector"
import { initReactI18next } from "react-i18next"
import { EN } from "./en.translations"
import { FR } from "./fr.translations"

const resources = {
  en: EN,
  "en-US": EN,
  fr: FR,
}

i18next
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources,
    interpolation: {
      escapeValue: false,
    },
  })

export { i18next }

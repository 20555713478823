import type { UserCourse } from "@/api/queries/useGetCourses"
import { useGetModuleItem } from "@/api/queries/useGetModules"
import { Icon } from "@/icons/Icon"
import { Card } from "@/shared/components/Card/Card"
import { CLXIconButton } from "@/shared/components/IconButton/IconButton"
import { CLXPill } from "@/shared/components/Pill/Pill"
import { getLearningObjectLink } from "@/shared/components/utils"
import { LearningObjectIcon } from "@/shared/learning_object_metadata/LearningObjectIcon"
import { formatDateToMMDD, getModuleItemNameAndType } from "@/shared/utils"
import { ui } from "@/themes/horizon/src/ui"
import { Text, View } from "@instructure/ui"
import { useNavigate } from "react-router-dom"

type Props = {
  course: UserCourse
  isCourseStarted: boolean
}

export const HomeCard = ({ course, isCourseStarted }: Props) => {
  const navigate = useNavigate()
  const { moduleItemName, moduleItemType, dueAt } =
    getModuleItemNameAndType(course)

  if (!course.course.modulesConnection.nodes.length) {
    return <div>Course has no modules</div>
  }

  const { data } = useGetModuleItem({
    courseId: course.course._id,
    moduleId: course.course.modulesConnection.nodes[0]._id,
    itemId: course.course.modulesConnection.nodes[0].moduleItems[0]._id,
  })
  const navigateToModule = () => {
    if (!data) {
      return
    }
    const loRoute = getLearningObjectLink(
      data.type,
      false,
      course.course._id,
      data,
      course.course.modulesConnection.nodes[0]._id,
    )
    navigate(loRoute)
  }

  return (
    <Card key={course.course._id} onClick={navigateToModule} cursor="pointer">
      <View as="div">
        <CLXPill isUpperCase>
          {isCourseStarted ? "In Progress" : "Not Started"}
        </CLXPill>
        <View as="div" margin="small 0">
          <Text>{course.course.modulesConnection.nodes[0]?.name}</Text>
        </View>
        <View as="div" margin="0 0 large">
          <Text
            size="large"
            color="secondary"
            weight="bold"
            themeOverride={{
              secondaryColor: ui.surfaceInstitution,
            }}
          >
            {moduleItemName}
          </Text>
        </View>
        <div className="flex-container flex-justify-space-between">
          <div className="flex-container flex-direction-column flex-justify-center">
            {moduleItemType && (
              <CLXPill
                renderIcon={
                  <span style={{ color: ui.surfaceInstitution }}>
                    <LearningObjectIcon type={moduleItemType} />
                  </span>
                }
                pillSize="x-small"
                isBorderVisible={false}
              >
                {moduleItemType}
              </CLXPill>
            )}
            {dueAt && (
              <CLXPill
                renderIcon={
                  <Icon name="schedule" customColor={ui.surfaceInstitution} />
                }
                pillSize="x-small"
                isBorderVisible={false}
              >
                {`Due Date ${formatDateToMMDD(dueAt)}`}
              </CLXPill>
            )}
          </div>
          <CLXIconButton
            size="medium"
            level="secondary"
            shape="circle"
            withBoxShadow
            screenReaderLabel="Open course"
          >
            <Icon name="arrow_forward" />
          </CLXIconButton>
        </div>
      </View>
    </Card>
  )
}

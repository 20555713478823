import { APIROUTE, generateRoute } from "@/shared/router"
import { useMutation } from "@tanstack/react-query"
import { canvasRestClient } from "../api.ts"

const mutationFn = (): Promise<void> => {
  const apiRoute = generateRoute(APIROUTE.LOGIN, {})

  return canvasRestClient(apiRoute, {
    method: "DELETE",
  })
}

export const useLogout = ({ onSettled }: { onSettled: () => void }) => {
  return useMutation({
    mutationFn,
    onSettled,
  })
}

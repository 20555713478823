import { useMutation, useQueryClient } from "@tanstack/react-query"
import { gql } from "graphql-request"
import { z } from "zod"
import { canvasGqlClient } from "../api"
import { QUERY_KEYS } from "../queryKeys"
import { ZSubmissionCommentContents } from "../types"
import useValidateResponse from "../useValidateResponse"

export const ZCreateSubmissionCommentResponse = z.object({
  submissionComment: ZSubmissionCommentContents,
  errors: z.object({
    attribute: z.string(),
    message: z.string(),
  }),
})

const mutation = gql`
mutation CreateSubmissionComment($id: ID!, $submissionAttempt: Int!, $comment: String!, $fileIds: [ID!], $mediaObjectId: ID, $mediaObjectType: String, $reviewerSubmissionId: ID) {
  createSubmissionComment(
    input: {submissionId: $id, attempt: $submissionAttempt, comment: $comment, fileIds: $fileIds, mediaObjectId: $mediaObjectId, mediaObjectType: $mediaObjectType, reviewerSubmissionId: $reviewerSubmissionId}
  ) {
    submissionComment {
      ...SubmissionHtmlComment
    }
    errors {
      ...Error
    }
  }
}

fragment Error on ValidationError {
  attribute
  message
}

fragment SubmissionHtmlComment on SubmissionComment {
  _id
  attachments {
    ...SubmissionCommentFile
  }
  author {
    ...SubmissionCommentAuthor
  }
  htmlComment
  mediaObject {
    ...MediaObject
  }
  read
  updatedAt
}

fragment MediaObject on MediaObject {
  id
  _id
  mediaSources {
    ...MediaSource
  }
  mediaTracks {
    ...MediaTrack
  }
  mediaType
  title
}

fragment MediaSource on MediaSource {
  height
  src: url
  type: contentType
  width
}

fragment MediaTrack on MediaTrack {
  _id
  locale
  content
  kind
}

fragment SubmissionCommentAuthor on User {
  avatarUrl
  shortName
}

fragment SubmissionCommentFile on File {
  _id
  displayName
  id
  mimeClass
  url
}
`

export const useCreateSubmissionComment = (
  submissionId: string,
  attempt: number,
) => {
  const queryClient = useQueryClient()
  const mutationResult = useMutation({
    mutationFn: async ({
      text,
      fileIds,
    }: {
      text: string
      fileIds?: string[]
    }) => {
      return (await canvasGqlClient()).request(mutation, {
        id: submissionId,
        submissionAttempt: attempt,
        comment: text,
        fileIds,
      })
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: QUERY_KEYS.SUBMISSION.comments(submissionId, attempt),
      })
    },
  })

  useValidateResponse(
    "useCreateSubmissionComment",
    mutationResult,
    ZCreateSubmissionCommentResponse,
  )
  return mutationResult
}

import { useAppContext } from "@/AppContext.tsx"
import {
  actions,
  useNavigationContextDispatch,
} from "@/shared/components/Navigation/NavigationContext.tsx"
import { Flex } from "@instructure/ui"
import { type PropsWithChildren, useEffect, useState } from "react"
import { DesktopLearningObject } from "./desktop_layout/DesktopLearningObject.tsx"
import { MobileLearningObject } from "./mobile_layout/MobileLearningObject.tsx"
import "./ContentStyles.css"
import {
  useGetModule,
  useGetModuleItemSequence,
} from "@/api/queries/useGetModules.ts"
import type { ASSET_TYPE, ModuleItem, ModuleType } from "@/api/types.ts"
import { FetchingError } from "@/shared/loading/FetchingError.tsx"
import { GlobalLoader } from "@/shared/loading/GlobalLoader.tsx"
import { SearchParams } from "@/shared/router"
import { getUserId } from "@/token-storage.ts"
import { useSearchParams } from "react-router-dom"

export type LearningObjectWrapperProps = {
  courseId: string
  assetId: number
  assetType: ASSET_TYPE
  estimatedTime?: string
  learningAssistContext?: string
  dueAt?: string
}
export type LearningObjectProps = LearningObjectWrapperProps & {
  previousItem: ModuleItem | null
  nextItem: ModuleItem | null
  moduleItem: ModuleItem
  module: ModuleType
  hasMoreRelevantSequence: boolean
}

export const LearningObjectWrapper = (
  props: PropsWithChildren<LearningObjectWrapperProps>,
) => {
  const { desktopMode } = useAppContext()
  const dispatch = useNavigationContextDispatch()
  const [searchParams] = useSearchParams()
  const [currentItem, setCurrentItem] = useState<ModuleItem | null>(null)

  useEffect(() => {
    dispatch({
      type: actions.IS_LEARNING_OBJECT_LOADED,
      payload: true,
    })
    return () => {
      dispatch({
        type: actions.IS_LEARNING_OBJECT_LOADED,
        payload: false,
      })
    }
  }, [dispatch])

  const {
    data: sequences,
    isLoading: isSequenceLoading,
    isError: isSequenceError,
  } = useGetModuleItemSequence({
    assetType: props.assetType,
    assetId: props.assetId,
    courseId: props.courseId,
    moduleItemId: searchParams.get(SearchParams.MODULE_ITEM_ID),
  })

  const {
    data: module,
    isLoading: isModuleLoading,
    isError: isModuleError,
  } = useGetModule({
    courseId: props.courseId,
    moduleId: currentItem?.module_id.toString() || "",
    userId: getUserId(),
    isEnabled: currentItem !== null,
  })

  useEffect(() => {
    if (!sequences) return
    setCurrentItem(sequences.items[0]?.current)
  }, [sequences])

  if (isSequenceLoading || isModuleLoading) {
    return <GlobalLoader title="Loading..." />
  }

  if (
    isSequenceError ||
    !sequences ||
    sequences.items.length === 0 ||
    isModuleError ||
    !module ||
    !module.items
  ) {
    return <FetchingError />
  }

  const { next, prev, current } = sequences.items[0]
  const hasMoreRelevantSequence = sequences.items.length > 1
  const moduleItem = module.items.find((item) => item.id === current.id)

  return (
    <Flex
      as="div"
      direction="column"
      justifyItems={"space-between"}
      height="100%"
    >
      <Flex as="div" direction="column" height="100%">
        {desktopMode ? (
          <DesktopLearningObject
            {...props}
            module={module}
            nextItem={hasMoreRelevantSequence ? null : next}
            previousItem={hasMoreRelevantSequence ? null : prev}
            hasMoreRelevantSequence={hasMoreRelevantSequence}
            moduleItem={moduleItem ?? current}
          >
            {props.children}
          </DesktopLearningObject>
        ) : (
          <MobileLearningObject
            {...props}
            module={module}
            nextItem={hasMoreRelevantSequence ? null : next}
            previousItem={hasMoreRelevantSequence ? null : prev}
            hasMoreRelevantSequence={hasMoreRelevantSequence}
            moduleItem={moduleItem ?? current}
          >
            {props.children}
          </MobileLearningObject>
        )}
      </Flex>
    </Flex>
  )
}

import { Icon } from "@/icons/Icon.tsx"
import { generateComponentTheme } from "@/shared/components/IconButton/theme.ts"
import type { BaseButtonProps } from "@/shared/themes/generateButtonTheme.ts"
import type { BaseButtonTheme } from "@instructure/shared-types"
import { IconButton } from "@instructure/ui"
import type React from "react"

interface ButtonProps extends BaseButtonProps {
  screenReaderLabel: string
  shape?: "circle"
  withBoxShadow?: boolean
  clxIconName?: string
  children?: React.ReactNode
}

export const CLXIconButton = ({
  level,
  variant = "default",
  size = "medium",
  shape = "circle",
  withBoxShadow = false,
  ...props
}: ButtonProps) => (
  <IconButton
    {...props}
    size={size}
    shape={shape}
    color={level}
    themeOverride={
      generateComponentTheme(
        level,
        withBoxShadow,
        variant,
      ) as () => Partial<BaseButtonTheme>
    }
  >
    {props.children || <Icon name={props.clxIconName} />}
  </IconButton>
)

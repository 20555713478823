import { useAppContext } from "@/AppContext.tsx"
import type { PropsWithChildren } from "react"
import { useParams } from "react-router-dom"
import { LearnDesktopLayout } from "./LearnDesktopLayout.tsx"
import { LearnMobileLayout } from "./LearnMobileLayout.tsx"

export type LearnLayoutProps = {
  courseId: string
}

export const LearnLayoutContainer = ({ children }: PropsWithChildren) => {
  const { courseId = "" } = useParams()
  const { desktopMode } = useAppContext()

  if (desktopMode) {
    return (
      <LearnDesktopLayout courseId={courseId}>{children}</LearnDesktopLayout>
    )
  }

  return <LearnMobileLayout courseId={courseId}>{children}</LearnMobileLayout>
}

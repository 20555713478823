import { Icon } from "@/icons/Icon.tsx"
import { useOutsideClick } from "@/shared/hooks/useOutsideClick.ts"
import type { FormMessage } from "@/shared/types.ts"
import { typography } from "@/themes/horizon/src/typography.ts"
import {
  Flex,
  InstUISettingsProvider,
  Select,
  Tag,
  View,
} from "@instructure/ui"
import {
  type ChangeEvent,
  type KeyboardEvent,
  type MouseEvent,
  type SyntheticEvent,
  useCallback,
  useEffect,
  useMemo,
  useRef,
} from "react"
import { useIntersection } from "react-use"
import {
  SelectContextProvider,
  actions,
  useSelectContext,
  useSelectContextDispatch,
} from "./SelectContext.tsx"

export enum SELECT_TYPE {
  MULTI = "multi",
  SINGLE = "single",
  AUTO = "auto",
}

// the passed functions should be memorised to avoid issues with rerender
export interface SelectProps<T, K> {
  options: T[]
  type: SELECT_TYPE
  getOptionLabel: (option: T) => string
  getOptionId: (option: T) => K
  setSelectedOptionIds: (optionIds: K[]) => void
  setSearchText?: (value: string) => void // filtering happens on the server side
  renderLabel?: string
  placeholder?: string
  onListEndReached?: () => void
  hasMoreMenuData?: boolean
  initialSelectedOptions?: T[]
  size?: "medium" | "small"
  interaction?: "enabled" | "disabled" | "readonly"
  messages?: FormMessage
  enableSelectionReset?: boolean
}

const RESET_OPTION_ID = "reset-option-id"

export const CLXSelect = <T extends object, K extends string | number>({
  initialSelectedOptions = [],
  type,
  getOptionLabel,
  ...rest
}: SelectProps<T, K>) => {
  const selectedOption =
    type !== SELECT_TYPE.MULTI && initialSelectedOptions.length
      ? initialSelectedOptions[0]
      : null
  return (
    <SelectContextProvider
      inputValue={selectedOption ? getOptionLabel(selectedOption) : ""}
      initialSelectedOptions={
        selectedOption ? [selectedOption] : initialSelectedOptions
      }
    >
      <SelectContent type={type} getOptionLabel={getOptionLabel} {...rest} />
    </SelectContextProvider>
  )
}

export const SelectContent = <T extends object, K extends string | number>({
  options,
  getOptionLabel,
  getOptionId,
  setSearchText,
  setSelectedOptionIds,
  onListEndReached,
  hasMoreMenuData,
  size,
  messages,
  type = SELECT_TYPE.AUTO,
  renderLabel = "",
  interaction = "enabled",
  enableSelectionReset = false,
  placeholder,
}: SelectProps<T, K>) => {
  const {
    inputValue,
    isShowingOptions,
    filteredOptionIds,
    highlightedOptionId,
    selectedOptions,
  } = useSelectContext()
  const dispatch = useSelectContextDispatch()

  const selectDropdownItemRef = useRef<HTMLElement | null>(null)
  const ref = useRef<HTMLInputElement | null>(null)

  useOutsideClick(ref, () =>
    dispatch({ type: actions.SET_IS_SHOWING_OPTIONS, payload: false }),
  )

  const optionsById: Map<K, T> = useMemo(() => {
    return new Map(options.map((option) => [getOptionId(option), option]))
  }, [options, getOptionId])

  const selectedIds = useMemo(() => {
    return selectedOptions.map((option) => getOptionId(option))
  }, [selectedOptions, getOptionId])

  useEffect(() => {
    if (setSelectedOptionIds) {
      setSelectedOptionIds(selectedOptions.map((option) => getOptionId(option)))
    }
  }, [selectedOptions, getOptionId, setSelectedOptionIds])

  useEffect(() => {
    if (setSearchText) {
      setSearchText(inputValue)
    }
  }, [inputValue, setSearchText])

  const filterOptions = useCallback(
    (value: string) => {
      const filteredList = setSearchText
        ? options
        : options.filter((option) =>
            getOptionLabel(option)
              .toLowerCase()
              .startsWith(value.toLowerCase()),
          )
      return new Set(filteredList.map((option) => getOptionId(option)))
    },
    [options, getOptionLabel, setSearchText, getOptionId],
  )

  useEffect(() => {
    dispatch({
      type: actions.OPTIONS_CHANGED,
      payload: new Set(options.map((option) => getOptionId(option))),
    })
  }, [options, getOptionId, dispatch])

  const handleShowOptions = () => {
    dispatch({ type: actions.SET_IS_SHOWING_OPTIONS, payload: true })
  }

  const handleBlur = () => {
    dispatch({ type: actions.SET_HIGHLIGHTED_OPTION, payload: null })
  }

  const handleHighlightOption = useCallback(
    (event: SyntheticEvent, { id }: { id?: string }) => {
      event.persist()
      const convertedId = id as K
      if (!optionsById.get(convertedId)) return // prevent highlighting empty op
      dispatch({ type: actions.SET_HIGHLIGHTED_OPTION, payload: convertedId })
    },
    [optionsById, dispatch],
  )

  const handleSelectOption = useCallback(
    (_event: SyntheticEvent, { id }: { id?: string }) => {
      id = id as string
      let convertedId: string | number
      if (typeof Array.from(optionsById.keys())[0] === "number") {
        convertedId = Number.parseInt(id)
      } else {
        convertedId = id
      }
      if (convertedId === RESET_OPTION_ID) {
        dispatch({
          type: actions.SELECT_OPTION,
          payload: {
            inputValue: "",
            selectedOptions: [],
            filteredOptionIds: filterOptions(""),
          },
        })
      }
      const option = optionsById.get(convertedId as K)
      if (!option) return // prevent selecting of empty option

      dispatch({
        type: actions.SELECT_OPTION,
        payload: {
          inputValue: type === SELECT_TYPE.MULTI ? "" : getOptionLabel(option),
          selectedOptions:
            type === SELECT_TYPE.MULTI
              ? [...selectedOptions, option]
              : [option],
          filteredOptionIds: filterOptions(""),
        },
      })
    },
    [
      optionsById,
      selectedOptions,
      filterOptions,
      type,
      getOptionLabel,
      dispatch,
    ],
  )

  const handleInputChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const value = event.target.value
      const newOptions = filterOptions(value)
      const firstOption =
        Array.from(newOptions).filter(
          (optionId) => !selectedIds.includes(optionId),
        )[0] ?? null
      dispatch({
        type: actions.INPUT_CHANGED,
        payload: {
          inputValue: value,
          highlightedOptionId: firstOption,
          filteredOptionIds: newOptions,
          isShowingOptions: true,
        },
      })
    },
    [filterOptions, dispatch, selectedIds],
  )

  const handleKeyDown = useCallback(
    (event: KeyboardEvent) => {
      if (event.key === "Backspace") {
        if (
          type === SELECT_TYPE.MULTI &&
          inputValue === "" &&
          selectedOptions.length > 0
        ) {
          dispatch({
            type: actions.REMOVE_SELECTED,
            payload: selectedOptions.slice(0, -1),
          })
        }
      }
      if (event.key === "Enter") {
        if (highlightedOptionId) {
          const highlightedOption = optionsById.get(highlightedOptionId)
          if (!highlightedOption) return
          dispatch({
            type: actions.SELECT_OPTION,
            payload: {
              inputValue:
                type === SELECT_TYPE.MULTI
                  ? ""
                  : getOptionLabel(highlightedOption),
              selectedOptions:
                type === SELECT_TYPE.MULTI
                  ? [...selectedOptions, highlightedOption]
                  : [highlightedOption],
              filteredOptionIds: filterOptions(""),
            },
          })
        }
      }
    },
    [
      selectedOptions,
      inputValue,
      filterOptions,
      highlightedOptionId,
      optionsById,
      type,
      dispatch,
      getOptionLabel,
    ],
  )

  const dismissTag = useCallback(
    (e: MouseEvent, tag: K) => {
      // prevent closing of list
      e.stopPropagation()
      e.preventDefault()

      const newSelection = selectedOptions.filter(
        (option) => getOptionId(option) !== tag,
      )
      dispatch({ type: actions.REMOVE_SELECTED, payload: newSelection })
      ref.current?.focus()
    },
    [getOptionId, selectedOptions, dispatch],
  )

  const handleHideOptions = useCallback(() => {
    if (type === SELECT_TYPE.MULTI) {
      dispatch({
        type: actions.INPUT_CHANGED,
        payload: {
          inputValue: "",
          highlightedOptionId: null,
          filteredOptionIds: filterOptions(""),
          isShowingOptions: false,
        },
      })
      return
    }
    const selectedOption =
      selectedOptions.length > 0 ? selectedOptions[0] : null
    const label = selectedOption ? getOptionLabel(selectedOption) : ""
    dispatch({
      type: actions.INPUT_CHANGED,
      payload: {
        inputValue: label,
        highlightedOptionId: selectedOption
          ? getOptionId(selectedOption)
          : null,
        filteredOptionIds: filterOptions(""),
        isShowingOptions: false,
      },
    })
  }, [
    getOptionId,
    selectedOptions,
    dispatch,
    getOptionLabel,
    filterOptions,
    type,
  ])

  const renderTags = useCallback(() => {
    const tags = selectedOptions.map((option, index) => {
      const id = getOptionId(option)
      return (
        <Tag
          dismissible
          key={id}
          size={size}
          text={getOptionLabel(option)}
          margin={index > 0 ? "xx-small 0 xx-small xx-small" : "xx-small 0"}
          onClick={(e) => dismissTag(e, id)}
        />
      )
    })
    return (
      <Flex wrap="wrap" padding="0 small 0 0">
        {tags}
      </Flex>
    )
  }, [selectedOptions, getOptionId, getOptionLabel, dismissTag, size])

  const renderOption = useCallback(() => {
    const options = []
    if (type !== SELECT_TYPE.MULTI && enableSelectionReset) {
      const isSelected = selectedOptions.length === 0
      options.push(
        <Select.Option
          id={RESET_OPTION_ID}
          key={RESET_OPTION_ID}
          isSelected={isSelected}
          renderAfterLabel={isSelected ? <Icon name="check" /> : null}
        >
          {placeholder ?? "Choose"}
        </Select.Option>,
      )
    }
    options.push(
      Array.from(filteredOptionIds).map((optionId, index) => {
        const option = optionsById.get(optionId)
        if (!option) return
        const isLast = filteredOptionIds.size - 1 === index
        const isSelected = selectedIds.indexOf(optionId) !== -1
        const isHighlighted = highlightedOptionId === optionId
        if (type === SELECT_TYPE.MULTI && isSelected) {
          return null
        }
        return (
          <Select.Option
            id={optionId.toString()}
            key={`select-key-${optionId}`}
            isHighlighted={isHighlighted}
            isSelected={isSelected}
            renderAfterLabel={isSelected ? <Icon name="check" /> : null}
          >
            <View
              key={`select-${optionId}`}
              elementRef={(el) => {
                if (isLast) {
                  selectDropdownItemRef.current = el as HTMLElement
                }
              }}
            >
              {getOptionLabel(option)}
            </View>
          </Select.Option>
        )
      }),
    )
    if (options.length === 0) {
      return (
        <Select.Option id="empty-option" key="empty-option">
          No match
        </Select.Option>
      )
    }
    return options
  }, [
    getOptionLabel,
    highlightedOptionId,
    selectedOptions,
    filteredOptionIds,
    optionsById,
    type,
    enableSelectionReset,
    placeholder,
    selectedIds,
  ])

  const intersection = useIntersection(selectDropdownItemRef, {
    root: null,
    rootMargin: "0px",
    threshold: 0.4,
  })

  useEffect(() => {
    if (intersection?.isIntersecting && hasMoreMenuData && onListEndReached) {
      selectDropdownItemRef.current = null
      onListEndReached()
    }
  }, [hasMoreMenuData, intersection, onListEndReached])

  return (
    <InstUISettingsProvider
      theme={{
        componentOverrides: {
          FormFieldLabel: {
            fontWeight: typography.fontWeightSemiBold,
          },
          "Options.Item": { fontWeightSelected: typography.fontWeightSemiBold },
        },
      }}
    >
      <Select
        messages={messages}
        placeholder={placeholder}
        renderLabel={renderLabel}
        interaction={interaction}
        assistiveText={`Type or use arrow keys to navigate options. ${type === SELECT_TYPE.MULTI ? "Multiple selections allowed." : null}`}
        inputValue={inputValue}
        isShowingOptions={isShowingOptions}
        inputRef={(element: HTMLInputElement | null) => {
          ref.current = element
        }}
        onBlur={handleBlur}
        onInputChange={
          type === SELECT_TYPE.SINGLE ? undefined : handleInputChange
        }
        onRequestHideOptions={handleHideOptions}
        onRequestShowOptions={handleShowOptions}
        onRequestHighlightOption={handleHighlightOption}
        onRequestSelectOption={handleSelectOption}
        onKeyDown={type === SELECT_TYPE.SINGLE ? undefined : handleKeyDown}
        renderBeforeInput={
          type === SELECT_TYPE.MULTI && selectedOptions.length > 0
            ? renderTags()
            : null
        }
        size={size}
      >
        {filteredOptionIds.size > 0 ? (
          renderOption()
        ) : (
          <Select.Option id="empty-option" key="empty-option">
            No match
          </Select.Option>
        )}
      </Select>
    </InstUISettingsProvider>
  )
}

import type { NoteTag } from "@/api/db/db"
import type {
  BedrockKBProperties,
  UseAnswerPromptProps,
} from "@/api/queries/useGetAIResponse.ts"
import type { AddressBookRecipientsProps } from "@/api/queries/useGetAddressBookRecipients.ts"
import type { AssignmentProperties } from "@/api/queries/useGetAssignment.ts"
import type { GetConversationsProps } from "@/api/queries/useGetConversations.ts"
import type { FileContentProperties } from "@/api/queries/useGetFileContent.ts"
import type {
  ModuleByUserProperties,
  ModuleItemProperties,
  ModuleItemSequenceProperties,
  ModulesByUserProperties,
} from "@/api/queries/useGetModules.ts"
import type { ModuleItemsProperties } from "@/api/queries/useGetModules.ts"
import type { PageProperties } from "@/api/queries/useGetPage.ts"
import type { QuizProperties } from "@/api/queries/useGetQuiz.ts"
import type { LaunchUrlProperties } from "@/api/queries/useGetSessionlessLaunchUrl.ts"

export const QUERY_KEYS = {
  ASSIGNMENT: {
    all: ["assignment"],
    item: (props: AssignmentProperties) => [
      ...QUERY_KEYS.ASSIGNMENT.all,
      "item",
      props,
    ],
  },
  AI_RESPONSE: {
    all: ["ai_response"],
    kbResponse: (props: BedrockKBProperties) => [
      ...QUERY_KEYS.AI_RESPONSE.all,
      props,
    ],
    response: (props: UseAnswerPromptProps) => [
      ...QUERY_KEYS.AI_RESPONSE.all,
      props,
    ],
  },
  COURSE: {
    all: ["course"],
    names: () => [...QUERY_KEYS.COURSE.all, "names"],
    name: (courseId: string) => [...QUERY_KEYS.COURSE.names(), { courseId }],
    progresses: () => [...QUERY_KEYS.COURSE.all, "progresses"],
    progress: (courseId: string, userId?: string) => [
      ...QUERY_KEYS.COURSE.progresses(),
      { courseId, userId },
    ],
    byUser: (userId: string) => [...QUERY_KEYS.COURSE.all, { userId }],
    overviews: () => [...QUERY_KEYS.COURSE.all, "overviews"],
    overview: (courseId: string) => [
      ...QUERY_KEYS.COURSE.overviews(),
      { courseId },
    ],
  },
  DOC: {
    all: ["doc"],
    byUrl: (url: string) => [...QUERY_KEYS.DOC.all, { url }],
  },
  FILE_CONTENT: {
    all: ["fileContent"],
    byProperties: (props: FileContentProperties) => [
      ...QUERY_KEYS.FILE_CONTENT.all,
      props,
    ],
  },
  MODULES: {
    all: ["module"],
    byId: (props: ModuleByUserProperties) => [...QUERY_KEYS.MODULES.all, props],
    byUser: (props: ModulesByUserProperties) => [
      ...QUERY_KEYS.MODULES.all,
      props,
    ],
    itemsSequence: (props: ModuleItemSequenceProperties) => [
      ...QUERY_KEYS.MODULES.all,
      "item_sequence",
      props,
    ],
    items: (props: ModuleItemsProperties) => [
      ...QUERY_KEYS.MODULES.all,
      "items",
      props,
    ],
    item: ({ courseId, moduleId, itemId, isEnabled }: ModuleItemProperties) => [
      ...QUERY_KEYS.MODULES.items({ courseId, moduleId, isEnabled }),
      { itemId },
    ],
  },
  NOTE: {
    all: ["notes"],
    byUserId: (userId: string) => [...QUERY_KEYS.NOTE.all, { userId }],
    bySearchCourse: (userId: string, courseSearchText: string) => [
      ...QUERY_KEYS.NOTE.all,
      { userId, courseSearchText },
    ],
    bySearchTagAndText: (
      userId: string,
      courseId: string,
      tags: NoteTag[],
      searchText: string,
    ) => [...QUERY_KEYS.NOTE.all, { userId, courseId, tags, searchText }],
  },
  NOTIFICATION: {
    all: ["notification"],
    byUser: (userId: string) => [...QUERY_KEYS.NOTIFICATION.all, { userId }],
  },
  PAGE: {
    all: ["page"],
    details: (props: PageProperties) => [
      ...QUERY_KEYS.PAGE.all,
      "details",
      props,
    ],
    content: (props: PageProperties) => [
      ...QUERY_KEYS.PAGE.all,
      "content",
      props,
    ],
  },
  QUIZ: {
    all: ["quiz"],
    assignment: (props: QuizProperties) => [...QUERY_KEYS.QUIZ.all, props],
  },
  SESSIONLESS_LAUNCH_URL: {
    all: ["sessionlessLaunchUrl"],
    byProperties: (props: LaunchUrlProperties) => [
      ...QUERY_KEYS.SESSIONLESS_LAUNCH_URL.all,
      props,
    ],
  },
  SUBMISSION: {
    all: ["submission"],
    byId: (id: string) => [...QUERY_KEYS.SUBMISSION.all, { id }],
    comments: (id: string, attempt: number) => [
      ...QUERY_KEYS.SUBMISSION.byId(id),
      { attempt },
    ],
  },
  USER: {
    all: ["user"],
    byId: (id: string) => [...QUERY_KEYS.USER.all, { id }],
  },
  ADDRESS_BOOK_RECIPIENTS: {
    all: ["addressBookRecipients"],
    byProperties: (props: AddressBookRecipientsProps) => [
      ...QUERY_KEYS.ADDRESS_BOOK_RECIPIENTS.all,
      props,
    ],
  },
  CONVERSATION: {
    all: ["conversation"],
    byProperties: (props: GetConversationsProps) => [
      ...QUERY_KEYS.CONVERSATION.all,
      props,
    ],
  },
  RCS: {
    all: ["rcs"],
    byCourseId: (courseId: string) => [...QUERY_KEYS.RCS.all, courseId],
  },
}

import NotFound from "@/features/errors/NotFound.tsx"
import { Assignment } from "@/features/learning_content/Assignment.tsx"
import { ExternalItem } from "@/features/learning_content/ExternalItem.tsx"
import { FileContent } from "@/features/learning_content/FileContent.tsx"
import { Page } from "@/features/learning_content/Page.tsx"
import { Quiz } from "@/features/learning_content/Quiz.tsx"
import Redirect from "@/features/login/Redirect.tsx"
import { Notebook } from "@/features/notebook/Notebook.tsx"
import Notification from "@/features/notification/Notification.tsx"
import { PreCourseSurvey } from "@/features/pre_course_survey/PreCourseSurvey.tsx"
import { LearnLayoutContainer } from "@/shared/components/Navigation/learn_layout/LearnLayoutContainer.tsx"
import { PAGEROUTE } from "@/shared/router"
import Unauthorized from "./features/errors/Unauthorized.tsx"
import Home from "./features/home/Home.tsx"
import { Inbox } from "./features/inbox/Inbox.tsx"
import { Journey } from "./features/journey/Journey.tsx"
import { Login } from "./features/login/Login.tsx"
import { Logout } from "./features/login/Logout.tsx"
import { OAuth2Response } from "./features/login/OAuth2Response.tsx"
import ProtectedRoute from "./features/login/ProtectedRoute.tsx"
import { CourseOverview } from "./features/programs/CourseOverview.tsx"
import { Grades } from "./features/programs/Grades.tsx"
import { Programs } from "./features/programs/Programs.tsx"
import { QuickLinks } from "./features/programs/QuickLinks.tsx"
import { MyProgress } from "./features/programs/my_progress/MyProgress.tsx"
import { sentryCreateBrowserRouter } from "./instrument.ts"
import { MainNavigation } from "./shared/components/Navigation/MainNavigation.tsx"
import { RedirectToCourseOverview } from "./shared/router/RedirectToCourseOverview.tsx"

const router = sentryCreateBrowserRouter([
  {
    element: <ProtectedRoute element={<MainNavigation />} />,
    errorElement: <NotFound />,
    children: [
      {
        path: PAGEROUTE.HOME,
        element: <Home />,
      },
      {
        path: PAGEROUTE.LEARN,
        element: <Programs />,
      },
      {
        path: PAGEROUTE.COURSE,
        element: <RedirectToCourseOverview />,
      },
      {
        path: PAGEROUTE.COURSE_PROGRESS,
        element: (
          <LearnLayoutContainer>
            <MyProgress />
          </LearnLayoutContainer>
        ),
      },
      {
        path: PAGEROUTE.COURSE_OVERVIEW,
        element: (
          <LearnLayoutContainer>
            <CourseOverview />
          </LearnLayoutContainer>
        ),
      },
      {
        path: PAGEROUTE.COURSE_GRADES,
        element: (
          <LearnLayoutContainer>
            <Grades />
          </LearnLayoutContainer>
        ),
      },
      {
        path: PAGEROUTE.COURSE_QUICKLINKS,
        element: (
          <LearnLayoutContainer>
            <QuickLinks />
          </LearnLayoutContainer>
        ),
      },
      {
        path: PAGEROUTE.PRECOURSESURVEY,
        element: <PreCourseSurvey />,
      },
      {
        path: PAGEROUTE.ASSIGNMENT,
        element: <Assignment />,
      },
      {
        path: PAGEROUTE.QUIZ,
        element: <Quiz />,
      },
      {
        path: PAGEROUTE.PAGE,
        element: <Page />,
      },
      {
        path: PAGEROUTE.EXTERNALITEM,
        element: <ExternalItem />,
      },
      {
        path: PAGEROUTE.FILECONTENT,
        element: <FileContent />,
      },

      {
        path: PAGEROUTE.SKILLSPACE,
        element: <Journey />,
      },
      {
        path: PAGEROUTE.INBOX,
        element: <Inbox />,
      },
      {
        path: PAGEROUTE.NOTEBOOK,
        element: <Notebook />,
      },
      {
        path: PAGEROUTE.ACCOUNT,
        element: <>Account</>, //TODO implement account page
      },
      {
        path: PAGEROUTE.ALERTS,
        element: <>Alerts</>, //TODO implement alerts page
      },
      {
        path: PAGEROUTE.NOTIFICATION,
        element: <Notification />,
      },
      {
        path: PAGEROUTE.LOGOUT,
        element: <Logout />,
      },
    ],
  },
  {
    path: PAGEROUTE.LOGIN,
    element: <Login />,
  },
  {
    path: PAGEROUTE.OAUTH2RESPONSE,
    element: <OAuth2Response />,
  },
  {
    path: PAGEROUTE.UNAUTHORIZED,
    element: <Unauthorized />,
  },
  {
    path: "/redirect",
    element: <Redirect />,
  },
])

export default router

import { Link, View } from "@instructure/ui"
import { useLayoutEffect, useRef, useState } from "react"
import { Document, Page, pdfjs } from "react-pdf"
import "react-pdf/dist/Page/TextLayer.css"
import "react-pdf/dist/Page/AnnotationLayer.css"

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.mjs`

export const PDFContent = ({ url }: { url: string }) => {
  const documentRef = useRef<HTMLDivElement>(null)
  const [numPages, setNumPages] = useState<number>()
  const [pageWidth, setPageWidth] = useState(0)

  function onDocumentLoadSuccess({ numPages }: { numPages: number }): void {
    setNumPages(numPages)
  }

  const onResize = () => {
    setPageWidth(documentRef?.current?.clientWidth ?? 0)
  }

  // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
  useLayoutEffect(() => {
    onResize()
    window.addEventListener("resize", onResize)
    return () => {
      window.removeEventListener("resize", onResize)
    }
  }, [])

  return (
    <>
      <View as="div">
        <View as="div" padding="medium 0 0 medium">
          <Link href={url}>Download original here</Link>
        </View>
        <div ref={documentRef}>
          <Document file={url} onLoadSuccess={onDocumentLoadSuccess}>
            {Array.from(new Array(numPages), (_, index) => (
              <Page
                key={`page_${index + 1}`}
                pageNumber={index + 1}
                width={pageWidth}
              />
            ))}
          </Document>
        </div>
      </View>
    </>
  )
}

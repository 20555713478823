import type { NavigationItemProps } from "@/shared/components/Navigation/constants.ts"
import { generateRoute } from "@/shared/router"
import { spacing } from "@/themes/horizon/src/spacing.ts"
import { ui } from "@/themes/horizon/src/ui.ts"
import { Flex, Text, View } from "@instructure/ui"
import { NavLink } from "react-router-dom"

export const SecondaryNavigationItem = ({
  route,
  title,
  subNavigationItems,
  routeParams,
}: NavigationItemProps & { routeParams?: Record<string, string> }) => {
  if (subNavigationItems && subNavigationItems.length > 0) {
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          gap: spacing.xxSmall,
          color: ui.surfaceInverseSecondary,
          width: "100%",
          height: "100%",
        }}
      >
        {title && (
          <Text size="medium" weight="normal">
            {title}
          </Text>
        )}
        {subNavigationItems && subNavigationItems.length > 0 && (
          <Flex direction="column">
            {subNavigationItems.map((item) => SecondaryNavLink(item))}
          </Flex>
        )}
      </div>
    )
  }

  return SecondaryNavLink({ route, title, routeParams })
}

export const SecondaryNavLink = ({
  route,
  title,
  routeParams,
}: NavigationItemProps & { routeParams?: Record<string, string> }) => {
  if (!route) {
    return null
  }
  const generatedRoute = generateRoute(route, routeParams ?? {})
  return (
    <View height="20px" width="111px" margin="x-small">
      <NavLink
        to={generatedRoute}
        style={{
          textDecoration: "none",
        }}
      >
        {({ isActive }) => {
          return (
            <div
              style={{
                width: "100%",
                height: "100%",
                color: ui.surfaceInverseSecondary,
                textDecoration: isActive ? "underline" : "none",
              }}
            >
              {title && (
                <Text size="medium" weight={isActive ? "bold" : "normal"}>
                  {title}
                </Text>
              )}
            </div>
          )
        }}
      </NavLink>
    </View>
  )
}

import { useMutation } from "@tanstack/react-query"
import { z } from "zod"
import { FileUploadError, canvasRestClient } from "../api"
import useValidateResponse from "../useValidateResponse"

const ZFinalizeFileUploadSettledResponse = z.array(
  z.object({
    value: z
      .object({
        id: z.number(),
        filename: z.string(),
        size: z.number(),
      })
      .strict()
      .optional(),
    status: z.enum(["rejected", "fulfilled"]),
    reason: z.instanceof(FileUploadError).optional(),
  }),
)

type ZFinalizeFileUploadSettledResponseType = z.infer<
  typeof ZFinalizeFileUploadSettledResponse
>

const mutationFn = async ({
  courseId,
  assignmentId,
  files,
  isComment = false,
}: {
  courseId: string
  assignmentId: string
  files: File[]
  isComment: boolean
}): Promise<ZFinalizeFileUploadSettledResponseType> => {
  return await Promise.allSettled(
    files.map(async (file) => {
      const fileUploadUrl = await canvasRestClient(
        `api/v1/courses/${courseId}/assignments/${assignmentId}/submissions/self/${isComment ? "comments/" : ""}files`,
        {
          method: "POST",
          body: JSON.stringify({
            name: file.name,
            no_redirect: true,
            submit_assignment: true,
          }),
        },
      )

      const { upload_params: uploadParams, upload_url: uploadUrl } =
        fileUploadUrl
      const formData = new FormData()
      for (const key in uploadParams) {
        formData.append(key, uploadParams[key])
      }
      formData.append("file", file)

      // Calling fetch directly since this is not a Canvas url
      const uploadResponse = await fetch(uploadUrl, {
        method: "POST",
        body: formData,
      })
      // The response is not useful; a 2xx is confirmation that we can move on
      if (!uploadResponse.ok) {
        throw new FileUploadError(
          `Failed to upload file: ${file.name}`,
          file.name,
        )
      }
      const finalizeUploadUrl = uploadParams.success_url

      const response = await fetch(finalizeUploadUrl)
      if (!response.ok) {
        throw new FileUploadError(
          `Failed to finalize file upload: ${file.name}`,
          file.name,
        )
      }
      const data = await response.json()
      return { id: data.id, filename: data.filename, size: data.size }
    }),
  )
}
export const useUploadFiles = () => {
  const mutationResult = useMutation({
    mutationFn,
  })

  useValidateResponse(
    "useFinalizeFileUpload",
    mutationResult,
    ZFinalizeFileUploadSettledResponse,
  )
  return mutationResult
}

import { CreateConversationButton } from "@/features/inbox/CreateConversationButton.tsx"
import { DebouncedTextField } from "@/shared/components/DebouncedTextField.tsx"
import {
  Flex,
  IconButton,
  IconConfigureLine,
  IconEditLine,
  View,
} from "@instructure/ui"

type InboxHeaderProps = {
  filterValue: string
  setFilter: (value: string) => void
}

export const InboxHeader = ({ filterValue, setFilter }: InboxHeaderProps) => {
  return (
    <Flex margin="none medium small medium">
      <Flex.Item shouldGrow>
        {/*TODO add back filter when we have the backend for it, just remove View component*/}
        <View className="hidden">
          <DebouncedTextField value={filterValue} setValue={setFilter} />
        </View>
      </Flex.Item>
      <Flex.Item>
        <View className="hidden">
          <IconButton
            screenReaderLabel="Edit"
            renderIcon={IconEditLine}
            withBackground
            shape="circle"
            withBorder={false}
            margin="x-small"
          />
        </View>
      </Flex.Item>
      <Flex.Item>
        <View className="hidden">
          <IconButton
            screenReaderLabel="Configure"
            renderIcon={IconConfigureLine}
            withBackground
            shape="circle"
            withBorder={false}
            margin="x-small"
          />
        </View>
      </Flex.Item>
      <Flex.Item>
        <CreateConversationButton />
      </Flex.Item>
    </Flex>
  )
}

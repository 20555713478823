import { canvasGqlClient } from "@/api/api.ts"
import { QUERY_KEYS } from "@/api/queryKeys.ts"
import useValidateResponse from "@/api/useValidateResponse.ts"
import { useMutation, useQueryClient } from "@tanstack/react-query"
import { gql } from "graphql-request"
import { z } from "zod"

const ZCreateConversationResponse = z
  .object({
    createConversation: z.object({
      errors: z
        .array(
          z
            .object({
              attribute: z.string(),
              message: z.string(),
            })
            .strict(),
        )
        .nullable(),
    }),
  })
  .strict()

type CreateConversationResponse = z.infer<typeof ZCreateConversationResponse>

type CreateConversationProperties = {
  body: string
  subject: string
  recipients: string[]
}

export const CREATE_CONVERSATION = gql`
    mutation CreateConversation(
        $body: String!
        $recipients: [String!]!
        $subject: String
        $bulkMessage: Boolean
        $groupConversation: Boolean
    ) {
        createConversation(
            input: {
                body: $body
                bulkMessage: $bulkMessage
                recipients: $recipients
                subject: $subject
                groupConversation: $groupConversation
            }
        ) {
            errors {
                attribute
                message
            }
        }
    }
`

export const useCreateConversation = () => {
  const queryClient = useQueryClient()
  const mutationResult = useMutation({
    mutationFn: async (
      props: CreateConversationProperties,
    ): Promise<CreateConversationResponse> =>
      (await canvasGqlClient()).request(CREATE_CONVERSATION, {
        ...props,
        bulkMessage: false,
        groupConversation: true,
      }),
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: QUERY_KEYS.CONVERSATION.all,
      })
    },
  })

  useValidateResponse(
    "useCreateConversation",
    mutationResult,
    ZCreateConversationResponse,
  )
  return mutationResult
}

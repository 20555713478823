import { useAppContext } from "@/AppContext.tsx"
import { DESKTOP_NAVIGATION_WIDTH } from "@/features/learning_content/learning_objects_skeleton/constants.ts"
import { CLXIconButton } from "@/shared/components/IconButton/IconButton.tsx"
import { AiNavigationItem } from "@/shared/components/Navigation/AiNavigationItem.tsx"
import { AI } from "@/shared/components/Navigation/constants.ts"
import { PAGEROUTE, generateRoute } from "@/shared/router"
import { spacing } from "@/themes/horizon/src/spacing.ts"
import { Flex } from "@instructure/ui"
import { useNavigate } from "react-router-dom"

export const DesktopLearningObjectNavigation = ({
  learningAssistContext,
  courseId,
}: { learningAssistContext: string; courseId: string }) => {
  const navigate = useNavigate()
  const { viewportHeight } = useAppContext()
  return (
    <div
      style={{
        display: "flex",
        alignSelf: "start",
        flexDirection: "column",
        justifyContent: "space-between",
        padding: `${spacing.large} ${spacing.xxSmall}`,
        height: viewportHeight,
        minWidth: DESKTOP_NAVIGATION_WIDTH,
        position: "sticky",
        top: 0,
      }}
    >
      <Flex direction="column" alignItems="center">
        <CLXIconButton
          screenReaderLabel={"Back to my progress"}
          level="primary"
          variant="inverse"
          clxIconName="arrow_left_alt"
          withBoxShadow={true}
          onClick={() => {
            const route = generateRoute(PAGEROUTE.COURSE_PROGRESS, {
              courseId,
            })
            navigate(route)
          }}
        />
      </Flex>
      <Flex direction="column" alignItems="center">
        <AiNavigationItem
          isIconButton={true}
          context={learningAssistContext}
          {...AI}
        />
      </Flex>
    </div>
  )
}

import type { VideoMimeType } from "@instructure/studio-player"
import { StudioPlayer } from "@instructure/studio-player"
import { View } from "@instructure/ui"
import { ErrorBoundary } from "@sentry/react"
import { useEffect, useState } from "react"
import type { ZMimeTypesType } from "../../api/types"

export const VideoContent = ({
  url,
  contentType,
}: { url: string; contentType: ZMimeTypesType }) => {
  const properContentType =
    contentType === "video/quicktime" ? "video/mp4" : contentType

  /**
   * We need to render StudioPlayer after the initial component tree rendered, because
   * there is a bug: if we render it immediately after we remove the global loading indicator,
   * it is not able to initialize itself properly as somehow it receives undefined as props object.
   * TODO: Investigate why this happens and remove this workaround.
   */
  const [loaded, setLoaded] = useState(false)
  // biome-ignore lint/correctness/useExhaustiveDependencies: we need to run this effect only if URL changes
  useEffect(() => {
    window.requestAnimationFrame(() => {
      setLoaded(true)
    })
    return () => {
      setLoaded(false)
    }
  }, [url])

  return (
    <View as="div" height={"50vh"}>
      {loaded && (
        <ErrorBoundary fallback={<div>Video failed to load</div>}>
          <StudioPlayer
            src={[
              {
                src: url,
                type: properContentType as VideoMimeType,
              },
            ]}
            title="Video"
          />
        </ErrorBoundary>
      )}
    </View>
  )
}

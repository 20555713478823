import { borders } from "../borders.ts"
import { typography } from "../typography.ts"
import { ui } from "../ui.ts"

export default {
  Badge: {
    fontFamily: typography.fontFamilyHeading,
    fontWeight: typography.fontWeightNormal,
    fontSize: typography.fontSizeXSmall,

    color: ui.textSurfaceColored,
    colorPrimary: ui.surfaceAttention,
    colorInverse: ui.textBody, // for inverse, this represents the color of the text, not the background color
    colorSuccess: ui.surfaceSuccess,
    colorDanger: ui.surfaceError,

    borderRadius: borders.radiusMedium,

    countOffset: "0.3rem",

    // letterSpacing: typography.letterSpacingExpanded,
  },
}

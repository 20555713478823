import liveRegion from "@/shared/utils/liveRegion"
import type { SyntheticEvent } from "react"
import type { Editor } from "tinymce"

export type MapPropsToRCEType = {
  autoFocus?: boolean
  textareaId: string
  defaultContent: string
  stem?: string
  height?: number | null
  actsAsInput?: boolean
  readOnly?: boolean
  placeholder?: string
  label: React.ReactNode
  messages?: {
    type: string
  }[]
  onFocus?: (event: SyntheticEvent, content: { editorContent: string }) => void
  onBlur?: (event: SyntheticEvent, content: { editorContent: string }) => void
  editorOptions?: {
    spellCheck: boolean
    wordCount: boolean
  }
  onContentChange: (content: string) => void
  onInit: (
    tinyEditorInstance: Editor & { rceWrapper: { iframe: { title: string } } },
  ) => void
}

export type CanvasConfig = {
  RICH_CONTENT_FILES_TAB_DISABLED: boolean
  RICH_CONTENT_CAN_UPLOAD_FILES: boolean
  RICH_CONTENT_CAN_EDIT_FILES: boolean
  DEEP_LINKING_POST_MESSAGE_ORIGIN: string
  RICH_CONTENT_INST_RECORD_TAB_DISABLED: boolean
  LOCALE: string
  active_brand_config_json_url: string
  kalturaSettings?: {
    hide_rte_button: boolean
  }
  FEATURES: {
    buttons_and_icons_root_account: boolean
    buttons_and_icons_cropper: boolean
    rce_ux_improvements: boolean
  }
}

export type MapRcsPropsToRcsType = {
  canvasUserId: string
  contextId: string
  contextType: string
  resourceType?: string
  resourceId?: string
  rcsHost: string
  rcsJwt: string
  rcsJwtRefreshFn: (cb: (freshToken: string) => void) => void
}

export const mapCLXRcePropsToCanvasRceProps = (
  props: MapPropsToRCEType,
  rcs: MapRcsPropsToRcsType,
  canvasConfig: CanvasConfig,
) => {
  const {
    textareaId,
    defaultContent,
    stem,
    readOnly,
    onFocus,
    autoFocus,
    height,
    onBlur,
    onContentChange,
    onInit,
  } = props

  const {
    contextType,
    contextId,
    canvasUserId: userId,
    resourceType,
    resourceId,
    rcsHost: host,
    rcsJwt: jwt,
    rcsJwtRefreshFn: refreshToken,
  } = rcs

  const {
    RICH_CONTENT_INST_RECORD_TAB_DISABLED: instRecordDisabled,
    RICH_CONTENT_CAN_EDIT_FILES,
    RICH_CONTENT_CAN_UPLOAD_FILES,
    LOCALE,
    RICH_CONTENT_CAN_UPLOAD_FILES: canUploadFiles,
    RICH_CONTENT_FILES_TAB_DISABLED: filesTabDisabled,
    active_brand_config_json_url: themeUrl,
    // DEEP_LINKING_POST_MESSAGE_ORIGIN: canvasUrl,
    FEATURES,
  } = canvasConfig

  // TODO: I have no idea why this works, but this is needed to show the uploaded file correctly
  const canvasUrl = window.location.origin

  const {
    buttons_and_icons_cropper: icon_maker_cropper = false,
    rce_ux_improvements = false,
    buttons_and_icons_root_account,
  } = FEATURES || {}

  const features = { icon_maker_cropper, rce_ux_improvements }

  const rcsProps = {
    containingContext: {
      contextType,
      contextId,
      userId,
      resourceId,
      resourceType,
    },
    contextId,
    contextType,
    host,
    jwt,
    refreshToken,
    canUploadFiles,
    filesTabDisabled,
    themeUrl,
    canvasUrl,
    features,
  }

  const spellCheck = props.editorOptions?.spellCheck
  const showWordCount = props.editorOptions?.wordCount

  const editorOptions = () => ({
    body_class: "",
    selector: `#${textareaId}`,
    directionality:
      document.documentElement.getAttribute("dir") ||
      getComputedStyle(document.documentElement, null).direction,
    content_css: [],
    show_media_upload: false,
    auto_focus: autoFocus,
    browser_spellcheck: spellCheck !== false,
    plugins: showWordCount === false ? ["-instructure_wordcount"] : [],
  })

  const use_rce_icon_maker = !!(
    RICH_CONTENT_CAN_UPLOAD_FILES &&
    RICH_CONTENT_CAN_EDIT_FILES &&
    buttons_and_icons_root_account
  )

  const rceProps = {
    textareaId,
    defaultContent,
    onContentChange,
    onInit,
    liveRegion,
    editorOptions,
    features,
    height,
    readOnly,
    onBlur,
    onFocus,
    language: LOCALE || "en",
    rcsProps,
    stem,
    use_rce_icon_maker,
    instRecordDisabled,
    canvasOrigin: canvasUrl,
  }
  return rceProps
}

export const LOREM = `Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi pretium pellentesque velit. Donec nec lacinia lectus. Duis sagittis nisl enim, ut consequat augue tempus nec. Nulla in vulputate tellus. Ut sollicitudin eros ut arcu tempus, sed euismod turpis vestibulum. Duis gravida egestas elementum. Aenean nibh elit, dignissim at ipsum id, suscipit dignissim libero. Nunc gravida et augue eget vulputate. Nam porta est eget enim ultrices hendrerit. Maecenas velit nibh, cursus et laoreet a, sagittis vel odio. Pellentesque consectetur mauris vel rhoncus vestibulum. Aliquam fringilla, lectus sed sollicitudin gravida, ligula erat gravida diam, ut ultricies erat augue faucibus purus. Nullam risus mauris, laoreet vel ultrices a, molestie et ex.
Nam molestie accumsan erat. Duis odio odio, auctor interdum semper nec, imperdiet consectetur magna. Aliquam mauris mi, placerat vel enim non, vestibulum convallis urna. Nulla accumsan velit non vehicula sollicitudin. Aliquam a velit lectus. Sed porttitor neque sed justo commodo, vitae congue ex finibus. Donec condimentum blandit nibh nec porta. Donec elementum pharetra suscipit.
Curabitur tempus a nisi a cursus. Vestibulum urna purus, lobortis sit amet nunc sed, hendrerit mollis mi. Donec facilisis elit quam, id malesuada ipsum mattis pharetra. Vestibulum ut mi venenatis, lacinia nunc viverra, vulputate elit. Aenean rutrum, dui non convallis gravida, massa orci iaculis diam, vitae hendrerit dui justo ut arcu. Morbi at rutrum tortor, quis convallis purus. Nunc a suscipit dui. Morbi hendrerit non lacus ac tempor. Integer sollicitudin sapien elit, porta venenatis nunc tempor sed. Aenean tempus sem quis quam semper laoreet. Nam semper mattis est, et sodales tortor interdum id. Sed pharetra, urna ac dictum aliquam, orci purus dictum ipsum, id iaculis massa augue vitae elit. Donec ac scelerisque elit, non fermentum risus.
Vestibulum finibus, dolor quis aliquet laoreet, magna metus volutpat velit, vitae imperdiet nunc lectus quis eros. Nunc nulla enim, tempus eget mollis eget, vulputate vitae odio. Pellentesque sed sodales erat. Praesent nec accumsan arcu. Proin hendrerit odio nec nisi lacinia venenatis. Nunc massa lorem, mollis convallis est at, tincidunt congue sem. Nulla ornare turpis est, ac pellentesque felis efficitur at. Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. Nulla a orci elit. Sed porta arcu tellus, in facilisis libero ornare at. Sed congue consectetur massa, quis facilisis velit dictum dapibus. Quisque tempor tortor vel quam euismod, vitae ornare arcu suscipit.
In hac habitasse platea dictumst. Quisque in lacus suscipit, tristique nunc a, semper est. Donec eget sem dolor. Quisque neque nunc, sagittis quis blandit nec, dapibus quis ex. Integer pulvinar mollis magna, quis iaculis nibh commodo ac. Orci varius natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Praesent elementum ullamcorper odio in gravida. Nulla elementum ipsum ut augue egestas, ac consectetur urna cursus. Suspendisse ac dolor vel nisl auctor egestas consectetur at magna. Morbi finibus bibendum arcu, vitae eleifend leo porta sed. Ut mauris sem, auctor vitae sapien vel, cursus consequat mauris. Suspendisse vitae lorem ullamcorper, tincidunt tortor sit amet, fermentum diam. Ut sed enim purus. Vestibulum tincidunt ullamcorper varius. Integer tempus tempus felis, eu lobortis ipsum accumsan vel. Nullam ac suscipit sapien, sit amet imperdiet urna.
Proin placerat sagittis faucibus. Aenean vestibulum eget lorem quis tempus. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos. Morbi eget ornare orci. Aliquam accumsan, augue tincidunt venenatis tincidunt, tortor lectus hendrerit dolor, eget rhoncus turpis mauris sit amet quam. Aenean tincidunt condimentum felis, a tincidunt dolor convallis a. Suspendisse ac justo eget massa viverra faucibus nec eget lacus. Duis accumsan malesuada augue placerat dignissim. Interdum et malesuada fames ac ante ipsum primis in faucibus.
Nullam at lorem a sapien vestibulum consectetur at quis dolor. Sed consectetur leo nec diam tristique, ut mollis enim placerat. Phasellus feugiat mauris ante, commodo maximus augue egestas in. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam fermentum nisi eu tortor tristique viverra. Mauris a dictum magna, ut tristique nisi. Quisque quis nulla elementum, commodo justo at, pellentesque leo. Aliquam erat volutpat. Vestibulum quis tincidunt orci. Nam eu quam id ex dictum dignissim. Aenean sed dolor accumsan, egestas purus quis, finibus orci. Donec porta sagittis faucibus. Nullam auctor nec neque a varius.
Suspendisse malesuada nibh at accumsan vulputate. Vestibulum sit amet lacus orci. Duis convallis odio eu mauris rutrum, id vehicula purus imperdiet. Nam mollis auctor neque, nec convallis massa vulputate at. Morbi blandit porta ante, a ultricies massa mollis sit amet. Morbi gravida vitae eros ac sodales. Proin mollis vel dolor ut rhoncus. Maecenas bibendum gravida augue, non luctus est. Praesent pellentesque, dui sed rhoncus pharetra, justo libero aliquet turpis, non consectetur tortor nisi ut neque. Fusce eget turpis pretium, pulvinar lacus a, lobortis erat. Interdum et malesuada fames ac ante ipsum primis in faucibus. Sed auctor ullamcorper enim et tristique. Suspendisse ut viverra sem.
Suspendisse potenti. Nullam at dictum magna, non faucibus velit. Sed tristique egestas nunc. Nulla interdum auctor est auctor faucibus. Nullam rutrum velit a porttitor dapibus. Ut massa erat, consequat at sollicitudin in, sodales eu leo. Donec consectetur eleifend nisl, ultrices bibendum enim.
Morbi massa dui, luctus pulvinar nisl et, posuere euismod velit. Ut semper pellentesque aliquam. Mauris egestas, ipsum pulvinar imperdiet pharetra, massa sem lacinia risus, non fringilla augue nisi ut orci. Duis ut tempus velit. In ac diam quis neque commodo pulvinar vel non augue. Nam porta congue risus quis tincidunt. Quisque elit tortor, aliquet sed leo et, condimentum mattis purus. Nulla iaculis facilisis ante pulvinar viverra. Vivamus vel diam iaculis, laoreet urna efficitur, posuere diam. Proin porta lacinia quam, eget feugiat lectus rhoncus nec. Maecenas vitae imperdiet mauris. Phasellus suscipit vel nisl iaculis rutrum. Nullam eu suscipit neque. Nam eget velit nunc. In dui ex, consectetur nec metus quis, gravida gravida dui. Nulla facilisi.`

export const DESKTOP_CONTENT_PADDING_TOP = 40

export const MOBILE_MENU_HEIGHT = 44
export const AI_GRADIENT = "linear-gradient(200deg, #086732, #0B557A)"

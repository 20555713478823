import {
  Flex,
  IconArrowStartLine,
  IconButton,
  IconXLine,
  Text,
} from "@instructure/ui"

const BUTTON_WIDTH = "38px"

export const Header = ({
  showBackButton,
  onBack,
  onClose,
}: { showBackButton: boolean; onBack: () => void; onClose: () => void }) => {
  return (
    <Flex as="div" justifyItems="space-between" margin="medium">
      <Flex.Item width={BUTTON_WIDTH}>
        {showBackButton && (
          <IconButton
            screenReaderLabel="Back"
            onClick={onBack}
            withBorder={false}
            withBackground={false}
            color="primary-inverse"
          >
            <IconArrowStartLine />
          </IconButton>
        )}
      </Flex.Item>
      <Flex.Item>
        <Text size="large" color="primary-inverse">
          AI Learning Assist
        </Text>
      </Flex.Item>
      <Flex.Item width={BUTTON_WIDTH}>
        <IconButton
          screenReaderLabel="Close"
          onClick={onClose}
          withBorder={false}
          withBackground={false}
          color="primary-inverse"
        >
          <IconXLine />
        </IconButton>
      </Flex.Item>
    </Flex>
  )
}

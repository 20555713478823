import { Login } from "@/features/login/Login.tsx"
import { parseHorizonRedirectUrl } from "@/shared/router/index.ts"
import { clearTokenData } from "@/token-storage.ts"
import { Navigate, useLocation } from "react-router-dom"

const getPathname = (url: string): string => {
  try {
    return new URL(url).pathname
  } catch {
    return url
  }
}

const Redirect = () => {
  const location = useLocation()
  const searchParams = new URLSearchParams(location.search)
  const shouldReauthenticate = searchParams.get("reauthenticate") === "true"
  const canvasUrl = searchParams.get("canvas_url") || "/"
  const horizonUrl = parseHorizonRedirectUrl(getPathname(canvasUrl))

  if (shouldReauthenticate) {
    clearTokenData()
    return <Login state={{ redirect_url: horizonUrl }} />
  }

  return <Navigate to={horizonUrl} replace />
}

export default Redirect

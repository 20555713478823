import type { CLXPillThemeProps } from "@/shared/components/Pill/Pill.tsx"
import { PILL_COLOR_OVERRIDE } from "@/themes/horizon/src/componentOverrides/Pill.ts"
import { primitives } from "@/themes/horizon/src/primitives.ts"
import { spacing } from "@/themes/horizon/src/spacing.ts"
import type { CLXTheme, ThemeObjectValue } from "@/themes/types.ts"

export const generatePillComponentTheme =
  ({ pillSize, fill, color, isBorderVisible }: CLXPillThemeProps) =>
  (_: unknown, currentTheme: CLXTheme) => {
    const { borders } = currentTheme
    const baseComponentTheme = {
      ...(currentTheme.componentOverrides.Pill as ThemeObjectValue),
      ...(pillSize === "small" && {
        padding: `0 ${spacing.xSmall}`,
      }),
      ...(pillSize === "x-small" && {
        padding: "none",
      }),
      ...(fill === "solid" && {
        background: PILL_COLOR_OVERRIDE[color || "primary"],
        primaryColor: primitives.white,
        dangerColor: primitives.white,
        infoColor: primitives.white,
      }),
    }

    if (!isBorderVisible) {
      return {
        ...baseComponentTheme,
        borderStyle: borders.none,
      }
    }

    return baseComponentTheme
  }

export const generatePillTextTheme =
  (isUpperCase: boolean) => (_: unknown, currentTheme: CLXTheme) => {
    const { typography } = currentTheme
    const baseComponentTheme = {
      ...(currentTheme.componentOverrides.Text as ThemeObjectValue),
    }

    return {
      ...baseComponentTheme,
      letterSpacingNormal: isUpperCase
        ? typography.letterSpacingExpanded
        : typography.letterSpacingSemiExpanded,
      fontFamily: isUpperCase
        ? typography.fontFamilyHeading
        : typography.fontFamily,
    }
  }

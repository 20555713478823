import { compile, match } from "path-to-regexp"

// Utility type to extract dynamic variables and map them to an object with optional properties
type ExtractParamsAsObject<Route extends string> =
  Route extends `${string}:${infer Param}/${infer Rest}`
    ? { [K in Param | keyof ExtractParamsAsObject<`/${Rest}`>]: string }
    : Route extends `${string}:${infer Param}`
      ? { [K in Param]: string }
      : object

export enum PAGEROUTE {
  HOME = "/",

  LEARN = "/learn",
  COURSE = "/learn/:courseId",
  COURSE_PROGRESS = "/learn/:courseId/progress",
  COURSE_OVERVIEW = "/learn/:courseId/overview",
  COURSE_GRADES = "/learn/:courseId/grades",
  COURSE_QUICKLINKS = "/learn/:courseId/quick-links",
  PRECOURSESURVEY = "/learn/:courseId/pre-course-survey",
  ASSIGNMENT = "/learn/:courseId/assignments/:assignmentId",
  QUIZ = "/learn/:courseId/quizzes/:assignmentId",
  PAGE = "/learn/:courseId/pages/:pageId",
  EXTERNALITEM = "/learn/:courseId/modules/:moduleId/items/:itemId",
  FILECONTENT = "/learn/:courseId/files/:fileId",

  SKILLSPACE = "/skillspace",
  INBOX = "/inbox",
  NOTEBOOK = "/notebook",
  NOTIFICATION = "/notification",
  ACCOUNT = "/account",
  ALERTS = "/alerts",

  LOGIN = "/login",
  LOGOUT = "/logout",
  UNAUTHORIZED = "/unauthorized",
  OAUTH2RESPONSE = "/oauth2response",
}

export enum APIROUTE {
  LOGIN = "login/oauth2/token",
  FILE = "api/v1/courses/:courseId/files/:fileId",
  MODULES = "api/v1/courses/:courseId/modules",
  MODULE = "api/v1/courses/:courseId/modules/:moduleId",
  ITEMS = "api/v1/courses/:courseId/modules/:moduleId/items",
  ITEM = "api/v1/courses/:courseId/modules/:moduleId/items/:itemId",
  ITEM_SEQUENCE = "api/v1/courses/:courseId/module_item_sequence",
  PAGE = "api/v1/courses/:courseId/pages/:pageId",
  ASSIGNMENT = "api/v1/courses/:courseId/assignments/:assignmentId",
  SESSIONLESSLAUNCH = "api/v1/courses/:courseId/external_tools/sessionless_launch",
  NOTIFICATION = "api/v1/users/:userId/planner/items",
  PAGE_MARK_DONE = "api/v1/courses/:courseId/modules/:moduleId/items/:itemId/done",
}

export enum CANVAS_ROUTE {
  COURSES = "/courses",
  COURSE = "/courses/:courseId",
  MODULES = "/courses/:courseId/modules",
  OVERVIEW = "/courses/:courseId/assignments/syllabus",
  GRADES = "/courses/:courseId/grades",
  PAGE = "/courses/:courseId/pages/:pageId",
  ASSIGNMENT = "/courses/:courseId/assignments/:assignmentId",
  QUIZ = "/courses/:courseId/quizzes/:quizId",
  MODULE = "/courses/:courseId/modules/:moduleId",
}

const CANVAS_HORIZON_ROUTE_MAPPING: [CANVAS_ROUTE, PAGEROUTE][] = [
  [CANVAS_ROUTE.COURSES, PAGEROUTE.LEARN],
  [CANVAS_ROUTE.OVERVIEW, PAGEROUTE.COURSE_OVERVIEW],
  [CANVAS_ROUTE.COURSE, PAGEROUTE.COURSE_OVERVIEW],
  [CANVAS_ROUTE.MODULES, PAGEROUTE.COURSE],
  [CANVAS_ROUTE.MODULES, PAGEROUTE.COURSE_PROGRESS],
  [CANVAS_ROUTE.GRADES, PAGEROUTE.COURSE_GRADES],
  [CANVAS_ROUTE.PAGE, PAGEROUTE.PAGE],
  [CANVAS_ROUTE.ASSIGNMENT, PAGEROUTE.ASSIGNMENT],
  [CANVAS_ROUTE.QUIZ, PAGEROUTE.QUIZ],
  [CANVAS_ROUTE.MODULE, PAGEROUTE.EXTERNALITEM],
]

export const CANVAS_ORIGIN_ROUTE = "CANVAS_ORIGIN_ROUTE"

export const parseHorizonRedirectUrl = (url: string): string => {
  localStorage.setItem(CANVAS_ORIGIN_ROUTE, url)

  for (const [canvasRoute, horizonRoute] of CANVAS_HORIZON_ROUTE_MAPPING) {
    const matcher = match(canvasRoute)
    const matched = matcher(url)

    if (matched) {
      return generateRoute(horizonRoute, matched.params)
    }
  }

  const courseIdMatch = url.match(/\/courses\/(\d+)/)

  if (courseIdMatch) {
    return generateRoute(PAGEROUTE.COURSE, { courseId: courseIdMatch[1] })
  }

  return generateRoute(PAGEROUTE.HOME, {})
}

export const parseCanvasRedirectUrl = (url: string, param: string): string => {
  const canvasBaseUrl = import.meta.env.PUBLIC_CANVAS_HOST

  for (const [canvasRoute, horizonRoute] of CANVAS_HORIZON_ROUTE_MAPPING) {
    const matcher = match(horizonRoute)
    const matched = matcher(url)

    if (matched) {
      const { courseId } = matched.params
      return `${canvasBaseUrl}/courses/${courseId}?${param}=${generateRoute(canvasRoute, matched.params)}`
    }
  }

  const canvasOriginRoute = localStorage.getItem(CANVAS_ORIGIN_ROUTE) || ""
  const courseMatch = canvasOriginRoute.match(/\/courses\/\d+/)

  if (courseMatch) {
    return `${canvasBaseUrl}${courseMatch[0]}?${param}=${courseMatch[0]}`
  }

  return canvasBaseUrl
}

type RouteParams = {
  [K in APIROUTE | PAGEROUTE | CANVAS_ROUTE]: ExtractParamsAsObject<K>
}

export const generateRoute = <T extends APIROUTE | PAGEROUTE | CANVAS_ROUTE>(
  path: T,
  params: RouteParams[T],
  queryParam?: Record<string, string | number>[],
): string => {
  const toPath = compile(path)
  const route = toPath(params)

  if (queryParam?.length) {
    return `${route}?${queryParam.map((q) => `${Object.keys(q)[0]}=${Object.values(q)[0]}`).join("&")}`
  }

  return route
}

export enum SearchParams {
  ADD_NOTE = "add_note",
  EDIT_NOTE = "edit_note",
  VIEW_NOTE = "view_note",
  MODULE_ITEM_ID = "module_item_id",
}

import type { CanvasConfig } from "@/shared/components/RCE/RichContentInput/rce5utils"
import { getUserId } from "@/token-storage"
import { type UseQueryResult, useQuery } from "@tanstack/react-query"
import { QUERY_KEYS } from "../queryKeys"

export const useGetRCEConfig = ({
  token,
  courseId,
}: { token: string; courseId: string }): UseQueryResult<
  CanvasConfig,
  Error
> => {
  const queryKey = QUERY_KEYS.RCS.byCourseId(courseId)
  const queryResult = useQuery({
    queryKey,
    enabled: !!token && !!courseId,
    queryFn: () =>
      fetch(
        `${import.meta.env.PUBLIC_RCS_HOST}/api/rceConfig?contextType=user&contextId=${getUserId() as string}`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        },
      ).then((resp) => resp.json()),
  })

  return queryResult
}

import { useGetCourseOverview } from "@/api/queries/useGetCourses.ts"
import { FetchingError } from "@/shared/loading/FetchingError.tsx"
import { GlobalLoader } from "@/shared/loading/GlobalLoader.tsx"
import "./styles.css"
import { Card } from "@/shared/components/Card/Card"
import { createMarkup } from "@/shared/utils"
import { useParams } from "react-router-dom"

export const CourseOverview = () => {
  const { courseId = "" } = useParams()
  const overviewQuery = useGetCourseOverview(courseId)

  if (overviewQuery.isLoading) {
    return <GlobalLoader title="Loading..." />
  }

  if (overviewQuery.isError) {
    return <FetchingError />
  }

  if (!overviewQuery.data?.courseOverview) {
    return <Card>No overview available</Card>
  }

  return (
    <Card margin="none medium">
      <div
        // biome-ignore lint/security/noDangerouslySetInnerHtml: <explanation>
        dangerouslySetInnerHTML={createMarkup(
          overviewQuery.data.courseOverview,
        )}
      />
    </Card>
  )
}

import { Icon } from "@/icons/Icon.tsx"
import { createMarkup } from "@/shared/utils"
import { ui } from "@/themes/horizon/src/ui.ts"
import { Flex, Text } from "@instructure/ui"

type LearningObjectLockedContent = {
  lockExplanation: string
}

export const LearningObjectLockedContent = ({
  lockExplanation,
}: LearningObjectLockedContent) => {
  const markup = createMarkup(lockExplanation)
  return (
    <Flex as="div" direction="column" gap="medium">
      <Flex as="div" gap="xx-small" alignItems="center">
        <Icon
          name="lock"
          width={24}
          height={24}
          customColor={ui.surfaceInstitution}
        />
        <Text size="large">Locked Content</Text>
      </Flex>
      <Text size="small">
        <div
          // biome-ignore lint/security/noDangerouslySetInnerHtml: sanitized by createMarkup
          dangerouslySetInnerHTML={markup}
        />
      </Text>
    </Flex>
  )
}

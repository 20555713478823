import { typography } from "../typography.ts"
import { ui } from "../ui.ts"

export default {
  Heading: {
    lineHeight: typography.lineHeight,

    h1FontSize: typography.fontSizeXXLarge,
    h1FontWeight: typography.fontWeightBold,
    h1FontFamily: typography.fontFamilyHeading,

    h2FontSize: typography.fontSizeXLarge,
    h2FontWeight: typography.fontWeightSemiBold,
    h2FontFamily: typography.fontFamilyHeading,

    h3FontSize: typography.fontSizeLarge,
    h3FontWeight: typography.fontWeightBold,
    h3FontFamily: typography.fontFamilyHeading,

    primaryColor: ui.textTitle,
    primaryInverseColor: ui.textSurfaceColored,

    borderStyle: "hidden",
  },
}

import {
  type UseInfiniteQueryResult,
  useInfiniteQuery,
} from "@tanstack/react-query"
import { compareDesc } from "date-fns"
import { gql } from "graphql-request"
import { z } from "zod"
import { canvasGqlClient } from "../api"
import { QUERY_KEYS } from "../queryKeys"
import { ZSubmissionCommentContents } from "../types"
import useValidateResponse from "../useValidateResponse"

const ZSubmissionCommentsPageInfo = z.object({
  startCursor: z.string(),
  hasPreviousPage: z.boolean(),
  hasNextPage: z.boolean(),
})

const ZSubmissionComments = z.object({
  submissionComments: z.object({
    commentsConnection: z.object({
      pageInfo: ZSubmissionCommentsPageInfo,
      nodes: ZSubmissionCommentContents,
    }),
  }),
})

const ZSubmissionCommentsResponse = z.object({
  comments: ZSubmissionCommentContents,
})

type ZSubmissionCommentsType = z.infer<typeof ZSubmissionComments>
type ZSubmissionCommentsResponseType = z.infer<
  typeof ZSubmissionCommentsResponse
>

const query = gql`query GetSubmissionComments($submissionId: ID!, $submissionAttempt: Int!, $cursor: String, $peerReview: Boolean = false) {
  submissionComments: node(id: $submissionId) {
    ... on Submission {
      commentsConnection(
        last: 20
        before: $cursor
        filter: {forAttempt: $submissionAttempt, peerReview: $peerReview}
      ) {
        pageInfo {
          startCursor
          hasPreviousPage
          hasNextPage
        }
        nodes {
          ...SubmissionHtmlComment
        }
      }
    }
  }
}

fragment SubmissionHtmlComment on SubmissionComment {
  _id
  attachments {
    ...SubmissionCommentFile
  }
  author {
    ...SubmissionCommentAuthor
  }
  htmlComment
  mediaObject {
    ...MediaObject
  }
  read
  updatedAt
  createdAt
}

fragment MediaObject on MediaObject {
  id
  _id
  mediaSources {
    ...MediaSource
  }
  mediaTracks {
    ...MediaTrack
  }
  mediaType
  title
}

fragment MediaSource on MediaSource {
  height
  src: url
  type: contentType
  width
}

fragment MediaTrack on MediaTrack {
  _id
  locale
  content
  kind
}

fragment SubmissionCommentAuthor on User {
  avatarUrl
  shortName
}

fragment SubmissionCommentFile on File {
  _id
  displayName
  id
  mimeClass
  url
  contentType
}
`

export const useGetSubmissionComments = (
  submissionId: string,
  attempt: number,
): UseInfiniteQueryResult<ZSubmissionCommentsResponseType> => {
  const queryKey = QUERY_KEYS.SUBMISSION.comments(submissionId, attempt)
  const queryResult = useInfiniteQuery({
    queryKey,
    queryFn: async ({ pageParam }): Promise<ZSubmissionCommentsType> =>
      (await canvasGqlClient()).request(query, {
        submissionId,
        submissionAttempt: attempt,
        cursor: pageParam,
      }),
    initialPageParam: "",
    getNextPageParam: (lastPage) =>
      (lastPage.submissionComments.commentsConnection.pageInfo
        .hasPreviousPage &&
        lastPage.submissionComments.commentsConnection.pageInfo.startCursor) ||
      null,
    select: (data) => {
      return {
        comments: data.pages
          .flatMap((x) => x.submissionComments.commentsConnection.nodes)
          .sort((a, b) =>
            compareDesc(new Date(a.createdAt), new Date(b.createdAt)),
          ),
      }
    },
  })

  useValidateResponse(
    queryKey.toString(),
    queryResult,
    ZSubmissionCommentsResponse,
  )
  return queryResult
}

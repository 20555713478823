import {
  Button,
  Checkbox,
  CheckboxGroup,
  FormFieldGroup,
  ScreenReaderContent,
  Text,
  View,
} from "@instructure/ui"
import type { Dispatch, SetStateAction } from "react"
import { Message } from "./Message"
import { STEPS } from "./types"

type StepProps = {
  inputName: string
  choicesTitle: string
  choices: string[]
  state: string | null
  setter: Dispatch<SetStateAction<string | null>>
  currentStep: STEPS
  setCurrentStep: Dispatch<SetStateAction<STEPS>>
  renderGoals: () => React.ReactElement
}

export const Step = ({
  inputName,
  choicesTitle,
  choices,
  state,
  setter,
  renderGoals,
  currentStep,
  setCurrentStep,
}: StepProps) => {
  const goToNextStep = () => {
    if (currentStep === STEPS.PREVIEW) {
      return
    }
    setCurrentStep((state) => state + 1)
  }

  return (
    <View as="div" background="secondary">
      <View as="div" padding="large 0" margin="0 0 0 large">
        <Text transform="uppercase">Pre-course check in</Text>
      </View>
      <View as="div" background="primary" padding="large">
        <Message />
        {renderGoals()}

        <FormFieldGroup
          description={
            <ScreenReaderContent>{choicesTitle}</ScreenReaderContent>
          }
          name={choicesTitle}
          key={choicesTitle}
        >
          <CheckboxGroup
            name={inputName}
            onChange={(value) => {
              setter(value[value.length - 1] as string)
            }}
            value={[state || ""]}
            description={choicesTitle}
          >
            {choices.map((val) => {
              return (
                <Checkbox
                  key={val.split(" ").join("-")}
                  label={val}
                  value={val}
                />
              )
            })}
          </CheckboxGroup>
        </FormFieldGroup>
        <Button margin="small 0" display="block" onClick={goToNextStep}>
          Next
        </Button>
      </View>
    </View>
  )
}

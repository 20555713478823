import { useGetCourseName } from "@/api/queries/useGetCourses"
import { useGetFileContent } from "@/api/queries/useGetFileContent"
import { ASSET_TYPE } from "@/api/types.ts"
import { FetchingError } from "@/shared/loading/FetchingError"
import { GlobalLoader } from "@/shared/loading/GlobalLoader"
import { View } from "@instructure/ui"
import { useParams } from "react-router-dom"
import { FileView } from "./FileView"
import { LearningObjectWrapper } from "./learning_objects_skeleton/LearningObjectWrapper.tsx"

export const FileContent = () => {
  const { courseId = "", fileId = "" } = useParams()
  const courseNameQuery = useGetCourseName(courseId)
  const fileQuery = useGetFileContent({ courseId, fileId })

  if (fileQuery.isLoading || courseNameQuery.isLoading) {
    return <GlobalLoader title="Loading..." />
  }

  if (
    fileQuery.isError ||
    !fileQuery.data ||
    courseNameQuery.isError ||
    !courseNameQuery.data
  ) {
    return <FetchingError />
  }

  const { content_type, url, mime_class, display_name, canvadoc_session_url } =
    fileQuery.data

  return (
    <LearningObjectWrapper
      assetType={ASSET_TYPE.FILE}
      assetId={Number.parseInt(fileId)}
      estimatedTime="20 Mins"
      courseId={courseId}
    >
      {canvadoc_session_url ? (
        <View as="div" height="100%" overflowY="hidden">
          <iframe
            title={display_name}
            src={`${import.meta.env.PUBLIC_CANVAS_HOST}/${canvadoc_session_url}`}
            style={{
              border: "none",
              width: "550px",
              height: "350px",
              display: "block",
            }}
          />
        </View>
      ) : (
        <FileView
          mimeClass={mime_class}
          url={url}
          displayName={display_name}
          contentType={content_type}
        />
      )}
    </LearningObjectWrapper>
  )
}

import { useAppContext } from "@/AppContext.tsx"
import { useGetModules } from "@/api/queries/useGetModules.ts"
import { MODULE_STATE, type ModuleType } from "@/api/types.ts"
import { DESKTOP_MY_PROGRESS_WIDTH } from "@/features/learning_content/learning_objects_skeleton/constants.ts"
import { Icon } from "@/icons/Icon.tsx"
import { CLXIconButton } from "@/shared/components/IconButton/IconButton.tsx"
import { ModuleItem } from "@/shared/components/ModuleItem/ModuleItem.tsx"
import type { MODULE_ITEM_TYPE } from "@/shared/components/ModuleItem/types.ts"
import { CLXPill } from "@/shared/components/Pill/Pill.tsx"
import { CLXTruncateText } from "@/shared/components/TruncateText/TruncateText.tsx"
import { getLearningObjectLink } from "@/shared/components/utils.tsx"
import { FetchingError } from "@/shared/loading/FetchingError.tsx"
import { GlobalLoader } from "@/shared/loading/GlobalLoader.tsx"
import { getLockExplanation } from "@/shared/utils/module.ts"
import { borders } from "@/themes/horizon/src/borders.ts"
import { shadows } from "@/themes/horizon/src/shadows.ts"
import { spacing } from "@/themes/horizon/src/spacing.ts"
import { ui } from "@/themes/horizon/src/ui.ts"
import { getUserId } from "@/token-storage.ts"
import { Flex, Heading } from "@instructure/ui"
import { useCallback, useEffect, useMemo, useRef, useState } from "react"

export type LearningObjectProps = {
  courseId: string
  module: ModuleType
  itemId: number
  onCloseClicked: () => void
}

export const LearningObjectMyProgress = ({
  courseId,
  module,
  itemId,
  onCloseClicked,
}: LearningObjectProps) => {
  const { desktopMode } = useAppContext()
  const [isModulesScrolled, setIsModulesScrolled] = useState(false)
  const [currentModule, setCurrentModule] = useState<ModuleType>(module)

  const {
    data: modules,
    isLoading,
    isError,
  } = useGetModules({
    courseId,
    userId: getUserId(),
  })

  const headerRef = useRef<HTMLDivElement>(null)
  const modulesRef = useRef<HTMLDivElement>(null)

  const isHeaderHidingModules = useCallback(() => {
    if (!headerRef.current || !modulesRef.current) return
    const headerBoundingClientRect = headerRef.current.getBoundingClientRect()
    const modulesBoundingClientRect = modulesRef.current.getBoundingClientRect()
    setIsModulesScrolled(
      headerBoundingClientRect.bottom > modulesBoundingClientRect.top,
    )
  }, [])

  useEffect(() => {
    if (!desktopMode || !headerRef.current || !modulesRef.current) {
      return
    }

    window.addEventListener("scroll", isHeaderHidingModules)

    return () => {
      if (!desktopMode || !headerRef.current || !modulesRef.current) {
        return
      }
      window.removeEventListener("scroll", isHeaderHidingModules)
    }
  }, [desktopMode, isHeaderHidingModules])

  const moduleNameById = useMemo(() => {
    if (!modules) {
      return new Map<number, string>()
    }
    return modules.reduce((acc, module) => {
      acc.set(module.id, module.name || "")
      return acc
    }, new Map<number, string>())
  }, [modules])

  if (isLoading) {
    return <GlobalLoader title="Loading..." />
  }

  if (isError || !modules) {
    return <FetchingError />
  }

  const moduleIndex = modules.findIndex((m) => m.id === currentModule.id)
  const prevModule = modules[moduleIndex - 1]
  const nextModule = modules[moduleIndex + 1]
  const lockExplanation = getLockExplanation(currentModule, moduleNameById)

  return (
    <div
      style={{
        display: "flex",
        width: desktopMode ? DESKTOP_MY_PROGRESS_WIDTH : "100%",
        flexDirection: "column",
        borderLeft: desktopMode
          ? `${borders.widthSmall} ${borders.style} ${borders.color}`
          : "none",
      }}
    >
      <div
        ref={headerRef}
        style={{
          display: "flex",
          flexDirection: "column",
          gap: spacing.mediumSmall,
          position: !desktopMode ? "sticky" : "relative",
          top: 0,
          background: desktopMode ? "none" : ui.surfacePageSecondary,
          padding: desktopMode
            ? `${spacing.large} 0 ${spacing.mediumSmall} ${spacing.medium}`
            : `${spacing.mediumSmall} ${spacing.medium}`,
          boxShadow: isModulesScrolled ? shadows.resting : "none",
        }}
      >
        <Flex justifyItems="space-between">
          <Flex gap="x-small">
            <Icon name="list_alt" />
            <Heading level="h3">My Progress</Heading>
          </Flex>
          <CLXIconButton
            screenReaderLabel={"Collapse navigation menu"}
            level="primary"
            variant="inverse"
            clxIconName="close"
            size={"small"}
            withBoxShadow={true}
            onClick={onCloseClicked}
          />
        </Flex>
      </div>
      <div
        ref={modulesRef}
        style={{
          display: "flex",
          flexDirection: "column",
          padding: desktopMode
            ? `0 0 0 ${spacing.medium}`
            : `0 ${spacing.medium}`,
          gap: spacing.xSmall,
        }}
      >
        {currentModule.state === MODULE_STATE.LOCKED && (
          <Flex justifyItems="start">
            <CLXPill
              pillSize="x-small"
              color="info"
              renderIcon={
                <Icon
                  name="lock"
                  width={18}
                  height={18}
                  customColor={ui.surfaceInstitution}
                />
              }
              renderTip={lockExplanation}
              isBorderVisible={false}
            >
              Locked
            </CLXPill>
          </Flex>
        )}
        <div style={{ margin: `0 0 ${spacing.xSmall} 0` }}>
          <CLXTruncateText
            text={currentModule?.name ?? ""}
            maxLines={2}
            size="medium"
            weight="semiBold"
          />
        </div>
        {currentModule.items?.length &&
          currentModule.items?.map((item) => (
            <ModuleItem
              key={item.id}
              name={item.title}
              position={item.position}
              type={item.type as MODULE_ITEM_TYPE}
              redirectUrl={getLearningObjectLink(
                item.type,
                item.quiz_lti,
                courseId,
                item,
                item.module_id.toString(),
              )}
              isSelected={item.id === itemId}
              duration={20} // TODO: get duration from API
              dueDate={item.content_details?.due_at}
              isCompleted={item.completion_requirement?.completed}
              isRequired={!!item.completion_requirement}
              isLocked={item.content_details?.locked_for_user}
              lockExplanation={item.content_details?.lock_explanation}
              points={item.content_details?.points_possible}
            />
          ))}
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          padding: desktopMode
            ? `${spacing.xSmall} 0 ${spacing.mediumSmall} ${spacing.medium}`
            : `${spacing.xSmall} ${spacing.medium} ${spacing.mediumSmall}`,
        }}
      >
        {prevModule ? (
          <CLXIconButton
            screenReaderLabel={"Previous"}
            level="primary"
            variant="inverse"
            withBoxShadow={true}
            clxIconName="chevron_left"
            size="small"
            onClick={() => setCurrentModule(prevModule)}
          />
        ) : (
          <div />
        )}
        {nextModule ? (
          <CLXIconButton
            screenReaderLabel={"Next"}
            level="primary"
            variant="inverse"
            withBoxShadow={true}
            clxIconName="chevron_right"
            size="small"
            onClick={() => setCurrentModule(nextModule)}
          />
        ) : (
          <div />
        )}
      </div>
    </div>
  )
}

import { Icon } from "@/icons/Icon.tsx"
import {
  MODULE_ITEM_TYPE,
  TYPE_DETAILS,
} from "@/shared/components/ModuleItem/types.ts"
import { CLXTruncateText } from "@/shared/components/TruncateText/TruncateText.tsx"
import { createMarkup, formatDateToMMDD } from "@/shared/utils"
import { spacing } from "@/themes/horizon/src/spacing.ts"
import { ui } from "@/themes/horizon/src/ui"
import { Flex, Text, Tooltip } from "@instructure/ui"
import { Link } from "react-router-dom"
import { Card } from "../Card/Card"

export interface ModuleItemProps {
  name: string
  position: number
  type: MODULE_ITEM_TYPE
  isSelected: boolean
  redirectUrl?: string
  isCompleted?: boolean
  isRequired?: boolean
  isLocked?: boolean
  lockExplanation?: string
  dueDate?: string
  points?: number
  duration?: number
}

const MODULE_ITEM_MIN_WIDTH = 220
const MODULE_ITEM_MAX_WIDTH = 768

export const ModuleItem = (props: ModuleItemProps) => {
  if (props.type === MODULE_ITEM_TYPE.SUB_HEADER) {
    return (
      <div style={{ marginTop: props.position > 1 ? spacing.large : "none" }}>
        <CLXTruncateText
          size="small"
          weight="bold"
          text={props.name}
          maxLines={2}
        />
      </div>
    )
  }
  return props.redirectUrl ? (
    <Link to={props.redirectUrl} style={{ textDecoration: "none" }}>
      <ModuleItemCard {...props} />
    </Link>
  ) : (
    <ModuleItemCard {...props} />
  )
}

export const ModuleItemCard = ({
  name,
  type,
  isLocked,
  lockExplanation,
  isSelected,
  duration,
  points,
  dueDate,
  isCompleted = false,
  isRequired = false,
}: ModuleItemProps) => {
  const typeDetails = TYPE_DETAILS[type]

  if (!typeDetails) {
    return null
  }
  const lockExplanationMarkup = createMarkup(lockExplanation ?? "")
  const isPastDue = dueDate && new Date(dueDate) < new Date()
  return (
    <Card
      variant="card"
      shadow="none"
      padding="small mediumSmall"
      borderWidth={isSelected ? "medium" : "small"}
      borderColor={isSelected ? ui.surfaceInstitution : ui.lineStroke}
      minWidth={MODULE_ITEM_MIN_WIDTH}
      maxWidth={MODULE_ITEM_MAX_WIDTH}
    >
      <Flex as="div" direction="row" justifyItems="space-between" gap="small">
        <Flex as="div" direction="column" gap="xx-small">
          <div style={{ overflow: "hidden" }}>
            <CLXTruncateText size="small" text={name} />
          </div>
          <div
            style={{
              display: "flex",
              flexWrap: "wrap",
              alignItems: "center",
              columnGap: spacing.small,
              rowGap: spacing.xxSmall,
            }}
          >
            <Flex gap={"xxx-small"}>
              <Icon
                name={typeDetails.icon}
                width={18}
                height={18}
                customColor={ui.surfaceInstitution}
              />
              <Text size="x-small">{typeDetails.description}</Text>
            </Flex>
            <Text size="x-small">{isRequired ? "Required" : "Optional"}</Text>
            {duration && <Text size="x-small">{`${duration} Mins`}</Text>}
            {dueDate && (
              <Text
                size="x-small"
                color={isPastDue ? "danger" : "primary"}
              >{`${isPastDue ? "Past " : ""}Due ${formatDateToMMDD(dueDate)}`}</Text>
            )}
            {points && <Text size="x-small">{`${points} pts`}</Text>}
          </div>
        </Flex>
        {isLocked && (
          <Tooltip
            renderTip={
              // biome-ignore lint/security/noDangerouslySetInnerHtml: createMarkup sanitizes the input
              <div dangerouslySetInnerHTML={lockExplanationMarkup} />
            }
            placement="top start"
            color="primary-inverse"
          >
            <Flex.Item align="center">
              <Icon
                name="lock"
                width={20}
                height={20}
                customColor={ui.surfaceInstitution}
              />
            </Flex.Item>
          </Tooltip>
        )}
        {!isLocked && isRequired && (
          <Flex.Item align="center">
            {isCompleted ? (
              <Icon
                name="check_circle_full"
                width={20}
                height={20}
                customColor={ui.surfaceInstitution}
              />
            ) : (
              <Icon
                name="circle"
                width={20}
                height={20}
                customColor={ui.lineStroke}
              />
            )}
          </Flex.Item>
        )}
      </Flex>
    </Card>
  )
}

import {
  actions,
  useNavigationContext,
  useNavigationContextDispatch,
} from "@/shared/components/Navigation/NavigationContext.tsx"
import { NavigationItem } from "@/shared/components/Navigation/NavigationItem.tsx"
import { SecondaryNavigationItem } from "@/shared/components/Navigation/SecondaryNavigationItem.tsx"
import {
  DESKTOP_HEADER_NAVIGATION_ITEMS,
  DESKTOP_NAVIGATION_HEADER_MAX_WIDTH,
  DESKTOP_NAVIGATION_ITEMS_BOTTOM,
  DESKTOP_NAVIGATION_ITEMS_TOP,
  DESKTOP_NAVIGATION_WIDTH,
  SECONDARY_NAVIGATION_WIDTH,
} from "@/shared/components/Navigation/constants.ts"
import { shadows } from "@/themes/horizon/src/shadows.ts"
import { spacing } from "@/themes/horizon/src/spacing.ts"
import { ui } from "@/themes/horizon/src/ui.ts"
import { Flex, View } from "@instructure/ui"
import { useEffect, useRef } from "react"
import { Outlet } from "react-router-dom"

const NavigationHeader = () => {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "space-between",
        width: "100%",
        padding: `${spacing.large} ${spacing.medium}`,
        maxWidth: DESKTOP_NAVIGATION_HEADER_MAX_WIDTH,
        margin: "auto",
      }}
    >
      <Flex.Item>
        <div
          style={{
            fontSize: 12,
            padding: 16,
            width: 118,
            background: ui.surfacePageTertiary,
          }}
        >
          Institution Logo
        </div>
      </Flex.Item>
      <Flex gap="x-small">
        {DESKTOP_HEADER_NAVIGATION_ITEMS.map((item) => (
          <NavigationItem key={item.icon} isIconButton={true} {...item} />
        ))}
      </Flex>
    </div>
  )
}

const SecondaryNavigation = () => {
  const { secondaryNavigationItems, secondaryNavigationHeader } =
    useNavigationContext()
  return (
    <View
      as="nav"
      background="primary"
      themeOverride={{ backgroundPrimary: ui.surfaceCardPrimary }}
    >
      <div
        style={{
          boxShadow: shadows.depth1,
          padding: `${spacing.xLarge} ${spacing.large}`,
          height: "100%",
        }}
      >
        <Flex
          gap="medium"
          direction="column"
          alignItems="center"
          width={SECONDARY_NAVIGATION_WIDTH}
        >
          {secondaryNavigationHeader}
          {secondaryNavigationItems.map((item) => (
            <SecondaryNavigationItem key={item.title} {...item} />
          ))}
        </Flex>
      </div>
    </View>
  )
}

export const DesktopNavigationWrapper = () => {
  const scrollRef = useRef<HTMLDivElement>(null)
  const { secondaryNavigationItems, isDesktopNavigationHidden } =
    useNavigationContext()

  const dispatch = useNavigationContextDispatch()

  useEffect(() => {
    dispatch({
      type: actions.SET_DESKTOP_SCROLL_REF,
      payload: scrollRef,
    })
    return () => {
      dispatch({
        type: actions.SET_DESKTOP_SCROLL_REF,
        payload: null,
      })
    }
  }, [dispatch])

  return (
    <View as="div" overflowY="auto" className="flex-container" height="100%">
      {!isDesktopNavigationHidden && (
        <View
          as="nav"
          background="primary"
          themeOverride={{ backgroundPrimary: ui.surfaceCardPrimary }}
        >
          <div
            style={{
              width: DESKTOP_NAVIGATION_WIDTH,
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              boxShadow: shadows.depth1,
              padding: `${spacing.large} ${spacing.xxSmall}`,
              height: "100%",
            }}
          >
            <Flex gap="medium" direction="column" alignItems="center">
              {DESKTOP_NAVIGATION_ITEMS_TOP.map((item) => (
                <NavigationItem key={item.icon} {...item} />
              ))}
            </Flex>
            <Flex gap="medium" direction="column" alignItems="center">
              {DESKTOP_NAVIGATION_ITEMS_BOTTOM.map((item) => (
                <NavigationItem key={item.icon} {...item} />
              ))}
            </Flex>
          </div>
        </View>
      )}
      {secondaryNavigationItems.length > 0 && <SecondaryNavigation />}
      <View
        as="div"
        className="flex-item-grow"
        height="100%"
        overflowY="hidden"
      >
        <div
          ref={scrollRef}
          style={{
            display: "flex",
            gap: spacing.mediumSmall,
            flexDirection: "column",
            height: "100%",
            width: "100%",
            overflowX: "auto",
            overflowY: "scroll",
          }}
        >
          {!isDesktopNavigationHidden && <NavigationHeader />}
          <View as="div" height="100%" padding="0 medium">
            <Outlet />
          </View>
        </div>
      </View>
    </View>
  )
}

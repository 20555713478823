import { QUERY_KEYS } from "@/api/queryKeys.ts"
import {
  ASSET_TYPE,
  type ModuleItem,
  type ModuleItemSequence,
  ZModule,
  ZModuleItem,
  ZModuleItemSequence,
} from "@/api/types.ts"
import { APIROUTE, generateRoute } from "@/shared/router"
import { useQuery } from "@tanstack/react-query"
import { z } from "zod"
import { canvasRestClient } from "../api.ts"
import useValidateResponse from "../useValidateResponse.ts"

export type ModulesByUserProperties = { courseId: string; userId?: string }

export type ModulesResponse = z.infer<typeof ZModulesResponse>
const ZModulesResponse = z.array(ZModule)

export const useGetModules = (props: ModulesByUserProperties) => {
  const queryKey = QUERY_KEYS.MODULES.byUser(props)
  const queryResult = useQuery({
    queryKey,
    queryFn: async (): Promise<ModulesResponse> => {
      const itemApiRoute = generateRoute(
        APIROUTE.MODULES,
        {
          courseId: props.courseId,
        },
        [
          { "include[]": "items" },
          { "include[]": "content_details" },
          props.userId != null ? { student_id: props.userId } : {},
        ],
      )

      return canvasRestClient(itemApiRoute)
    },
  })

  useValidateResponse(queryKey.toString(), queryResult, ZModulesResponse)
  return queryResult
}

export type ModuleByUserProperties = {
  courseId: string
  moduleId: string
  userId?: string
  isEnabled?: boolean
}
export type ModuleResponse = z.infer<typeof ZModule>

export const useGetModule = (props: ModuleByUserProperties) => {
  const queryKey = QUERY_KEYS.MODULES.byId(props)
  const queryResult = useQuery({
    queryKey,
    queryFn: async (): Promise<ModuleResponse> => {
      const itemApiRoute = generateRoute(
        APIROUTE.MODULE,
        {
          courseId: props.courseId,
          moduleId: props.moduleId,
        },
        [
          { "include[]": "items" },
          { "include[]": "content_details" },
          props.userId != null ? { student_id: props.userId } : {},
        ],
      )

      return canvasRestClient(itemApiRoute)
    },
    enabled: props.isEnabled ?? true,
  })

  useValidateResponse(queryKey.toString(), queryResult, ZModule)
  return queryResult
}

export type ModuleItemsResponse = z.infer<typeof ZModuleItemsResponse>
const ZModuleItemsResponse = z.array(ZModuleItem)
export type ModuleItemsProperties = {
  courseId: string
  moduleId: string
  userId?: string
  isEnabled?: boolean
}

export const useGetModuleItems = (props: ModuleItemsProperties) => {
  const queryKey = QUERY_KEYS.MODULES.items(props)
  const queryResult = useQuery({
    queryKey,
    queryFn: async (): Promise<ModuleItemsResponse> => {
      const itemApiRoute = generateRoute(
        APIROUTE.ITEMS,
        {
          courseId: props.courseId,
          moduleId: props.moduleId,
        },
        [
          { per_page: 100 },
          { "include[]": "content_details" },
          { "include[]": "completion_requirement" },
          props.userId != null ? { student_id: props.userId } : {},
        ],
      )

      return canvasRestClient(itemApiRoute)
    },
    enabled: props.isEnabled ?? true,
  })

  useValidateResponse(queryKey.toString(), queryResult, ZModuleItemsResponse)
  return queryResult
}

export type ModuleItemProperties = ModuleItemsProperties & {
  itemId: string
  userId?: string
}

export const useGetModuleItem = (props: ModuleItemProperties) => {
  const queryKey = QUERY_KEYS.MODULES.item(props)
  const queryResult = useQuery({
    queryKey,
    queryFn: async (): Promise<ModuleItem> => {
      const itemApiRoute = generateRoute(APIROUTE.ITEM, props, [
        { "include[]": "content_details" },
        props.userId != null ? { student_id: props.userId } : {},
      ])
      return canvasRestClient(itemApiRoute)
    },
    enabled: props.isEnabled ?? true,
  })

  useValidateResponse(queryKey.toString(), queryResult, ZModuleItem)
  return queryResult
}

export type ModuleItemSequenceProperties = {
  courseId: string
  assetType: ASSET_TYPE
  assetId: number
  moduleItemId: string | null
}

export const useGetModuleItemSequence = (
  props: ModuleItemSequenceProperties,
) => {
  const queryKey = QUERY_KEYS.MODULES.itemsSequence(props)
  const queryResult = useQuery({
    queryKey,
    queryFn: async (): Promise<ModuleItemSequence> => {
      const itemApiRoute = generateRoute(APIROUTE.ITEM_SEQUENCE, props, [
        {
          asset_type: props.moduleItemId
            ? ASSET_TYPE.MODULE_ITEM
            : props.assetType,
        },
        { asset_id: props.moduleItemId ?? props.assetId },
      ])
      return canvasRestClient(itemApiRoute)
    },
  })

  useValidateResponse(queryKey.toString(), queryResult, ZModuleItemSequence)
  return queryResult
}

import type { ModuleItemType } from "@/api/types.ts"

export enum MODULE_ITEM_TYPE {
  ASSIGNMENT = "Assignment",
  PAGE = "Page",
  FILE = "File",
  QUIZ = "Quiz",
  EXTERNAL_URL = "ExternalUrl",
  EXTERNAL_TOOL = "ExternalTool",
  SUB_HEADER = "SubHeader",
}

type TypeDetails = {
  icon: string
  description: string
}

export const TYPE_DETAILS: { [key: string]: TypeDetails } = {
  [MODULE_ITEM_TYPE.ASSIGNMENT]: {
    icon: "edit_document_assignment",
    description: "Assignment",
  },
  [MODULE_ITEM_TYPE.PAGE]: {
    icon: "text_snippet",
    description: "Page",
  },
  [MODULE_ITEM_TYPE.FILE]: {
    icon: "attach_file",
    description: "File",
  },
  [MODULE_ITEM_TYPE.QUIZ]: {
    icon: "fact_check",
    description: "Assessment",
  },
  [MODULE_ITEM_TYPE.EXTERNAL_URL]: {
    icon: "link",
    description: "External Link",
  },
  [MODULE_ITEM_TYPE.EXTERNAL_TOOL]: {
    icon: "note_alt",
    description: "External Tool",
  },
}
export const SUPPORTED_MODULE_ITEM_TYPES: ModuleItemType[] = Object.keys(
  TYPE_DETAILS,
).map((key) => key as ModuleItemType)

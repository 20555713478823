import { useGetAddressBookRecipients } from "@/api/queries/useGetAddressBookRecipients.ts"
import type { User } from "@/api/types.ts"
import { CLXSelect, SELECT_TYPE } from "@/shared/components/Select/Select.tsx"
import { getUserId } from "@/token-storage.ts"
import debounce from "@instructure/debounce"
import { useCallback, useEffect, useRef, useState } from "react"

type RecipientSelectorProps = {
  currentRecipients: User[]
  setRecipientIds: (recipientIds: string[]) => void
}

export const RecipientSelector = ({
  currentRecipients = [],
  setRecipientIds,
}: RecipientSelectorProps) => {
  const userId = getUserId() || ""
  const [search, setSearch] = useState<string>("")
  const [afterUser, setAfterUser] = useState<string>("")
  const [recipients, setRecipients] = useState<User[]>(currentRecipients)
  const currentSearchTerm = useRef<string>("")
  const { isFetching, isError, data } = useGetAddressBookRecipients({
    userId,
    search,
    afterUser,
  })

  useEffect(() => {
    if (!isFetching && !isError && data?.users) {
      const newUsers = data.users
      if (currentSearchTerm.current !== search) {
        setRecipients(newUsers)
        currentSearchTerm.current = search
      } else {
        setRecipients((prevState) => {
          return [...prevState, ...newUsers]
        })
      }
    }
  }, [data, isFetching, isError, search])

  const debouncedUpdateSearchText: (searchValue: string) => void = useCallback(
    debounce(
      (value: string) => {
        setSearch(value)
        setAfterUser("")
      },
      500,
      { leading: false, trailing: true },
    ),
    [],
  )

  return (
    <CLXSelect<User, string>
      type={SELECT_TYPE.MULTI}
      options={recipients}
      initialSelectedOptions={currentRecipients}
      getOptionLabel={useCallback((user: User) => user.name, [])}
      getOptionId={useCallback((user: User) => user._id, [])}
      setSelectedOptionIds={useCallback(
        (recipientIds: string[]) => setRecipientIds(recipientIds),
        [setRecipientIds],
      )}
      setSearchText={debouncedUpdateSearchText}
      onListEndReached={useCallback(
        () => setAfterUser(data?.pageInfo.endCursor || ""),
        [data],
      )}
      hasMoreMenuData={data?.pageInfo.hasNextPage}
    />
  )
}

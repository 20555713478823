import { canvasRestClient } from "./api"

export const refreshCanvasToken = (initialToken: string) => {
  return (callback: (newToken: string) => void) => {
    canvasRestClient("/api/v1/jwts/refresh", {
      method: "POST",
      mode: "cors",
      body: JSON.stringify({ jwt: initialToken }),
    }).then((data) => {
      callback(data.token)
    })
  }
}

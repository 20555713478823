import { useCallback, useMemo, useState } from "react"
import Flashcard, {
  type FlashcardStyle,
  type FlashcardProps,
} from "./Flashcard"
import "./FlashcardArray.css"
import {
  IconArrowEndLine,
  IconArrowStartLine,
  IconButton,
} from "@instructure/ui"

export interface FlashcardArrayProps extends FlashcardStyle {
  readonly cards: FlashcardProps[]
  readonly controls?: boolean
  readonly showCount?: boolean
  readonly FlashcardArrayStyle?: React.CSSProperties
  readonly cycle?: boolean
}

function FlashcardArray({
  cards,
  controls = true,
  showCount = true,
  frontCardStyle = {},
  frontContentStyle = {},
  backCardStyle = {},
  backContentStyle = {},
  FlashcardArrayStyle = {},
  cycle = false,
}: FlashcardArrayProps) {
  const [cardIndex, setCardIndex] = useState(0)
  const [cardsInDisplay, setCardsInDisplay] = useState(
    !cycle ? [-1, 0, 1] : [cards.length - 1, 0, 1],
  )

  const placeFillerCard = (
    <Flashcard
      id="filler"
      className="FlashcardArrayWrapper__empty"
      width="100%"
      backHTML=""
      frontHTML=""
    />
  )

  const cardsList = useMemo(
    () =>
      cards.map((card) => (
        <Flashcard
          id={card.id}
          key={card.id}
          frontHTML={card.frontHTML}
          backHTML={card.backHTML}
          frontCardStyle={{ ...card.frontCardStyle, ...frontCardStyle }}
          frontContentStyle={{
            ...card.frontContentStyle,
            ...frontContentStyle,
          }}
          backCardStyle={{ ...card.backCardStyle, ...backCardStyle }}
          backContentStyle={{ ...card.backContentStyle, ...backContentStyle }}
          className={card.className}
          height={card.height || "100%"}
          width={card.width || "100%"}
          style={card.style}
        />
      )),
    [cards, frontCardStyle, frontContentStyle, backCardStyle, backContentStyle],
  )

  const numberOfCards =
    cardsList.length !== undefined ? cardsList.length - 1 : 0

  const nextCard = useCallback(() => {
    const currentCardNumber =
      cardIndex + 1 < numberOfCards ? cardIndex + 1 : numberOfCards

    if (cycle) {
      setCardIndex(
        cardsInDisplay[1] + 1 < cards.length ? cardsInDisplay[1] + 1 : 0,
      )
      setCardsInDisplay((prevState) => {
        return [
          prevState[0] + 1 < cards.length ? prevState[0] + 1 : 0,
          prevState[1] + 1 < cards.length ? prevState[1] + 1 : 0,
          prevState[2] + 1 < cards.length ? prevState[2] + 1 : 0,
        ]
      })
    } else {
      setCardIndex(currentCardNumber)
      setCardsInDisplay(
        currentCardNumber < numberOfCards
          ? [currentCardNumber - 1, currentCardNumber, currentCardNumber + 1]
          : [numberOfCards - 1, numberOfCards, -1],
      )
    }
  }, [cardIndex, cycle, numberOfCards, cards, cardsInDisplay])

  const prevCard = useCallback(() => {
    const currentCardNumber = cardIndex - 1 >= 0 ? cardIndex - 1 : 0

    if (cycle) {
      setCardIndex(
        cardsInDisplay[1] - 1 >= 0 ? cardsInDisplay[1] - 1 : cards.length - 1,
      )
      setCardsInDisplay((prevState) => {
        const activeCard =
          prevState[1] - 1 < 0 ? cards.length - 1 : prevState[1] - 1

        return [
          activeCard - 1 < 0 ? cards.length - 1 : activeCard - 1,
          activeCard,
          activeCard + 1 < cards.length ? activeCard + 1 : 0,
        ]
      })
    } else {
      setCardIndex(currentCardNumber)
      setCardsInDisplay(
        currentCardNumber === 0
          ? [-1, 0, 1]
          : [currentCardNumber - 1, currentCardNumber, currentCardNumber + 1],
      )
    }
  }, [cardIndex, cycle, cards, cardsInDisplay])

  return (
    <div className="FlashcardArrayWrapper" style={FlashcardArrayStyle}>
      <div className="FlashcardArrayWrapper__CardHolder">
        {cardsInDisplay[0] !== -1
          ? cardsList[cardsInDisplay[0]]
          : placeFillerCard}
        {cardsList[cardsInDisplay[1]]}
        {cardsInDisplay[2] !== -1
          ? cardsList[cardsInDisplay[2]]
          : placeFillerCard}
      </div>

      {(controls || showCount) && (
        <div className="FlashcardArrayWrapper__controls">
          {controls && (
            <IconButton
              screenReaderLabel="Previous Card"
              onClick={() => prevCard()}
              shape="circle"
            >
              <IconArrowStartLine />
            </IconButton>
          )}
          {showCount && (
            <span className="FlashcardArrayWrapper__controls--count">
              {cardIndex + 1}/{cardsList.length}
            </span>
          )}
          {controls && (
            <IconButton
              screenReaderLabel="Next Card"
              onClick={() => nextCard()}
              shape="circle"
            >
              <IconArrowEndLine />
            </IconButton>
          )}
        </div>
      )}
    </div>
  )
}

export default FlashcardArray

import { CLXTruncateText } from "@/shared/components/TruncateText/TruncateText.tsx"
import { Flex, Heading, Text } from "@instructure/ui"
import type { LearningObjectProps } from "../LearningObjectWrapper.tsx"

export const DesktopLearningContentHeader = ({
  moduleItem,
  module,
  estimatedTime,
  dueAt = "XX/XX",
}: LearningObjectProps) => {
  return (
    <Flex
      as="div"
      direction="column"
      alignItems="center"
      gap="medium"
      margin="large none none none"
    >
      <Flex as="div" direction="column" gap="xx-small" alignItems="center">
        <CLXTruncateText
          size="small"
          text={module.name ?? ""}
          textAlign="center"
          maxLines={1}
        />
        <Heading level="h2">
          <CLXTruncateText
            size="content"
            color="secondary"
            text={moduleItem.title}
            textAlign="center"
            maxLines={3}
          />
        </Heading>
      </Flex>
      <Text size="small">{`${estimatedTime ? `${estimatedTime} | ` : ""}Due ${dueAt}`}</Text>
    </Flex>
  )
}

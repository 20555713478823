import { ui } from "./ui.ts"

export const borders = {
  radiusXXSmall: "0.125rem", // 2px
  radiusXSmall: "0.25rem", // 4px
  radiusSmall: "0.5rem", // 8px
  radiusSmallMedium: "0.75rem", // 12px
  radiusMedium: "1rem", // 16px
  radiusLarge: "2rem", // 32px
  radiusXLarge: "6.250rem", // 100px

  widthSmall: "0.0625rem", // 1px
  widthSmallMedium: "0.075rem", // 1.2px
  widthMedium: "0.125rem", // 2px

  style: "solid",
  none: "none",
  color: ui.lineStroke,
}

// default borders removed: widthMedium, widthLarge
// TODO: figure out smoothing

import { borders } from "./src/borders"
import { breakpoints } from "./src/breakpoints"
import componentOverrides from "./src/componentOverrides"
import { contrast } from "./src/contrast.ts"
import { forms } from "./src/forms"
import { gradients } from "./src/gradients"
import { media } from "./src/media"
import { primitives } from "./src/primitives"
import { shadows } from "./src/shadows"
import { spacing } from "./src/spacing"
import { stacking } from "./src/stacking"
import { transitions } from "./src/transitions"
import { typography } from "./src/typography"
import { ui } from "./src/ui"

export const horizonTheme = {
  key: "canvas",
  primitives,
  gradients,
  contrast,
  borders,
  transitions,
  typography,
  spacing,
  forms,
  media,
  breakpoints,
  shadows,
  stacking,
  ui,
  componentOverrides,
  ltiOverrides: {
    newQuizzes: {
      style: {},
      theme: {},
    },
  },
}

export const generateThemeJSON = () => {
  return JSON.stringify(horizonTheme)
}

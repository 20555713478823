import { Button, CloseButton, Flex, Modal, Text } from "@instructure/ui"

type ConfirmationModalProps = {
  isOpen?: boolean
  titleText?: string
  bodyText: string
  confirmButtonText?: string
  cancelButtonText?: string
  onConfirm: () => void
  onCancel: () => void
}

export const ConfirmationModalContent = ({
  titleText = "",
  bodyText,
  onCancel,
  onConfirm,
  confirmButtonText = "Confirm",
  cancelButtonText = "Cancel",
}: ConfirmationModalProps) => {
  return (
    <>
      <Modal.Header>
        <Flex>
          <Flex.Item shouldGrow shouldShrink>
            <Text size="medium">{titleText}</Text>
          </Flex.Item>
          <Flex.Item>
            <CloseButton
              placement="end"
              offset="small"
              onClick={onCancel}
              screenReaderLabel={cancelButtonText}
            />
          </Flex.Item>
        </Flex>
      </Modal.Header>
      <Modal.Body padding="small">
        <Text>{bodyText}</Text>
      </Modal.Body>
      <Modal.Footer>
        <Flex gap="small">
          <Button onClick={onCancel} withBackground={true} type="submit">
            {cancelButtonText}
          </Button>
          <Button onClick={onConfirm} withBackground={false} type="submit">
            {confirmButtonText}
          </Button>
        </Flex>
      </Modal.Footer>
    </>
  )
}

export const ConfirmationModal = (props: ConfirmationModalProps) => {
  return (
    <Modal
      open={props.isOpen}
      onDismiss={props.onCancel}
      size="large"
      label="Confirmation modal"
      shouldCloseOnDocumentClick
    >
      <ConfirmationModalContent {...props} />
    </Modal>
  )
}

import type { ModuleItemType } from "@/api/types.ts"
import type { ReactElement } from "react"

export enum Actor {
  Assistant = "assistant",
  User = "user",
}

export type Prompt = {
  // Prompt description shown when there's lots of space
  longMessage: string
  // Prompt description shown when space is limited
  shortMessage: string
  // Element to render when the prompt is selected
  renderInteraction?: ReactElement
  // Prompt to send to agent when the prompt is selected
  agentPrompt?: string
}

export type Chat = {
  id: number | string
  actor: Actor
  message?: string
  htmlMessage?: string
  citations?: Citation[]
  // When included, this chat message will be sent to the conversational agent; if omitted, the message
  // will be sent to the knowledge base. agentPrompt is never shown to user.
  agentPrompt?: string
}

export type Citation = {
  objectName: string
  objectType: ModuleItemType | "Syllabus"
  objectId: string
}

import { useAddConversationMessage } from "@/api/mutations/conversation/useAddConversationMessage.ts"
import { useCreateConversation } from "@/api/mutations/conversation/useCreateConversation.ts"
import type { User } from "@/api/types.ts"
import { RecipientSelector } from "@/features/inbox/RecipientSelector.tsx"
import { useToast } from "@/shared/components/Toast/ToastContext.tsx"
import {
  Button,
  CloseButton,
  Flex,
  Mask,
  Modal,
  Overlay,
  ScreenReaderContent,
  Spinner,
  Text,
  TextArea,
  TextInput,
} from "@instructure/ui"
import { useState } from "react"

type SendMessageModalProps = {
  isOpen?: boolean
  closeModal: () => void
  currentRecipients?: User[]
  currentSubject?: string
  conversationId?: string
  includedMessages?: string[]
}

export const SendMessageModalContent = ({
  closeModal,
  conversationId,
  includedMessages,
  currentSubject,
  currentRecipients = [],
}: SendMessageModalProps) => {
  const [body, setBody] = useState<string>("")
  const [subject, setSubject] = useState<string>("")
  const [recipients, setRecipients] = useState<string[]>(
    currentRecipients.map((user) => user._id),
  )
  const { isPending: isCreateConversationPending, mutate: createConversation } =
    useCreateConversation()
  const { isPending: isAddMessagePending, mutate: addMessage } =
    useAddConversationMessage()
  const isValidConversation = body.length > 0 && recipients.length > 0
  const isNewConversation =
    !conversationId && !includedMessages && !currentSubject
  const sendToast = useToast()
  const onSuccess = () => {
    closeModal()
    sendToast({ message: "Message successfully sent!", variant: "success" })
  }

  const onError = () => {
    sendToast({
      message:
        "Something went wrong during message sending, please try again later!",
      variant: "error",
    })
  }

  const onSubmitClicked = async () => {
    if (isNewConversation) {
      createConversation(
        {
          subject,
          body,
          recipients,
        },
        { onSuccess, onError },
      )
    } else if (conversationId && includedMessages) {
      addMessage(
        {
          body,
          recipients,
          includedMessages,
          conversationId,
        },
        { onSuccess, onError },
      )
    }
  }

  return (
    <>
      <Modal.Header>
        <Flex>
          <Flex.Item shouldGrow shouldShrink>
            <Text size="large">Compose message</Text>
          </Flex.Item>
          <Flex.Item>
            <CloseButton
              placement="end"
              offset="small"
              onClick={closeModal}
              screenReaderLabel="Close"
            />
          </Flex.Item>
        </Flex>
      </Modal.Header>
      <Modal.Body padding="small">
        <Flex direction="column" gap="small">
          <Flex gap="small">
            <Text>To:</Text>
            <Flex.Item shouldGrow>
              <RecipientSelector
                currentRecipients={currentRecipients}
                setRecipientIds={setRecipients}
              />
            </Flex.Item>
          </Flex>
          <Flex gap="small">
            <Text>Subject:</Text>
            <TextInput
              renderLabel={<ScreenReaderContent>Search</ScreenReaderContent>}
              type="text"
              placeholder="Subject"
              shouldNotWrap
              disabled={!!currentSubject}
              value={currentSubject ?? subject}
              onChange={(e) => setSubject(e.target.value)}
            />
          </Flex>
          <TextArea
            label={<ScreenReaderContent>Message Body</ScreenReaderContent>}
            autoGrow={false}
            height="200px"
            maxHeight="200px"
            value={body}
            onChange={(e) => setBody(e.target.value)}
            data-testid="message-body"
          />
        </Flex>
        <Overlay
          open={isCreateConversationPending || isAddMessagePending}
          transition="fade"
          label="Sending message"
          shouldReturnFocus
          shouldContainFocus
        >
          <Mask>
            <Spinner
              renderTitle="Sending message"
              size="large"
              margin="0 0 0 medium"
            />
          </Mask>
        </Overlay>
      </Modal.Body>
      <Modal.Footer>
        <Button
          disabled={
            isCreateConversationPending ||
            isAddMessagePending ||
            !isValidConversation
          }
          onClick={onSubmitClicked}
          withBackground={false}
          type="submit"
        >
          Send
        </Button>
      </Modal.Footer>
    </>
  )
}

export const SendMessageModal = (props: SendMessageModalProps) => {
  return (
    <Modal
      open={props.isOpen}
      onDismiss={props.closeModal}
      size="large"
      label="Create Conversation"
      shouldCloseOnDocumentClick
    >
      <SendMessageModalContent {...props} />
    </Modal>
  )
}

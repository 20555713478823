import { jwtDecode } from "jwt-decode"
import { z } from "zod"

const TOKEN_DATA_KEY = "access_token_data"
const ACCESS_TOKEN_EXPIRATION_MS = 60 * 60 * 1000
const REFRESH_TOKEN_EXPIRATION_MS = 2 * 60 * 60 * 1000
const TOKEN_EXPIRATION_OFFSET_MS = 61 * 1000
const IS_LOGGED_IN_KEY = "is_logged_in"

const ZTokenData = z
  .object({
    userId: z.string(),
    isMasquerading: z.boolean(),
    isTestStudent: z.boolean(),
    access: z.object({
      token: z.string(),
      expirationDate: z.coerce.date(),
    }),
    refresh: z.object({
      token: z.string(),
      expirationDate: z.coerce.date(),
    }),
    cedarJwt: z.string().optional(),
  })
  .optional()

type TokenData = z.infer<typeof ZTokenData>

const getTokenData = (): TokenData => {
  const data = localStorage.getItem(TOKEN_DATA_KEY)
  if (!data) {
    return undefined
  }
  try {
    const tokenData = JSON.parse(data)
    ZTokenData.parse(tokenData)
    return tokenData
  } catch (e) {
    console.error(e)
    return undefined
  }
}

export const setBaseTokenData = ({
  userId,
  accessToken,
  refreshToken,
  isMasquerading = false,
  isTestStudent = false,
  cedarJwt,
}: {
  userId: string
  accessToken: string
  refreshToken: string
  isMasquerading?: boolean
  isTestStudent?: boolean
  cedarJwt?: string
}) => {
  localStorage.setItem(
    TOKEN_DATA_KEY,
    JSON.stringify({
      userId,
      isMasquerading: isMasquerading,
      isTestStudent: isTestStudent,
      access: {
        token: accessToken,
        expirationDate: new Date(
          new Date().getTime() +
            ACCESS_TOKEN_EXPIRATION_MS -
            TOKEN_EXPIRATION_OFFSET_MS,
        ),
      },
      refresh: {
        token: refreshToken,
        expirationDate: new Date(
          new Date().getTime() +
            REFRESH_TOKEN_EXPIRATION_MS -
            TOKEN_EXPIRATION_OFFSET_MS,
        ),
      },
      cedarJwt,
    }),
  )
}

export const setCedarJwt = (cedarJwt: string) => {
  const tokenData = getTokenData()
  if (tokenData) {
    tokenData.cedarJwt = cedarJwt
    localStorage.setItem(TOKEN_DATA_KEY, JSON.stringify(tokenData))
  }
}

export const clearTokenData = () => {
  localStorage.removeItem(TOKEN_DATA_KEY)
}

export const getUserId = () => {
  const tokenData = getTokenData()
  return tokenData?.userId
}

export const getIsMasquerading = () => {
  const tokenData = getTokenData()
  return !!tokenData?.isMasquerading
}

export const getIsTestStudent = () => {
  const tokenData = getTokenData()
  return !!tokenData?.isTestStudent
}

export const getAccessToken = () => {
  const tokenData = getTokenData()
  if (tokenData && new Date(tokenData.access.expirationDate) > new Date()) {
    return tokenData.access.token
  }
  return undefined
}

export const getRefreshToken = () => {
  const tokenData = getTokenData()
  if (tokenData && new Date(tokenData.refresh.expirationDate) > new Date()) {
    return tokenData.refresh.token
  }
  return undefined
}

export const getCedarJwt = () => {
  const tokenData = getTokenData()
  const cedarJwt = tokenData?.cedarJwt
  if (cedarJwt && !isJwtExpired(cedarJwt)) {
    return cedarJwt
  }
  return undefined
}

export const subscribeOnceToChanges = (callback: () => void) => {
  const storageListener = (event: StorageEvent) => {
    if (event.key === TOKEN_DATA_KEY) {
      callback()
      window.removeEventListener("storage", storageListener)
    }
  }
  window.addEventListener("storage", storageListener)
}

const isJwtExpired = (jwt: string) => {
  const decodedJwt = jwtDecode(jwt)
  const expirationString = decodedJwt.exp
  if (!expirationString) {
    return true
  }
  const expirationDate = new Date(expirationString * 1000)
  return expirationDate <= new Date()
}

export const setIsLoggedIn = (isLoggedIn: boolean) => {
  localStorage.setItem(IS_LOGGED_IN_KEY, String(isLoggedIn))
}

export const getIsLoggedIn = () => {
  return localStorage.getItem(IS_LOGGED_IN_KEY) === "true"
}

import { useAppContext } from "@/AppContext.tsx"
import { MOBILE_MENU_HEIGHT } from "@/constants.ts"
import { CLXIconButton } from "@/shared/components/IconButton/IconButton.tsx"
import { AiNavigationItem } from "@/shared/components/Navigation/AiNavigationItem.tsx"
import { useNavigationContext } from "@/shared/components/Navigation/NavigationContext.tsx"
import { NavigationItem } from "@/shared/components/Navigation/NavigationItem.tsx"
import {
  AI,
  MOBILE_NAVIGATION_ITEMS,
} from "@/shared/components/Navigation/constants.ts"
import { ui } from "@/themes/horizon/src/ui.ts"
import { Flex, IconHamburgerLine, Tray, View } from "@instructure/ui"
import { useState } from "react"
import { Outlet, ScrollRestoration } from "react-router-dom"

export const MobilNavigationTray = ({
  isShowingMenu,
  setShowingMenu,
}: {
  isShowingMenu: boolean
  setShowingMenu: (isShowing: boolean) => void
}) => {
  return (
    <Tray
      size="small"
      placement="end"
      label="Navigation"
      open={isShowingMenu}
      enableMask={true}
      shouldCloseOnDocumentClick={true}
    >
      <Flex
        height="100%"
        justifyItems="center"
        gap="medium"
        direction="column"
        padding="large"
      >
        <Flex height={44} width="100%" justifyItems="end">
          <CLXIconButton
            size="medium"
            level="primary"
            shape="circle"
            variant="inverse"
            withBoxShadow={true}
            screenReaderLabel="Close navigation menu"
            onClick={() => {
              setShowingMenu(false)
            }}
            clxIconName={"close"}
          />
        </Flex>
        <Flex gap="small" direction="column" alignItems="center">
          {MOBILE_NAVIGATION_ITEMS.map((item) => (
            <NavigationItem
              key={item.icon}
              {...item}
              onClick={() => {
                setShowingMenu(false)
              }}
            />
          ))}
        </Flex>
      </Flex>
    </Tray>
  )
}

const MobilNavigationHeader = () => {
  const [isShowingMenu, setShowingMenu] = useState(false)
  return (
    <Flex
      height={MOBILE_MENU_HEIGHT}
      margin="medium"
      justifyItems="space-between"
    >
      <Flex.Item>
        <span
          style={{
            height: 12,
            padding: 16,
            width: 86,
            background: ui.surfacePageTertiary,
          }}
        >
          Institution Logo
        </span>
      </Flex.Item>
      <Flex gap="small">
        <AiNavigationItem {...{ ...AI, isIconButton: true }} />
        <CLXIconButton
          size="medium"
          level="primary"
          shape="circle"
          variant="inverse"
          withBoxShadow={true}
          screenReaderLabel="Open navigation menu"
          onClick={() => setShowingMenu(true)}
        >
          <IconHamburgerLine size="x-small" />
        </CLXIconButton>
        <MobilNavigationTray
          isShowingMenu={isShowingMenu}
          setShowingMenu={setShowingMenu}
        />
      </Flex>
    </Flex>
  )
}

export const MobileNavigationWrapper = () => {
  const { viewportHeight } = useAppContext()
  const { isMobileNavigationHidden } = useNavigationContext()

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        height: viewportHeight,
      }}
    >
      {isMobileNavigationHidden ? null : <MobilNavigationHeader />}
      <ScrollRestoration getKey={(location, _matches) => location.pathname} />
      <View
        as="div"
        height={
          viewportHeight - (isMobileNavigationHidden ? 0 : MOBILE_MENU_HEIGHT)
        }
        overflowX="auto"
      >
        <Outlet />
      </View>
    </div>
  )
}

import type { MODULE_STATE, ModuleRequirementType } from "@/api/types.ts"
import { Icon } from "@/icons/Icon.tsx"
import { Card } from "@/shared/components/Card/Card.tsx"
import { ModuleContainerStateLabel } from "@/shared/components/ModuleContainer/ModuleContainerStateLabel.tsx"
import { CLXTruncateText } from "@/shared/components/TruncateText/TruncateText.tsx"
import { getRequirementText } from "@/shared/utils/module.ts"
import { Flex, Text, View } from "@instructure/ui"
import type { ReactNode } from "react"

export interface ModuleContainerProps {
  moduleName: string | null
  moduleState: MODULE_STATE | null
  itemCount: number
  duration: number
  content: ReactNode
  lockExplanation?: string
  isCollapsed: boolean
  setIsCollapsed: (isCollapsed: boolean) => void
  moduleRequirementType: ModuleRequirementType
  isAllOfThemRequired: boolean
}

export const MODULE_CONTAINER_MAX_WIDTH = 848

export const ModuleContainer = ({
  moduleName,
  moduleState,
  content,
  itemCount,
  duration,
  lockExplanation,
  isCollapsed,
  setIsCollapsed,
  moduleRequirementType,
  isAllOfThemRequired,
}: ModuleContainerProps) => {
  return (
    <Card
      variant="card"
      shadow="none"
      maxWidth={MODULE_CONTAINER_MAX_WIDTH}
      width="fill-available"
      margin="none"
      padding="none"
      onClick={() => setIsCollapsed(!isCollapsed)}
    >
      <Flex direction="column" justifyItems="space-between">
        <Flex
          direction="row"
          alignItems="start"
          gap="xx-small"
          padding="mediumSmall"
        >
          <Icon
            name={isCollapsed ? "keyboard_arrow_down" : "keyboard_arrow_up"}
          />
          <Flex direction="column" gap="xx-small">
            <Flex>
              <ModuleContainerStateLabel state={moduleState} />
            </Flex>
            <Flex.Item shouldGrow={true}>
              <CLXTruncateText
                size="medium"
                weight="bold"
                maxLines={2}
                text={moduleName ?? ""}
              />
            </Flex.Item>
            <Flex gap="xx-small">
              <Text size="x-small">{`${itemCount} items`}</Text>
              <Text size="x-small">{`${duration} Mins`}</Text>
            </Flex>
          </Flex>
        </Flex>
        {!isCollapsed && (
          <>
            {lockExplanation && (
              <View as="div" padding="x-small mediumSmall mediumSmall">
                <Text size="small">{lockExplanation}</Text>
              </View>
            )}
            {!lockExplanation && moduleRequirementType && (
              <View as="div" padding="x-small mediumSmall mediumSmall">
                <Text size="small">
                  {getRequirementText(
                    moduleRequirementType,
                    isAllOfThemRequired,
                  )}
                </Text>
              </View>
            )}
            <View borderWidth="small 0 0 0" padding="mediumSmall">
              <Flex direction="column" gap="x-small">
                {content}
              </Flex>
            </View>
          </>
        )}
      </Flex>
    </Card>
  )
}

import { useCreateSubmission } from "@/api/mutations/useCreateSubmission.ts"
import { AssignmentSubmissionControls } from "@/features/learning_content/AssignmentSubmissionControls.tsx"
import { View } from "@instructure/ui"
import { useState } from "react"
import { useParams } from "react-router-dom"
import { UploadFile, type UploadedFile } from "./UploadFile"

export const OnlineUploadSubmissionForm = () => {
  const { assignmentId = "" } = useParams()
  const [uploadedFiles, setUploadedFiles] = useState<UploadedFile[]>([])
  const [uploadErrors, setUploadErrors] = useState<Error[]>([])
  const createSubmissionMutation = useCreateSubmission()

  const entryValid = uploadedFiles.length > 0 && uploadErrors.length === 0
  return (
    <View>
      <UploadFile
        uploadedFiles={uploadedFiles}
        setUploadedFiles={setUploadedFiles}
        setErrors={setUploadErrors}
        errors={uploadErrors}
      />
      <AssignmentSubmissionControls
        entryValid={entryValid}
        onSubmit={() => {
          createSubmissionMutation.mutate({
            assignmentLid: assignmentId,
            submissionType: "online_upload",
            fileIds: uploadedFiles.map((file) => file.id),
          })
        }}
        isPending={createSubmissionMutation.isPending}
        isSuccess={createSubmissionMutation.isSuccess}
        errorMessage={createSubmissionMutation.error?.message}
      />
    </View>
  )
}

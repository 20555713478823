import { getIsLoggedIn } from "@/token-storage.ts"
import type React from "react"
import { Navigate } from "react-router-dom"

const ProtectedRoute = ({ element }: { element: React.ReactNode }) => {
  const isLoggedIn = getIsLoggedIn()
  return isLoggedIn ? element : <Navigate to="/login" />
}

export default ProtectedRoute

import { useGetSubmission } from "@/api/queries/useGetSubmissions"
import { FetchingError } from "@/shared/loading/FetchingError"
import { GlobalLoader } from "@/shared/loading/GlobalLoader"
import { View } from "@instructure/ui"
import { useEffect, useMemo, useState } from "react"
import { ViewSubmission } from "./ViewSubmission"
import "./SubmissionStyles.css"
import type { ZSubmissionType } from "@/api/types"
import { CLXButton } from "@/shared/components/Button/Button"
import { CLXSelect, SELECT_TYPE } from "@/shared/components/Select/Select.tsx"
import { SubmissionComments } from "./SubmissionComments"

export const Submissions = ({
  submissionId,
  pointsPossible,
  onClick,
}: {
  submissionId: string
  pointsPossible?: number | null
  onClick: () => void
}) => {
  const submissionQuery = useGetSubmission(submissionId)
  const [selectedOption, setSelectedOption] = useState<number | null>(null)

  const submissionMap: Map<number, ZSubmissionType> = useMemo(() => {
    if (submissionQuery.data) {
      return new Map(submissionQuery.data.map((s) => [s.attempt, s]))
    }
    return new Map()
  }, [submissionQuery])

  useEffect(() => {
    if (submissionQuery.data && selectedOption !== null) {
      const attempt =
        submissionQuery.data[submissionQuery.data.length - 1].attempt
      setSelectedOption(attempt)
    }
  }, [submissionQuery, selectedOption])

  if (submissionQuery.isLoading) {
    return <GlobalLoader title="Loading..." />
  }

  if (submissionQuery.isError || !submissionQuery.data) {
    return <FetchingError />
  }

  return (
    <View as="div" padding="medium">
      <View as="div" margin="small 0">
        <CLXButton level="secondary" display="block" onClick={onClick}>
          Go back
        </CLXButton>
      </View>
      <View as="div" className="submission-select">
        <CLXSelect<ZSubmissionType, number>
          options={submissionQuery.data}
          type={SELECT_TYPE.SINGLE}
          getOptionLabel={({ attempt }) => {
            return `Attempt ${attempt}`
          }}
          getOptionId={({ attempt }) => attempt}
          setSelectedOptionIds={(selectedIds) =>
            setSelectedOption(selectedIds[0] || null)
          }
        />
      </View>
      {selectedOption && (
        <>
          <ViewSubmission
            {...(submissionMap.get(selectedOption) as ZSubmissionType)}
            isLastSubmission={false}
            pointsPossible={pointsPossible}
          />
          <SubmissionComments
            submissionId={submissionId}
            attempt={
              (submissionMap.get(selectedOption) as ZSubmissionType).attempt
            }
          />
        </>
      )}
    </View>
  )
}

import { useUploadFiles } from "@/api/mutations/useUploadFiles"
import {
  FileDrop,
  Flex,
  IconDocumentLine,
  IconTrashLine,
  IconUploadLine,
  Spinner,
  Text,
  View,
} from "@instructure/ui"
import type { SetStateAction } from "react"
import { useParams } from "react-router-dom"

export type UploadedFile = {
  id: number
  filename: string
  size: number
}

export const UploadFile = ({
  uploadedFiles,
  setUploadedFiles,
  errors,
  setErrors: setError,
  isComment = false,
  allowMultiple = false,
  allowRepeats = false,
  allowDeletion = false,
}: {
  uploadedFiles: UploadedFile[]
  setUploadedFiles: React.Dispatch<SetStateAction<UploadedFile[]>>
  errors: Error[]
  setErrors: React.Dispatch<SetStateAction<Error[]>>
  isComment?: boolean
  allowMultiple?: boolean
  allowRepeats?: boolean
  allowDeletion?: boolean
}) => {
  const { courseId = "", assignmentId = "" } = useParams()
  const uploadFileMutation = useUploadFiles()

  const uploadFile = (files: File[]) => {
    uploadFileMutation.mutate(
      {
        courseId,
        assignmentId,
        files,
        isComment,
      },
      {
        onSuccess: (finalizeResponse) => {
          const fulFilled = finalizeResponse.filter(
            (r) => r.status === "fulfilled",
          )
          const rejected = finalizeResponse.filter(
            (r) => r.status === "rejected",
          )
          if (fulFilled.length > 0) {
            setUploadedFiles([
              ...uploadedFiles,
              ...fulFilled.map((f) => f.value as UploadedFile),
            ])
          }
          if (rejected.length > 0) {
            setError(rejected.map((r) => r.reason as Error))
          }
        },
      },
    )
  }

  const isUploadPending = uploadFileMutation.isPending
  const isUploadError = errors.length > 0
  const hasUploadedFile = uploadedFiles.length > 0 && !isUploadError

  return (
    <View as="div" margin="small 0">
      {isUploadPending && (
        <View as="div" margin="medium 0" textAlign="center">
          <Spinner renderTitle="Uploading file" size="small" />
        </View>
      )}

      {!isUploadPending &&
        hasUploadedFile &&
        uploadedFiles.map((f) => {
          return (
            <Flex
              key={f.id}
              as="div"
              margin="large 0"
              justifyItems="space-between"
            >
              <Flex.Item>
                <IconDocumentLine />
                <Text>{f.filename}</Text>
              </Flex.Item>
              <Flex.Item>
                <Text color="secondary">{`${f.size} bytes`}</Text>
              </Flex.Item>
              {allowDeletion && (
                <Flex.Item>
                  <IconTrashLine
                    onClick={() => {
                      setUploadedFiles((state) =>
                        state.filter((s) => s.id !== f.id),
                      )
                    }}
                  />
                </Flex.Item>
              )}
            </Flex>
          )
        })}

      {isUploadError && (
        <View as="div" margin="medium 0">
          {errors.map((e) => {
            return (
              <Text key={e.message} as="div" color="danger">
                {e.message}
              </Text>
            )
          })}
        </View>
      )}

      {!isUploadPending &&
        (allowMultiple ? !isUploadError : !hasUploadedFile) && (
          <FileDrop
            renderLabel={
              <View as="div" margin="medium">
                <IconUploadLine size="medium" />
                <View as="div" margin="medium 0">
                  <Text>Drop a file here or click to browse</Text>
                </View>
              </View>
            }
            shouldAllowMultiple={allowMultiple}
            shouldAllowRepeats={allowRepeats}
            onDropAccepted={(acceptedObjects) => {
              uploadFile(acceptedObjects as File[])
            }}
          />
        )}
    </View>
  )
}

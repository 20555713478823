import { ui } from "@/themes/horizon/src/ui"
import type { CLXTheme, ThemeObjectValue } from "@/themes/types"
import type { LinkTheme } from "@instructure/shared-types"

export type LinkColor = "link" | "link-inverse" | "blue" | "beige"
export const generateLinkComponentTheme =
  (color: LinkColor) => (_: unknown, currentTheme: CLXTheme) => {
    const baseComponentTheme: Partial<LinkTheme> = {
      ...(currentTheme.componentOverrides.Link as ThemeObjectValue),
    }

    if (color === "link-inverse") {
      return {
        ...baseComponentTheme,
        color: ui.surfacePageSecondary,
        focusOutlineColor: ui.surfacePageSecondary,
        hoverColor: ui.surfacePageSecondary,
      }
    }

    if (color === "blue") {
      return {
        ...baseComponentTheme,
        color: ui.surfaceAttention,
        focusOutlineColor: ui.surfaceAttention,
        hoverColor: ui.surfaceAttentionSecondary,
      }
    }

    if (color === "beige") {
      return {
        ...baseComponentTheme,
        color: ui.surfacePagePrimary,
        focusOutlineColor: ui.surfacePagePrimary,
        hoverColor: ui.surfaceCardSecondary,
      }
    }

    return baseComponentTheme
  }

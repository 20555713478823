import { useGetCourses } from "@/api/queries/useGetCourses.ts"
import { Card } from "@/shared/components/Card/Card.tsx"
import { FetchingError } from "@/shared/loading/FetchingError.tsx"
import { GlobalLoader } from "@/shared/loading/GlobalLoader.tsx"
import { ui } from "@/themes/horizon/src/ui.ts"
import { View } from "@instructure/ui"
import { useAppContext } from "src/AppContext.tsx"
import { getUserId } from "../../token-storage.ts"
import { DesktopHome } from "./DesktopHome.tsx"
import { MobileHome } from "./MobileHome.tsx"

const Home = () => {
  const { data: courses, isPending, isError } = useGetCourses(getUserId())
  const { desktopMode } = useAppContext()
  if (isPending) {
    return <GlobalLoader title="Loading..." renderInContent={true} />
  }

  if (isError) {
    return <FetchingError />
  }

  if (!courses?.length) {
    return desktopMode ? (
      <View
        as="div"
        background="primary"
        themeOverride={{ backgroundPrimary: ui.surfacePagePrimary }}
        borderRadius="medium"
        padding="large"
      >
        <Card>You haven't enrolled in any course yet</Card>
      </View>
    ) : (
      <Card>You haven't enrolled in any course yet</Card>
    )
  }

  return desktopMode ? (
    <DesktopHome courses={courses} />
  ) : (
    <MobileHome courses={courses} />
  )
}

export default Home

import { aiClient } from "@/api/api"
import useValidateResponse from "@/api/useValidateResponse"
import { useMutation } from "@tanstack/react-query"
import { z } from "zod"

export const ZAIResult = z.object({
  message: z.object({
    role: z.string(),
    content: z.string(),
  }),
})

export type AIResult = z.infer<typeof ZAIResult>

const mutationFn = async (
  content: string,
  jsonResponse: boolean,
): Promise<AIResult> => {
  const response = await aiClient.chat({
    model: "llama3.2",
    format: jsonResponse ? "json" : "summary",
    messages: [
      {
        role: "user",
        content,
      },
    ],
  })
  return response
}

export const useAI = (jsonResponse = false) => {
  const mutation = useMutation({
    mutationFn: (content: string) => mutationFn(content, jsonResponse),
  })

  useValidateResponse("useAI", mutation, ZAIResult)

  return mutation
}

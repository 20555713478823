import Badge from "./Badge.ts"
import Billboard from "./Billboard.ts"
import Button from "./Button"
import FileDrop from "./FileDrop"
import FormFieldLabel from "./FormFieldLabel.ts"
import FormFieldMessage from "./FormFieldMessage"
import Heading from "./Heading"
import IconButton from "./IconButton"
import Link from "./Link"
import Option from "./Option"
import Pill from "./Pill.ts"
import Popover from "./Popover.ts"
import Tabs from "./Tabs.ts"
import Tag from "./Tag"
import Text from "./Text"
import TextArea from "./TextArea"
import TextInput from "./TextInput"

export default {
  ...TextInput,
  ...FormFieldMessage,
  ...Button,
  ...TextArea,
  ...IconButton,
  ...Pill,
  ...Link,
  ...Heading,
  ...Text,
  ...Tag,
  ...Badge,
  ...Popover,
  ...FormFieldLabel,
  ...FileDrop,
  ...Billboard,
  ...Tabs,
  ...Option,
}

import { type ClientError, GraphQLClient } from "graphql-request"
import { Ollama } from "ollama/browser"
import * as TokenStorage from "../token-storage.ts"

export class ExpiredTokenError extends Error {
  constructor() {
    super("Could not obtain a valid access token.")
    Object.setPrototypeOf(this, ExpiredTokenError.prototype)
  }
}

export class FileUploadError extends Error {
  fileName: string
  constructor(message: string, fileName: string) {
    super(message)
    Object.setPrototypeOf(this, FileUploadError.prototype)
    this.fileName = fileName
  }
}

const getCurrentAccessToken = async () => {
  const accessToken = TokenStorage.getAccessToken()
  if (accessToken) {
    return accessToken
  }

  const refreshToken = TokenStorage.getRefreshToken()
  if (refreshToken) {
    return await refreshAccessToken(refreshToken)
  }

  // Both the access and refresh tokens are expired!
  throw new ExpiredTokenError()
}

const refreshAccessToken = async (refreshToken: string) => {
  try {
    const json = await canvasRestClient(
      "login/oauth2/token",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
        body: new URLSearchParams({
          grant_type: "refresh_token",
          client_id: import.meta.env.PUBLIC_CANVAS_CLIENT_ID,
          refresh_token: refreshToken,
        }),
      },
      true,
    )

    TokenStorage.setBaseTokenData({
      userId: json.user.id.toString(),
      accessToken: json.access_token,
      refreshToken: json.refresh_token,
    })

    return json.access_token
  } catch (_error) {
    // If refreshing the token fails for any reason, we don't want to mess up any existing state, so we'll
    // show the modal to login again.
    throw new ExpiredTokenError()
  }
}

const getCurrentCedarJwt = async () => {
  const cedarJwt = TokenStorage.getCedarJwt()
  if (cedarJwt) {
    return cedarJwt
  }

  return await fetchCedarJwt()
}

const fetchCedarJwt = async () => {
  const cedarHost = new URL(import.meta.env.PUBLIC_CEDAR_HOST)
  const json = await canvasRestClient("api/v1/jwts", {
    method: "POST",
    body: JSON.stringify({
      audience: cedarHost.hostname,
      workflows: ["cedar"],
    }),
  })
  const base64EncodedJwt = json.token
  const jwt = atob(base64EncodedJwt)
  TokenStorage.setCedarJwt(jwt)
  return jwt
}

export const canvasGqlClient = async () => {
  const client = new GraphQLClient(
    `${import.meta.env.PUBLIC_HORIZON_HOST}/canvas/api/graphql`,
    {
      method: "POST",
      headers: {
        Authorization: `Bearer ${await getCurrentAccessToken()}`,
      },
    },
  )

  const request = async <T>(query: string, variables?: object): Promise<T> => {
    try {
      return await client.request(query, variables)
    } catch (error) {
      // Check if the token has been revoked before we know it expired
      if ((error as ClientError).response?.status === 401) {
        throw new ExpiredTokenError()
      }
      return Promise.reject(error)
    }
  }

  return { request }
}

export const canvasRestClient = async (
  endpoint: string,
  options: RequestInit = {},
  skipAuthorizationHeader = false,
) => {
  const defaultOptions: RequestInit = {
    method: "GET",
    headers: {
      Authorization: skipAuthorizationHeader
        ? ""
        : `Bearer ${await getCurrentAccessToken()}`,
      "Content-Type": "application/json",
    },
  }

  const response = await fetch(
    `${import.meta.env.PUBLIC_HORIZON_HOST}/canvas/${endpoint}`,
    { ...defaultOptions, ...options },
  )

  if (!response.ok) {
    // Check if the token has been revoked before we know it expired
    if (response.status === 401) {
      throw new ExpiredTokenError()
    }
    const errorBody = await response.json().catch(() => ({}))
    throw new Error(
      `canvasRestClient received a bad response: ${response.status} ${response.statusText}: ${errorBody.message}`,
    )
  }
  return await response.json()
}

export const cedarGqlClient = async () => {
  return new GraphQLClient(
    `${import.meta.env.PUBLIC_HORIZON_HOST}/cedar/graphql`,
    {
      method: "POST",
      headers: {
        Authorization: `Bearer ${await getCurrentCedarJwt()}`,
      },
    },
  )
}

export const aiClient = new Ollama({ host: import.meta.env.PUBLIC_AI_HOST })
